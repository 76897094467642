import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Quote } from '@common/util-models';

@Component({
  selector: 'wl-quote-summary-total',
  templateUrl: './quote-summary-total.component.html',
  styleUrls: ['./quote-summary-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteSummaryTotalComponent implements OnChanges {
  @Input() applianceIsWhiteGoods?: boolean;
  @Input() isExpanded?: boolean;
  @Input() isFixedTerm?: boolean;
  @Input() isMultiItem?: boolean;
  @Input() quotes?: Quote[];
  @Input() selectedPaymentTypeIsCard?: boolean;
  @Input() storeDiscountPercentage?: number;

  cardTotal?: number;
  defaultDiscountPeriod = 3;
  directDebitDiscount?: number;
  directDebitTotal?: number;
  discountPeriod?: number;
  firstDiscountPayment?: number;
  hasDiscount?: boolean;
  monthlyPaymentTotal?: number;
  numberOfMonthlyPayments?: number;
  subsequentMonthlyPaymentTotal?: number;
  subsequentPayment?: number;
  totalWithDiscount?: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quotes) {
      this.buildQuoteTotal();
    }
  }

  private buildQuoteTotal(): void {
    this.discountPeriod = this.quotes?.reduce(
      (discountPeriod: number, item: Quote) =>
        (discountPeriod =
          item?.cover?.paymentOptions?.directDebit?.discountPeriod ?? 0),
      0
    );
    this.hasDiscount = this.quotes?.reduce(
      (discount: boolean, item: Quote) =>
        (discount =
          item?.cover?.paymentOptions?.directDebit?.discount ?? false),
      false
    );
    this.totalWithDiscount = this.quotes?.reduce(
      (totalOfDiscount: number, item: Quote) =>
        item?.cover?.paymentOptions?.directDebit?.fee || 0,
      0
    );
    this.firstDiscountPayment = this.quotes?.reduce(
      (firstDiscountPayment: number, item: Quote) =>
        (firstDiscountPayment =
          item?.cover?.paymentOptions?.directDebit?.firstDiscountPayment ?? 0),
      0
    );
    this.cardTotal = this.quotes?.reduce(
      (total: number, item: Quote) =>
        (total += item?.cover?.paymentOptions?.card?.fee ?? 0),
      0
    );
    this.directDebitTotal = this.quotes?.reduce(
      (total: number, item: Quote) =>
        (total += item?.cover?.paymentOptions?.directDebit?.fee ?? 0),
      0
    );
    this.subsequentMonthlyPaymentTotal = this.quotes?.reduce(
      (total: number, item: Quote) =>
        (total +=
          item?.cover?.paymentOptions?.directDebit?.subsequentPayment ?? 0),
      0
    );
    this.subsequentPayment = this.quotes?.reduce(
      (total: number, item: Quote) =>
        item?.cover?.paymentOptions?.directDebit?.discount
          ? item?.cover?.paymentOptions?.directDebit
              ?.subsequentDiscountPayment ?? 0
          : item?.cover?.paymentOptions?.directDebit?.subsequentPayment ?? 0,
      0
    );
    this.numberOfMonthlyPayments = this.quotes?.reduce(
      (count: number, item: Quote) =>
        Math.max(
          count,
          item?.cover?.paymentOptions?.directDebit?.numPayments ?? 0
        ),
      0
    );

    this.directDebitDiscount =
      this.cardTotal && this.directDebitTotal
        ? this.cardTotal - this.directDebitTotal
        : undefined;
  }
}
