import {
  BasketItemApplianceDetails,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  ItemType,
  PaymentType,
} from './basket-item.types';
import { CoverPaymentOptions, TaggingMetadata } from './quote-page.types';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';

export interface CheckoutConfirmation {
  planNumber: string;
  breakdownStartDate?: string;
  renewalDate?: string;
  coverStartDate?: string;
}

export interface CheckoutConfirmationData
  extends CheckoutConfirmation,
    BasketItemApplianceDetails {
  heading: string;
  paymentType: string;
  preferredPaymentDay: number;
  coverType: string;
  isHeating: boolean;
  itemQuoteDetails: BasketItemQuoteDetails;
  paymentOptions: CoverPaymentOptions;
}

export interface CheckoutPlanDetails {
  paymentOption?: BasketItemPaymentOption;
  paymentDay?: number;
  confirmation?: CheckoutConfirmation;
  applianceDetails?: BasketItemApplianceDetails;
  periodOfCover?: number;
  itemType?: ItemType;
  paymentTerm?: string;
  excessAmount?: number;
  productType?: string;
}

export interface CheckoutConfirmationTaggingData extends TaggingMetadata {
  user: User | undefined;
  basketId: string;
  fee: number;
  coverName: string;
  paymentType: PaymentType | undefined;
  planNumber: string;
  digitalId: string;
  isHeating: boolean;
  cardNumber: string | undefined;
}
