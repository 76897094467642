import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dgx-dfb-hightlighted-text',
  templateUrl: './hightlighted-text.component.html',
  styleUrls: ['./hightlighted-text.component.scss'],
})
export class HightlightedTextComponent implements OnChanges {
  @Input() text = '';
  @Input() highlightText: string | undefined = '';
  @Input() sanitise?: 'block' | 'encrypt';

  highlightedOption: string[] = [];

  ngOnChanges(changes: SimpleChanges) {
    const text = changes?.text ? changes?.text?.currentValue : this.text;
    const highlightText = changes?.highlightText
      ? changes?.highlightText?.currentValue
      : this.highlightText;
    if (text || highlightText) {
      this.highlightedOption = this.highlightOption(text, highlightText);
    }
  }

  private highlightOption(option: string, text: string): string[] {
    const parts =
      text.length > 0 ? option.split(new RegExp(`(${text})`, 'gmi')) : [option];
    return parts;
  }
}
