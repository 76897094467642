import { OfferRenewalFormField } from '@domgen/dgx-fe-business-models';

export interface OfferRenewalFormSubmit {
  [OfferRenewalFormField.ReferenceNumber]: string;
  [OfferRenewalFormField.PlanNumber]: string;
  [OfferRenewalFormField.PostCode]: string;
  singleUseId: string;
}

export interface OfferFormSubmit {
  [OfferRenewalFormField.ReferenceNumber]: string;
  [OfferRenewalFormField.PostCode]: string;
}

export interface RenewalFormSubmit {
  [OfferRenewalFormField.PlanNumber]: string;
  [OfferRenewalFormField.PostCode]: string;
}

export const OFFER = 'offer';
export const RENEWAL = 'renewal';
export const REFERRER = 'orenreferrer';

export interface OfferRenewalHomePageRedirect {
  referenceNo: string;
  postCode: string;
  [REFERRER]: string;
}
