<div *ngIf="device">
  <div class="device-title typog-h2">{{ device.title }}</div>
  <div class="row device-content">
    <div class="device-image">
      <img
        class="mt-8"
        width="100%"
        [src]="device.img"
        [alt]="device?.imgAlt"
      />
    </div>
    <div class="device-description mt-8">{{ device.desc }}</div>
  </div>
</div>
