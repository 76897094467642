import { Brand, RemoteData } from '@common/util-models';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { getApplianceDetailsState } from '../appliance-details.selectors';
import { ApplianceDetailsState } from '../appliance-details.reducer';
import { brandsAdapter, BrandsState } from './brands.reducer';

const { selectAll: selectBrands, selectEntities: selectBrandEntities } =
  brandsAdapter.getSelectors();

export const getBrandsState = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState): BrandsState => state.brands
);

export const getBrandsLoaded = createSelector(
  getBrandsState,
  (state: BrandsState): RemoteData => state.remoteState
);

export const getBrands = createSelector(
  getBrandsState,
  (state: BrandsState): Brand[] => selectBrands(state)
);

export const getBrandEntities = createSelector(
  getBrandsState,
  (state: BrandsState): Dictionary<Brand> => selectBrandEntities(state)
);

export const getBrandByCode = (props: { code: string }) =>
  createSelector(
    getBrandEntities,
    (brandsEntities: Dictionary<Brand>): Brand | undefined =>
      brandsEntities[props.code]
  );
