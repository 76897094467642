import { CmsFormField } from '@domgen/dgx-fe-business-models';

export type ReinstateFormField =
  | 'planNumber'
  | 'lastName'
  | 'postCode'
  | 'email'
  | 'termsAndConditions';

export type ReinstateFormSubmit = Omit<
  Record<ReinstateFormField, string>,
  'termsAndConditions'
>;

export type ReinstateFormFieldTypes =
  | CmsFormField<'planNumber'>
  | CmsFormField<'lastName'>
  | CmsFormField<'postCode'>
  | CmsFormField<'email'>
  | CmsFormField<'termsAndConditions'>
  | CmsFormField<'reactivate'>;

export type ReinstateFormFields = ReinstateFormFieldTypes[];
