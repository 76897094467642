import {
  Appliance,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ApplianceDetailsActions from './appliances.actions';

export interface AppliancesState extends EntityState<Appliance> {
  remoteState: RemoteData<null>;
  selectedAppliance?: Appliance;
}

export const APPLIANCES_KEY = 'appliances';

export const appliancesAdapter: EntityAdapter<Appliance> =
  createEntityAdapter<Appliance>({
    selectId: (appliance) => appliance.code,
  });

export const initialAppliancesState: AppliancesState =
  appliancesAdapter.getInitialState({
    remoteState: remoteDataNotFetched(),
    selectedAppliance: undefined,
  });

export const appliancesReducer = createReducer(
  initialAppliancesState,
  on(ApplianceDetailsActions.loadAppliances, (state) => ({
    ...state,
    remoteState: remoteDataLoading(),
  })),

  on(ApplianceDetailsActions.loadAppliancesSuccess, (state, { appliances }) =>
    appliancesAdapter.setAll(appliances, {
      ...state,
      remoteState: remoteDataOK(null),
    })
  ),

  on(ApplianceDetailsActions.loadAppliancesFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),

  on(ApplianceDetailsActions.applianceSelected, (state, { code }) => {
    return {
      ...state,
      selectedAppliance: state.entities[code],
    };
  })
);
