import { Injectable } from '@angular/core';
import {
  ApplianceDetailsConfig,
  ClientConfig,
  DiscountStoreConfig,
  ErrorPageData,
  JourneyType,
  WorkingOrderErrorMessage,
  QuotesConfig,
  SafeHandsClientConfig,
  TrustBoxClientConfig,
} from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeatureConfigState } from './feature-config.reducers';
import * as FeatureConfigSelectors from './feature-config.selectors';
import {
  getErrorPageClientConfig,
  getSafeHandsClientConfig,
  getTrustBoxClientConfig,
} from './feature-config.selectors';

@Injectable()
export class FeatureConfigFacade {
  remoteStateLoaded$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.getRemoteStateLoaded)
    .pipe(filter((loaded: boolean) => loaded));

  discountStore$: Observable<DiscountStoreConfig | undefined> =
    this.store.select(FeatureConfigSelectors.getDiscountStoreConfig);

  applianceDetails$: Observable<ApplianceDetailsConfig> = this.store
    .select(FeatureConfigSelectors.getApplianceDetailsConfig)
    .pipe(filterNullUndefined());

  quotes$: Observable<QuotesConfig> = this.store
    .select(FeatureConfigSelectors.getQuotesConfig)
    .pipe(filterNullUndefined());

  isEngineerJourney$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.isEngineerJourney)
    .pipe(filterNullUndefined());

  isMobileJourney$: Observable<boolean> = this.store
    .select(FeatureConfigSelectors.isMobileJourney)
    .pipe(filterNullUndefined());

  journeyType$: Observable<JourneyType> = this.store
    .select(FeatureConfigSelectors.getConfigJourneyType)
    .pipe(filterNullUndefined());

  trustBoxClientConfig$: Observable<TrustBoxClientConfig | undefined> =
    this.store.select(getTrustBoxClientConfig);

  safeHandsClientConfig$: Observable<SafeHandsClientConfig | undefined> =
    this.store.select(getSafeHandsClientConfig);

  errorPageClientConfig$: Observable<ErrorPageData | undefined> =
    this.store.select(getErrorPageClientConfig);

  workingOrderErrorMessageClientConfig$: Observable<
    WorkingOrderErrorMessage | undefined
  > = this.store.select(
    FeatureConfigSelectors.getWorkingOrderErrorMessageClientConfig
  );

  constructor(private store: Store<FeatureConfigState>) {}

  clientConfig$<T extends ClientConfig>(
    filterNullAndUndefinedValues: boolean = true
  ): Observable<T> {
    const clientConfig$ = this.store.select(
      FeatureConfigSelectors.getClientConfig
    ) as Observable<T>;

    return filterNullAndUndefinedValues
      ? clientConfig$.pipe(filterNullUndefined())
      : clientConfig$;
  }
}
