import { Observable, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * @deprecated Use `import { filterNullUndefined } from '@domgen/dgx-fe-common';` instead.
 */
export function filterNullUndefined<T>(): OperatorFunction<
  T | undefined | null,
  T
> {
  return function (source: Observable<T | undefined | null>): Observable<T> {
    return source.pipe(
      filter(
        (value: T | undefined | null) => value !== undefined && value !== null
      ),
      map((value: T | undefined | null) => value as T)
    );
  };
}
