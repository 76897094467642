import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  Appliance,
  ApplianceDetailsConfig,
  ApplianceFormFields,
  ApplianceFormSubmit,
  ApplianceFormValue,
  Brand,
  isRemoteDataLoading,
  Normalize,
  RemoteData,
} from '@common/util-models';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { InvalidFormSubmissionPayload } from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable } from 'rxjs';
import { ApplianceDetailsFormConfigService } from './appliance-details-form-config.service';
import { ApplianceDetailsFormStateService } from './appliance-details-form-state.service';

@Component({
  selector: 'sales-appliance-details-form',
  templateUrl: './appliance-details-form.component.html',
  styleUrls: ['./appliance-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ApplianceDetailsFormStateService,
    ApplianceDetailsFormConfigService,
  ],
})
export class ApplianceDetailsFormComponent {
  @Output() appliance: Observable<Appliance | undefined> =
    this.applianceDetailsFormService.appliance$;

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  @Output()
  invalidSubmit: EventEmitter<InvalidFormSubmissionPayload> = new EventEmitter();

  @Output()
  validatedSubmit: Observable<ApplianceFormSubmit> =
    this.applianceDetailsFormService.validatedSubmit$;

  @Output()
  validatedForm: Observable<ApplianceFormSubmit> =
    this.applianceDetailsFormService.validatedForm$;

  @Output() formChanges: Observable<UntypedFormGroup> =
    this.applianceDetailsFormService.form$;

  @Output()
  valueChanges: Observable<ApplianceFormValue> =
    this.applianceDetailsFormService.formValue$;

  @Output()
  formValidationMessagesChanges: Observable<Normalize<Normalize<string>>> =
    this.applianceDetailsFormService.formValidationMessages$;

  @Input() validate = false;

  @Input() set cmsFormData(
    setCmsFormData: ApplianceFormFields | null | undefined
  ) {
    if (setCmsFormData) {
      this.applianceDetailsFormService.setCmsFormData(setCmsFormData);
    }
  }

  @Input() set prefilledForm(
    prefilledForm: ApplianceFormSubmit | null | undefined
  ) {
    this.applianceDetailsFormService.setPrefilledFormData(prefilledForm);
  }

  @Input() set appliances(appliances: Appliance[] | null | undefined) {
    this.applianceDetailsFormService.setAppliances(appliances);
  }

  @Input() set brands(brands: Brand[] | null | undefined) {
    this.applianceDetailsFormService.setBrands(brands);
  }

  @Input() set brandsLoaded(brandsLoaded: RemoteData | null | undefined) {
    this.applianceDetailsFormService.setBrandsLoaded(
      brandsLoaded ? isRemoteDataLoading(brandsLoaded) : true
    );
  }

  @Input() set fieldsOptions(config: ApplianceDetailsConfig | null) {
    this.applianceDetailsFormService.setConfig(config);
  }

  readonly vm$ = this.applianceDetailsFormService.vm$;

  constructor(
    private readonly applianceDetailsFormService: ApplianceDetailsFormStateService,
    private sanitizer: DomSanitizer
  ) {}

  handleValidFormSubmission(value: ApplianceFormValue) {
    this.applianceDetailsFormService.updateSubmittedForm(value);
  }

  handleInvalidFormSubmission(value: InvalidFormSubmissionPayload) {
    this.invalidSubmit.emit(value);
  }

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
