import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { DirectDebitDetails, Person } from '@common/util-models';
import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserProfileApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {}

  getPersonalDetails(): Observable<Person> {
    const getPersonalDetailsUrl = `${this.Config.personApi}/get-person-details`;
    return this.httpClient
      .get<{ result: { person: Person } }>(getPersonalDetailsUrl, {
        ...this.getHeaders(),
      })
      .pipe(map((apiResponse) => apiResponse.result.person));
  }

  getDirectDebitDetails(): Observable<DirectDebitDetails> {
    const getDirectDebitDetailsUrl = `${this.Config.personApi}/get-direct-debit-details`;
    return this.httpClient
      .get<{ result: DirectDebitDetails }>(getDirectDebitDetailsUrl, {
        ...this.getHeaders(),
      })
      .pipe(
        this.noDirectDebitIf404Error(),
        map((apiResponse) => apiResponse.result)
      );
  }

  private getHeaders() {
    return {
      headers: {
        'request-source': this.Config.catalogueStore,
        'request-action': this.Config.action,
        'wl-client': this.Config.wlClient,
      },
      withCredentials: true,
    };
  }

  noDirectDebitIf404Error(): MonoTypeOperatorFunction<{
    result: DirectDebitDetails;
  }> {
    const isError404 = (error: Error) =>
      error instanceof HttpErrorResponse && error.status === 404;
    return (source$) => {
      return source$.pipe(
        catchError((error: HttpErrorResponse) => {
          if (isError404(error)) {
            return of({
              result: {
                bankDetails: {},
                paymentToken: null,
              } as DirectDebitDetails,
            });
          }
          return throwError(error);
        })
      );
    };
  }

  private get Config() {
    return this.buildConfigService.config;
  }
}
