import {
  ApplianceDetailsConfig,
  DiscountStoreConfig,
  QuotesConfig,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
  ClientConfig,
} from '@common/util-models';
import { Action, createReducer, on } from '@ngrx/store';
import * as FeatureConfigActions from './feature-config.actions';

export const FEATURE_CONFIG_FEATURE_KEY = 'feature-config';

export interface FeatureConfigState {
  applianceDetails?: ApplianceDetailsConfig;
  discountStore?: DiscountStoreConfig;
  quotes?: QuotesConfig;
  remoteState: RemoteData<null>;
  clientConfig?: ClientConfig;
}

export interface FeatureConfigPartialState {
  readonly [FEATURE_CONFIG_FEATURE_KEY]: FeatureConfigState;
}

export const initialState: FeatureConfigState = {
  remoteState: remoteDataNotFetched(),
  quotes: undefined,
  applianceDetails: undefined,
  discountStore: undefined,
  clientConfig: undefined,
};

const featureConfigReducer = createReducer(
  initialState,

  on(FeatureConfigActions.loadFeatureConfig, (state) => ({
    ...state,
    remoteState: remoteDataLoading(),
  })),

  on(FeatureConfigActions.loadFeatureConfigSuccess, (state, { response }) => ({
    ...state,
    ...response,
    remoteState: remoteDataOK(null),
  })),

  on(FeatureConfigActions.loadFeatureConfigFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  }))
);

export function reducer(state: FeatureConfigState | undefined, action: Action) {
  return featureConfigReducer(state, action);
}
