import {
  Basket,
  BasketItem,
  BasketItemApplianceDetails,
  BasketItemData,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  CheckoutBasket,
  ContractType,
  CoverType,
  CoverTypeName,
  CreateQuoteResponse,
  ItemType,
  PaymentType,
  Quote,
  SelectedBasketItemApplianceQuote,
} from '@common/util-models';

export const MOCK_BASKET_ITEM_APPLIANCE_DETAILS: BasketItemApplianceDetails = {
  purchaseMonth: 3,
  purchaseYear: 2018,
  purchasePrice: 200,
  applianceCode: 'AW',
  applianceName: 'Washing Machine',
  applianceCategory: 'Washing Machine',
  applianceIcon:
    'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
  applianceImage:
    'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
  brandCode: 'HOTP',
  brandName: 'Hotpoint',
  warranty: 12,
  inWarranty: true,
};

export const MOCK_BASKET_ITEM_PAYMENT_OPTION: BasketItemPaymentOption = {
  preferredPayment: true,
  paymentType: PaymentType.DirectDebit,
  paymentProfile: 'P',
  firstPayment: 8,
  subsequentPayment: 8,
  numPayments: 11,
  numSubsequentPayments: 10,
  paymentFrequency: 1,
  fee: 96,
};

export const MOCK_BASKET_ITEM_DIRECT_DEBIT_PAYMENT_OPTION: BasketItemPaymentOption =
  {
    preferredPayment: true,
    paymentType: PaymentType.DirectDebit,
    paymentProfile: 'P',
    firstPayment: 8,
    subsequentPayment: 8,
    numPayments: 11,
    numSubsequentPayments: 10,
    paymentFrequency: 1,
    fee: 96,
  };

export const MOCK_BASKET_ITEM_CARD_PAYMENT_OPTION: BasketItemPaymentOption = {
  preferredPayment: true,
  paymentType: PaymentType.Card,
  paymentProfile: 'P',
  firstPayment: 96,
  subsequentPayment: 0,
  numPayments: 1,
  numSubsequentPayments: 0,
  paymentFrequency: 1,
  fee: 96,
};

export const MOCK_BASKET_ITEM_QUOTE_DETAILS: BasketItemQuoteDetails = {
  quoteId: '1',
  contractTypeCode: ContractType.Insurance,
  productType: 'I',
  companyCode: 'D',
  schemeCode: 'VU6',
  isBundle: false,
  paymentTerm: 'IPM',
  coverType: CoverType.Standard,
  breakdownStartDate: '2021-01-07',
  periodOfCover: 0,
  waitDays: 30,
  excessAmount: 0,
  paymentOptions: [MOCK_BASKET_ITEM_PAYMENT_OPTION],
};

export const MOCK_BASKET_ITEM_DATA: BasketItemData = {
  quotes: [MOCK_BASKET_ITEM_QUOTE_DETAILS],
  applianceDetails: MOCK_BASKET_ITEM_APPLIANCE_DETAILS,
};

export const MOCK_BASKET_ITEM: BasketItem = {
  requestSource: 'DandGUK',
  basketId: '1',
  itemId: '1',
  itemType: ItemType.Quote,
  created: '2020-12-08T10:39:12.557Z',
  updated: '2020-12-08T10:39:12.557Z',
  expiry: '2020-12-08T13:27:13.000Z',
  status: 'ACTIVE',
  data: MOCK_BASKET_ITEM_DATA,
};

export const MOCK_BASKET: Basket = {
  basketId: '1',
  created: '2020-12-08T10:39:12.022Z',
  updated: '2020-12-08T10:39:12.557Z',
  expiry: '2020-12-15T10:39:13.000Z',
  status: 'ACTIVE',
  requestSource: 'DandGUK',
  items: [MOCK_BASKET_ITEM],
};

export const MOCK_SELECTED_BASKET_ITEM_APPLIANCE_QUOTE: SelectedBasketItemApplianceQuote =
  {
    itemId: MOCK_BASKET_ITEM.itemId,
    itemType: MOCK_BASKET_ITEM.itemType,
    applianceDetails: MOCK_BASKET_ITEM_APPLIANCE_DETAILS,
    quote: MOCK_BASKET_ITEM_QUOTE_DETAILS,
  };

export const MOCK_CREATE_QUOTE_RESPONSE = {
  requestedItemId: '2',
  basket: {
    basketId: '1',
    created: '2020-12-08T10:39:12.022Z',
    updated: '2020-12-08T10:39:12.557Z',
    expiry: '2020-12-15T10:39:13.000Z',
    status: 'ACTIVE',
    requestSource: 'DandGUK',
    items: [
      {
        requestSource: 'DandGUK',
        basketId: '1',
        itemId: '1',
        itemType: ItemType.Quote,
        created: '2020-12-08T10:39:12.557Z',
        updated: '2020-12-08T10:39:12.557Z',
        expiry: '2020-12-08T13:27:13.000Z',
        status: 'ACTIVE',
        data: {
          quotes: [
            {
              quoteId: '1',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              isBundle: false,
              paymentTerm: 'IPM',
              coverType: 'STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '2',
              coverType: 'STD',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 50,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 6,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '3',
              coverType: 'STD',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 95,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 4,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '4',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '5',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 10,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '6',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 50,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 120,
                },
              ],
            },
            {
              quoteId: '7',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 95,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 6,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 120,
                },
              ],
            },
            {
              quoteId: '8',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'P-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 144,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 144,
                },
              ],
            },
            {
              quoteId: '9',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'P-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 50,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 140,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 140,
                },
              ],
              metadata: {
                companyCode: 'D',
                schemeCode: 'VU6',
                inWarranty: true,
                outOfWarranty: false,
              },
            },
          ],
          applianceDetails: {
            purchaseMonth: 3,
            purchaseYear: 2018,
            purchasePrice: 200,
            applianceCode: 'AW',
            applianceName: 'Washing Machine',
            applianceCategory: 'Washing Machine',
            applianceIcon:
              'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
            applianceImage:
              'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
            brandCode: 'HOTP',
            brandName: 'Hotpoint',
            warranty: 12,
            inWarranty: true,
          },
        },
      },
      {
        requestSource: 'DandGUK',
        basketId: '1',
        itemId: '2',
        itemType: ItemType.Quote,
        created: '2020-12-08T11:39:12.557Z',
        updated: '2020-12-08T11:39:12.557Z',
        expiry: '2020-12-08T14:27:13.000Z',
        status: 'ACTIVE',
        data: {
          quotes: [
            {
              quoteId: '10',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'G-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '11',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              coverType: 'P-STD',
              isBundle: false,
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 144,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 144,
                },
              ],
            },
          ],
          applianceDetails: {
            purchaseMonth: 3,
            purchaseYear: 2018,
            purchasePrice: 200,
            applianceCode: 'E7',
            applianceName: 'Condensing Boiler',
            applianceCategory: 'Condensing Boiler',
            brandCode: 'WR',
            brandName: 'Worcester',
            warranty: 12,
            inWarranty: true,
          },
        },
      },
    ],
  },
} as CreateQuoteResponse;

export const MOCK_MULTI_ITEM_QUOTE_SUMMARY: Quote[] = [
  {
    applianceCode: 'CN',
    applianceCategory: 'Fridges & Freezers',
    applianceName: 'American Fridge Freezer',
    brandCode: 'BEKO',
    brandName: 'Beko',
    purchaseDate: new Date('2017-10-01T03:00:00.000Z'),
    applianceIcon: 'assets/images/catalogue-icons/american-fridge-freezer.svg',
    cover: {
      name: CoverTypeName.STD,
      quoteId: '7bf963e2-6547-4a8a-8284-fd875478cd0c',
      isBundle: false,
      coverType: CoverType.Gold,
      excessAmount: 0,
      contractTypeCode: ContractType.Insurance,
      productType: 'AC',
      companyCode: 'D',
      schemeCode: 'VU6',
      breakdownStartDate: '2021-11-03',
      periodOfCover: 0,
      waitDays: 30,
      paymentTerm: 'IPM',
      paymentOptions: {
        directDebit: {
          paymentType: PaymentType.DirectDebit,
          preferredPayment: true,
          paymentProfile: 'P',
          numPayments: 0,
          paymentFrequency: 1,
          firstPayment: 9.4,
          subsequentPayment: 9.4,
          fee: 9.4,
          numSubsequentPayments: 0,
        },
      },
    },
    itemId: '82ba47f3-e7e1-42b7-bd0c-0c0dedf97633',
    itemType: ItemType.Quote,
  },
];

export const MOCK_BASKET_QUOTE_ITEM: CheckoutBasket = {
  basketId: 'e4fb68aa-68ec-4402-8a7e-eca92f2de512',
  items: [
    {
      itemId: '8c95b913-c59e-402e-806b-1d19ab7849bc',
      quoteId: '372e3545-a126-4a1d-aaf9-71694e0de2f2',
      itemType: ItemType.Quote,
      paymentOptions: {
        directDebit: {
          paymentType: PaymentType.Card,
          preferredPayment: false,
          paymentProfile: 'V',
          numPayments: 12,
          paymentFrequency: 1,
          firstPayment: 0,
          subsequentPayment: 0,
          fee: 193.2,
        },
      },
      contractType: ContractType.MSP,
      companyCode: 'A',
      schemeCode: 'Z5W',
    },
  ],
};

export const MOCK_BASKET_QUOTE_ITEM_DISCOUNTED = {
  basket: {
    ...MOCK_BASKET_QUOTE_ITEM,
    items: [
      {
        ...MOCK_BASKET_QUOTE_ITEM.items[0],
        paymentOptions: {
          directDebit: {
            ...MOCK_BASKET_QUOTE_ITEM.items[0].paymentOptions.directDebit,
            discount: true,
            discountPeriod: 3,
            firstDiscountPayment: 8.05,
            totalOfDiscount: 24.15,
          },
        },
      },
    ],
  } as never,
};
