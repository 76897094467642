import { CmsAsset } from '@domgen/dgx-fe-business-models';

export interface QuoteCms {
  included: string[];
  excluded: string[];
  termsAndConditionsAsset: CmsAsset;
  termsAndConditionsPDAsset?: CmsAsset;
  ipidAsset: CmsAsset;
  ipidPDAsset?: CmsAsset;
  jcrName: string;
  formName: string;
  banner: string;
  planDurationHeading: string;
  planIntroBody?: string;
  planDurationBody: string;
  coverType?: string;
  needToKnow?: string;
}
