import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { take, tap } from 'rxjs/operators';

import { ListOverlayService } from './list-overlay.service';

let nextId = 0;

export interface ListOverlayEvent {
  type: 'click' | 'mouseEnter';
  payload: number;
}

@Component({
  selector: 'dgx-dfb-list-overlay',
  templateUrl: './list-overlay.component.html',
  styleUrls: ['./list-overlay.component.scss'],
  providers: [ListOverlayService],
})
export class ListOverlayComponent implements OnInit {
  @Input() showAllOptions = false;
  @Input() options: string[] | null = [];
  @Input() activeOptionIndex = -1;
  @Input() hightlightedText: string | undefined = '';
  @Input() hasError: boolean | undefined = false;
  @Input() hasScroll: boolean | undefined = false;
  @Input() sanitise?: 'block' | 'encrypt';

  @Output() emitEvent = new EventEmitter<ListOverlayEvent>();
  @Output() emitClose = new EventEmitter<boolean>();

  optionId = `list-overlay-${nextId++}`;

  constructor(
    private listOverlayService: ListOverlayService,
    elementRef: ElementRef<HTMLElement>
  ) {
    this.listOverlayService.setNativeDomElement(elementRef.nativeElement);
  }

  ngOnInit() {
    this.listOverlayService.dismissOptionsUpdater$
      .pipe(
        take(1),
        tap(() => (this.activeOptionIndex = -1)),
        tap(() => this.emitClose.emit(true))
      )
      .subscribe();
  }

  onClick(index: number) {
    this.emitEvent.emit({
      type: 'click',
      payload: index,
    });
  }

  onMouseEnter(index: number) {
    this.emitEvent.emit({
      type: 'mouseEnter',
      payload: index,
    });
  }
}
