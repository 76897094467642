<dgx-dfb-field-layout
  [id]="field?.controlName"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  class="form-group__sortcode"
>
  <div class="input-wrapper">
    <div class="sortcode-wrap">
      <input
        autocomplete="off"
        class="form-control"
        placeholder="XX"
        (input)="onInputChange($any($event.target))"
        (blur)="onInputBlur($any($event.target))"
        (mousedown)="onMousedown($any($event.target))"
        (keydown)="onKeyDown($event, $any($event.target))"
        maxlength="2"
        name="sort1"
        #sort1
        type="text"
        [dgxQmSanitise]="'block'"
        [value]="inputs.sort1.value"
        [class.segment-touched]="inputs.sort1.touched"
      />
      <input
        autocomplete="off"
        class="form-control"
        placeholder="XX"
        (input)="onInputChange($any($event.target))"
        (blur)="onInputBlur($any($event.target))"
        (mousedown)="onMousedown($any($event.target))"
        (keydown)="onKeyDown($event, $any($event.target))"
        maxlength="2"
        name="sort2"
        #sort2
        type="text"
        [dgxQmSanitise]="'block'"
        [value]="inputs.sort2.value"
        [class.segment-touched]="inputs.sort2.touched"
      />
      <input
        autocomplete="off"
        class="form-control"
        placeholder="XX"
        (input)="onInputChange($any($event.target))"
        (keydown)="onKeyDown($event, $any($event.target))"
        (mousedown)="onMousedown($any($event.target))"
        (blur)="onInputBlur($any($event.target))"
        maxlength="2"
        name="sort3"
        #sort3
        type="text"
        [dgxQmSanitise]="'block'"
        [value]="inputs.sort3.value"
        [class.segment-touched]="inputs.sort3.touched"
      />
      <ui-form-validation-icon
        [validityStatus]="validity"
      ></ui-form-validation-icon>
    </div>
  </div>
</dgx-dfb-field-layout>
