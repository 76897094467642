<div class="error">
  <div class="container">
    <div class="error__content text--center">
      <h2 class="error__header">{{ messages.header }}</h2>
      <p class="error__support">
        {{ messages.support }}
      </p>
      <div>
        <p class="customer-service-contact__title">
          {{ messages.customerServiceTitle }}
        </p>
        <p class="customer-service-contact__help-number">
          <a class="link" href="tel:{{ messages.helpNumber }}">{{
            messages.helpNumber
          }}</a>
        </p>
        <strong class="customer-service-contact__available-text">
          {{ messages.availabilityText }}
        </strong>
        <p *ngFor="let hour of messages.availabilityHours">{{ hour }}</p>
      </div>
    </div>
  </div>
</div>
