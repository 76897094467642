import { Component, Input } from '@angular/core';
import { CoverType, QuoteCms } from '@common/util-models';
import { QuoteDocument } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'wl-need-to-know',
  templateUrl: './need-to-know.component.html',
  styleUrls: ['./need-to-know.component.scss'],
})
export class NeedToKnowComponent {
  @Input() cmsData!: QuoteCms | null | undefined;
  @Input() productInfo: QuoteDocument | undefined = undefined;
  @Input() terms: QuoteDocument | undefined = undefined;
  @Input() policy: QuoteDocument | undefined = undefined;
  @Input() isInsurance: boolean | null = false;
  @Input() displayTCLink = true;
  @Input() coverType!: CoverType | null;
  @Input() brandName = '';

  get content() {
    return this.cmsData?.needToKnow?.replace(/\n/g, '<br/>');
  }
}
//todo: if this varies based on product, ideally this should be driven by CMS product-features
