export interface CardPaymentRedirectRequest {
  request_source: string;
  channel_code?: string;
  channel_group_code?: string;
  client_code?: string;
  client_group_code?: string;
  company_code: string;
  scheme_code: string;
  reference_number?: string;
  amount: number;
  currency?: string;
  description: string;
  name: string;
  mob_tel: string;
  email_addr: string;
  transaction_source: string;
  transaction_type: string;
  ip_address?: string;
  address_line_1: string;
  address_line_2?: string;
  address_line_3?: string;
  address_line_4?: string;
  postal_code: string;
  country_code: string;
}

export type CardPaymentRedirectRequestWithoutConfigParameters = Omit<
  CardPaymentRedirectRequest,
  'request_source' | 'description' | 'transaction_source' | 'transaction_type'
>;

export interface CardPaymentRedirectResponse {
  client_reference_number: string;
  url: string;
  order_number: string;
  status: string;
}
