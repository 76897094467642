import { CorrespondenceAddress } from './user-profile.types';

export interface YourDetails {
  name: string;
  email?: string;
  mobile?: string;
  landline?: string;
  address: CorrespondenceAddress;
}

export interface PaymentDetails {
  accountName?: string;
  accountNumber?: string;
  sortCode?: string;
  paymentDay?: number;
  paymentToken?: string | null;
}
