<ng-container *ngIf="vm$ | async as vm">
  <dgx-dfb-field-layout
    [id]="typeaheadId"
    [label]="vm.fieldDef?.label?.text"
    [tooltip]="vm.fieldDef?.tooltip"
    [hint]="vm.fieldDef?.hint"
    [errorMessage]="vm.errorMessage"
    data-hook="dgx-sales-input"
  >
    <div class="typeahead__wrapper">
      <div class="input__wrapper">
        <input
          class="form-control"
          autocomplete="off"
          type="text"
          [id]="typeaheadId"
          [name]="vm.fieldDef?.controlName"
          [placeholder]="vm.fieldDef?.placeholder"
          [value]="vm.value"
          [dgxQmSanitise]="vm.fieldDef?.sanitise"
          [disabled]="vm.disable"
          (input)="onChange($event)"
          (blur)="onBlur($event)"
          (keydown)="onKeyDown($event)"
          (focus)="isInputFocused = true"
          (focusout)="isInputFocused = false"
        />
        <i
          class="icon icon--lg typeahead__trigger"
          [ngClass]="{
            'icon-close': vm.value !== '' || isInputFocused,
            'icon-search': !vm.value && !isInputFocused
          }"
          (click)="clearControl()"
        ></i>
        <ui-form-validation-icon
          [validityStatus]="validity"
        ></ui-form-validation-icon>
      </div>
      <div
        class="typeahead__results"
        *ngIf="vm.showOptions && !vm.loading"
        [ngClass]="{
          'typeahead__results--all-options': vm?.fieldDef?.showAllOptions
        }"
      >
        <button
          *ngFor="let option of vm.filteredOptions; let idx = index"
          type="button"
          class="typeahead__results-button {{ vm.optionClass }}"
          [id]="typeaheadId + '-' + idx"
          [class.active]="idx === vm.activeOptionIndex"
          (mouseenter)="onMouseEnter(idx)"
          (click)="onClick(idx)"
        >
          <ng-container
            *ngFor="
              let part of vm.highlightedFilteredOptions[idx];
              let isOdd = odd
            "
          >
            <span
              *ngIf="isOdd; else even"
              class="typeahead-option--highlight"
              >{{ part }}</span
            >
            <ng-template #even>{{ part }}</ng-template>
          </ng-container>
        </button>
      </div>
      <div
        class="typeahead__results typeahead__results--empty"
        *ngIf="noResultsFound | async"
      >
        {{ vm.fieldDef?.emptyResultText || 'No match found' }}
      </div>
      <div
        class="typeahead__results typeahead__results--empty"
        *ngIf="vm.loading"
      >
        {{ vm.fieldDef?.loadingText || 'Loading...' }}
      </div>
    </div>
  </dgx-dfb-field-layout>
</ng-container>
