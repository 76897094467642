import { BasketItemPaymentOption, PaymentDiscount } from '@common/util-models';

export function mapDiscountFields(
  paymentOption: BasketItemPaymentOption,
  discountPeriod = 3, // Fixed for now still backend definition
  discountFactor = 21 // Fixed for now still backend definition
): PaymentDiscount {
  const firstDiscountPayment = +(paymentOption.fee / discountFactor).toFixed(2);
  const totalOfDiscount = +(firstDiscountPayment * discountPeriod).toFixed(2);
  const subsequentDiscountPayment = firstDiscountPayment * 2; // Fixed still backend support it

  return {
    discount: true,
    discountPeriod,
    firstDiscountPayment,
    subsequentDiscountPayment,
    totalOfDiscount,
  };
}
