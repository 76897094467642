import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { ExpandedCoversExclusionsComponent } from './expanded-covers-exclusions.component';

@NgModule({
  imports: [CommonModule, SharedUiComponentsCoreModule],
  declarations: [ExpandedCoversExclusionsComponent],
  exports: [ExpandedCoversExclusionsComponent],
})
export class ExpandedCoverExclusionsModule {}
