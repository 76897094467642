import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  Address,
  AddressItem,
  AddressItemDetail,
  AddressListDef,
  ControlType,
  FindAddressResponse,
  Label,
  ListBoxDef,
  RetrieveAddressResponse,
} from '../../_shared/interfaces';
import { AddressLookupService } from '../../_shared/services/address-lookup.service';

@Injectable({
  providedIn: 'root',
})
export class AddressListStateHelpersService {
  constructor(private addressLookupService: AddressLookupService) {}

  updateLabel(fieldDef: AddressListDef): Label {
    if (fieldDef.initialValue?.postcode) {
      const { postcode } = fieldDef.initialValue;
      const text = `Address found for ${postcode}`;
      return { text, sanitise: fieldDef?.label?.sanitise ?? 'block' };
    }
    return fieldDef.label || ({} as Label);
  }

  getListBoxDef(addressDef: AddressListDef): ListBoxDef {
    return {
      ...addressDef,
      controlType: ControlType.LISTBOX,
      initialValue: addressDef.initialValue?.line1,
      hightlightedText: addressDef.hightlightedText,
      optionsStream$: addressDef.optionsStream$,
    } as ListBoxDef;
  }

  getAddressByPostcode(postcode: string): Observable<AddressItem[]> {
    return this.addressLookupService.findAddress(postcode).pipe(
      map(
        (findAddressResponse: FindAddressResponse) =>
          findAddressResponse?.Items[0]?.Id
      ),
      switchMap((id: string) =>
        this.addressLookupService.findAddress(postcode, id)
      ),

      map((foundAddresses: FindAddressResponse) => foundAddresses.Items)
    );
  }

  getAddressIdFromAddressItemList(
    addressItemList: AddressItem[],
    index: number
  ): string {
    return addressItemList[index].Id;
  }

  getRetrieveAddress(Id: string): Observable<Address> {
    return this.addressLookupService.retrieveAddress(Id).pipe(
      map((foundAddresses: RetrieveAddressResponse) => foundAddresses.Items[0]),
      map((addressItemDetail) =>
        this.mapAddressItemDetailToAddressSelection(addressItemDetail)
      )
    );
  }

  mapAddressItemDetailToAddressSelection(
    addressItemDetail: AddressItemDetail
  ): Address {
    return {
      city: addressItemDetail.City,
      line1: addressItemDetail.Line1,
      line2: addressItemDetail.Line2,
      county: addressItemDetail.Province,
      postcode: addressItemDetail.PostalCode,
      type: addressItemDetail.Type,
    };
  }

  emptyForm(initial: Address = {} as Address): Address {
    const empty = {
      city: '',
      line1: '',
      line2: '',
      county: '',
      postcode: '',
      type: '',
    };
    return { ...empty, ...initial };
  }
}
