import { retryPayingByDirectDebit } from '@common/data-access-shared';
import {
  BasketItemPaymentOption,
  CreateQuoteResponse,
  PaymentType,
} from '@common/util-models';
import { Action, createReducer, on } from '@ngrx/store';
import * as SelectPaymentActions from './select-payment.actions';
import { selectFirstOrPreferredPaymentOption } from './select-payment.actions';

export const SELECTPAYMENT_FEATURE_KEY = 'selectPayment';

export interface SelectPaymentState {
  selectedPaymentType: PaymentType | undefined;
}

export interface SelectPaymentPartialState {
  readonly [SELECTPAYMENT_FEATURE_KEY]: SelectPaymentState;
}

export const initialState: SelectPaymentState = {
  selectedPaymentType: undefined,
};

const selectPaymentReducer = createReducer(
  initialState,
  on(SelectPaymentActions.selectPaymentOption, (state, { paymentType }) => ({
    ...state,
    selectedPaymentType: paymentType,
  })),
  on(retryPayingByDirectDebit, (state) => ({
    ...state,
    selectedPaymentType: PaymentType.DirectDebit,
  })),
  on(selectFirstOrPreferredPaymentOption, (state, { quoteResponse }) => {
    return {
      ...state,
      selectedPaymentType: getFirstOrPreferredPaymentOption(quoteResponse),
    };
  })
);

export function reducer(state: SelectPaymentState | undefined, action: Action) {
  return selectPaymentReducer(state, action);
}

const getFirstOrPreferredPaymentOption = (
  quoteResponse: CreateQuoteResponse
) => {
  const paymentOptions: BasketItemPaymentOption[] =
    quoteResponse.basket.items[0]?.data?.quotes[0].paymentOptions;
  const preferredPaymentType: BasketItemPaymentOption | undefined =
    paymentOptions.find((paymentOption) => paymentOption.preferredPayment);

  if (preferredPaymentType) {
    return preferredPaymentType.paymentType;
  } else if (paymentOptions.length) {
    return paymentOptions[0]?.paymentType;
  }
  return PaymentType.DirectDebit;
};
