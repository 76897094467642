import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { OperatorFunction, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function pluckControl(
  controlKey: string
): OperatorFunction<UntypedFormGroup | null | undefined, AbstractControl> {
  return pipe(
    map((form) => form?.get(controlKey)),
    filter((control: AbstractControl | null | undefined) => !!control),
    map((control) => control as AbstractControl)
  );
}
