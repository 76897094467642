import {
  CheckoutBasketPartialState,
  CheckoutBasketSelectors,
} from '@common/data-access-checkout-basket';
import {
  PersonalDetailsPartialState,
  PersonalDetailsSelectors,
} from '@common/data-access-personal-details';
import {
  UserProfilePartialState,
  UserProfileSelectors,
} from '@common/data-access-user-profile';
import {
  CardPaymentRedirectRequestWithoutConfigParameters,
  CheckoutBasketItem,
  CoverPaymentOptions,
  isRemoteDataError,
  Person,
  PersonalAddress,
  PersonalDetails,
  RemoteData,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthPartialState, getIsLoggedIn } from '@shared/data-access-auth';
import {
  CardPaymentPartialState,
  CardPaymentState,
  CARD_PAYMENT_FEATURE_KEY,
} from './card-payment.reducer';

// Lookup the 'CardPayment' feature state managed by NgRx
export const getCardPaymentState = createFeatureSelector<CardPaymentState>(
  CARD_PAYMENT_FEATURE_KEY
);

export const getCardPaymentSetupRemoteState = createSelector(
  getCardPaymentState,
  (state: CardPaymentState) => state.remoteStateSetupCardPayment
);

export const getCardPaymentSetupError = createSelector(
  getCardPaymentSetupRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const getCardPaymentOrderNumber = createSelector(
  getCardPaymentState,
  (state: CardPaymentState) => state.cardPaymentOrderNumber
);

export const getCardPaymentProviderMetadata = createSelector(
  getCardPaymentState,
  (state: CardPaymentState) => state.cardPaymentProviderMetadata
);

const getLoggedInUserCardPaymentSetupRequest = createSelector<
  CheckoutBasketPartialState & UserProfilePartialState,
  [
    CheckoutBasketItem | undefined,
    CoverPaymentOptions | undefined,
    Person | undefined
  ],
  CardPaymentRedirectRequestWithoutConfigParameters | undefined
>(
  CheckoutBasketSelectors.getFirstItem,
  CheckoutBasketSelectors.getPaymentOptions,
  UserProfileSelectors.getPersonalDetails,
  (
    basketFirstItem: CheckoutBasketItem | undefined,
    paymentOptions: CoverPaymentOptions | undefined,
    person: Person | undefined
  ) => {
    // TODO: add support for multi item basket if required
    if (basketFirstItem && paymentOptions?.card && person) {
      return {
        amount: paymentOptions.card.fee,
        company_code: basketFirstItem.companyCode,
        scheme_code: basketFirstItem.schemeCode,
        name: `${person.personalDetails?.firstName} ${person.personalDetails?.surname}`,
        mob_tel:
          person.contact?.mobilePhoneNumber ||
          person.contact?.landlinePhoneNumber,
        email_addr: person.contact?.email,
        address_line_1: formatAddressLine(
          person.correspondenceAddress?.addressLine1
        ),
        address_line_2: formatAddressLine(
          person.correspondenceAddress?.addressLine2
        ),
        address_line_3: formatAddressLine(
          person.correspondenceAddress?.addressLine3
        ),
        address_line_4: formatAddressLine(
          person.correspondenceAddress?.addressLine4
        ),
        postal_code: person.correspondenceAddress?.postalCode,
        country_code: person.correspondenceAddress?.countryCode,
      } as CardPaymentRedirectRequestWithoutConfigParameters;
    }
    return undefined;
  }
);

const getGuestUserCardPaymentSetupRequest = createSelector<
  CheckoutBasketPartialState & PersonalDetailsPartialState,
  [
    CheckoutBasketItem | undefined,
    CoverPaymentOptions | undefined,
    PersonalAddress | undefined,
    PersonalDetails | undefined
  ],
  CardPaymentRedirectRequestWithoutConfigParameters | undefined
>(
  CheckoutBasketSelectors.getFirstItem,
  CheckoutBasketSelectors.getPaymentOptions,
  PersonalDetailsSelectors.getAddress,
  PersonalDetailsSelectors.getPersonalDetails,
  (
    basketFirstItem: CheckoutBasketItem | undefined,
    paymentOptions: CoverPaymentOptions | undefined,
    address: PersonalAddress | undefined,
    person: PersonalDetails | undefined
  ) => {
    // TODO: add support for multi item basket if required
    if (basketFirstItem && paymentOptions?.card && person && address) {
      return {
        amount: paymentOptions.card.fee,
        company_code: basketFirstItem.companyCode,
        scheme_code: basketFirstItem.schemeCode,
        name: `${person.firstName} ${person.lastName}`,
        mob_tel: person.mobileNumber,
        email_addr: person?.email,
        address_line_1: formatAddressLine(address.line1),
        address_line_2: formatAddressLine(address.line2),
        address_line_3: '',
        address_line_4: formatAddressLine(address.county),
        postal_code: address.postcode,
        country_code: 'GBR',
      } as CardPaymentRedirectRequestWithoutConfigParameters;
    }
    return undefined;
  }
);

export const getCardPaymentSetupRequest = createSelector<
  CheckoutBasketPartialState &
    PersonalDetailsPartialState &
    UserProfilePartialState &
    AuthPartialState,
  [
    boolean,
    CardPaymentRedirectRequestWithoutConfigParameters | undefined,
    CardPaymentRedirectRequestWithoutConfigParameters | undefined
  ],
  CardPaymentRedirectRequestWithoutConfigParameters | undefined
>(
  getIsLoggedIn,
  getLoggedInUserCardPaymentSetupRequest,
  getGuestUserCardPaymentSetupRequest,
  (
    getIsLoggedIn,
    loggedInUserCardPaymentSetupRequest,
    guestUserCardPaymentSetupRequest
  ) => {
    return getIsLoggedIn
      ? loggedInUserCardPaymentSetupRequest
      : guestUserCardPaymentSetupRequest;
  }
);

function formatAddressLine(addressLine: string | undefined) {
  return addressLine || '';
}
