import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/** @dynamic */
@Injectable({
  providedIn: 'root',
})
export class ScrollToFirstInvalidControlService {
  constructor(@Inject(DOCUMENT) readonly document: Document) {}

  scrollToFirstInvalidControl(): void {
    const firstElementWithError = this.document.querySelector(
      'form .ng-invalid'
    ) as HTMLElement;
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
