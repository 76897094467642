import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileSelectors } from '@common/data-access-user-profile';
import { BuildConfigService } from '@common/util-foundation';
import { PaymentType } from '@common/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthPartialState, getIsLoggedIn } from '@shared/data-access-auth';
import { tap, withLatestFrom } from 'rxjs/operators';
import * as fromSelectPaymentActions from './select-payment.actions';
import { SelectPaymentPartialState } from './select-payment.reducer';
import * as fromSelectPaymentSelectors from './select-payment.selectors';

@Injectable()
export class SelectPaymentEffects {
  proceedToPaymentPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromSelectPaymentActions.proceedToPayment),
        withLatestFrom(
          this.store.pipe(
            select(fromSelectPaymentSelectors.getSelectedPaymentType)
          ),
          this.store.pipe(select(getIsLoggedIn)),
          this.store.pipe(
            select(UserProfileSelectors.getDirectDebitDetailsValid)
          )
        ),
        tap(
          ([
            ,
            selectedPaymentType,
            isLoggedOnUser,
            directDebitDetailsValid,
          ]) => {
            if (selectedPaymentType === PaymentType.Card) {
              return this.router.navigateByUrl(
                this.buildConfig.config.checkoutCardPaymentPage
              );
            }
            if (isLoggedOnUser && directDebitDetailsValid) {
              return this.router.navigateByUrl(
                this.buildConfig.config.checkoutLoggedInUserPage
              );
            }

            return this.router.navigateByUrl(
              this.buildConfig.config.checkoutDirectDebitPage
            );
          }
        )
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private buildConfig: BuildConfigService,
    private actions$: Actions,
    private store: Store<SelectPaymentPartialState & AuthPartialState>
  ) {}
}
