import { mapDiscountFields } from '@common/data-access-shared';
import {
  Basket,
  BasketItem,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  calculateNumberSubsequentPayments,
  isDiscountPaymentProfile,
  PaymentType,
} from '@common/util-models';

export function addNumberSubsequentPaymentsToBasket(basket: Basket): Basket {
  return {
    ...basket,
    items: basket.items.map((item: BasketItem) => ({
      ...item,
      data: {
        ...item.data,
        quotes: item.data.quotes.map((quote: BasketItemQuoteDetails) => ({
          ...quote,
          paymentOptions: quote.paymentOptions.map(
            (paymentOption: BasketItemPaymentOption) => ({
              ...paymentOption,
              numSubsequentPayments:
                calculateNumberSubsequentPayments(paymentOption),
            })
          ),
        })),
      },
    })),
  };
}

export function addDiscountToBasket(basket: Basket): Basket {
  return {
    ...basket,
    items: basket.items.map((item: BasketItem) => ({
      ...item,
      data: {
        ...item.data,
        quotes: item.data.quotes.map((quote: BasketItemQuoteDetails) => ({
          ...quote,
          paymentOptions: quote.paymentOptions.map(
            (paymentOption: BasketItemPaymentOption) => {
              if (
                paymentOption.paymentType === PaymentType.DirectDebit &&
                isDiscountPaymentProfile(paymentOption)
              ) {
                return {
                  ...paymentOption,
                  ...mapDiscountFields(paymentOption),
                };
              }
              return paymentOption;
            }
          ),
        })),
      },
    })),
  };
}
