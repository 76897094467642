// Types from dmorosinotto/TestTypedForms.ts : https://gist.github.com/dmorosinotto/76a9272b5c45af1f78a61e7894df5777

import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable } from 'rxjs';

export type FormStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

export interface AbstractControlTyped<T> extends AbstractControl {
  // BASE PROPS AND METHODS COMMON TO ALL FormControl/FormGroup/FormArray
  readonly value: T;
  valueChanges: Observable<T>;
  readonly status: FormStatus;
  statusChanges: Observable<FormStatus>;
  get<V = unknown>(
    path: Array<string | number> | string
  ): AbstractControlTyped<V> | null;
  setValue<V>(
    value: V extends T ? V : never,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ): void;
  patchValue<V>(
    value: V extends Partial<T> ? V : never,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ): void;
  reset<V>(
    value?: V extends Partial<T> ? V : never,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ): void;
}

export type FormControlTyped<T> = UntypedFormControl & AbstractControlTyped<T>;

export type FormGroupTyped<T> = UntypedFormGroup &
  AbstractControlTyped<T> & {
    // PROPS AND METHODS SPECIFIC OF FormGroup
    controls: { [P in keyof T]: AbstractControlTyped<T[P]> };
    registerControl<P extends keyof T>(
      name: P,
      control: AbstractControlTyped<T[P]>
    ): AbstractControlTyped<T[P]>;
    registerControl<V = unknown>(
      name: string,
      control: AbstractControlTyped<V>
    ): AbstractControlTyped<V>;
    addControl<P extends keyof T>(
      name: P,
      control: AbstractControlTyped<T[P]>
    ): void;
    addControl<V = unknown>(
      name: string,
      control: AbstractControlTyped<V>
    ): void;
    removeControl(name: keyof T): void;
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    removeControl(name: string): void;
    setControl<P extends keyof T>(
      name: P,
      control: AbstractControlTyped<T[P]>
    ): void;
    setControl<V = unknown>(
      name: string,
      control: AbstractControlTyped<V>
    ): void;
    contains(name: keyof T): boolean;
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    contains(name: string): boolean;
    get<P extends keyof T>(path: P): AbstractControlTyped<T[P]>;
    getRawValue(): T & { [disabledProp in string | number]: unknown };
  };

export type FormArrayTyped<T> = UntypedFormArray &
  AbstractControlTyped<T> & {
    // PROPS AND METHODS SPECIFIC OF FormGroup
    controls: AbstractControlTyped<T>[];
    at(index: number): AbstractControlTyped<T>;
    push<V = T>(ctrl: AbstractControlTyped<V>): void;
    insert<V = T>(index: number, control: AbstractControlTyped<V>): void;
    setControl<V = T>(index: number, control: AbstractControlTyped<V>): void;
    getRawValue(): T[];
  };
