import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  AddressFormField,
  PersonalAddress,
  PersonalDetailsFormField,
  PersonalDetailsState,
} from '@common/util-models';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  AddressDef,
  ControlType,
  CustomValidatorService,
  FieldDef,
  SelectDef,
  TextInputDef,
} from '@domgen/dgx-fe-dynamic-form-builder';

const TITLE_OPTIONS = ['Mr', 'Mrs', 'Ms', 'Miss', 'Mx'];

@Injectable()
export class PersonalDetailsFormConfigService {
  constructor(private customValidatorService: CustomValidatorService) {}

  public getFormbuilderConfig(
    cmsFormData: CmsFormField[],
    prefilledForm?: PersonalDetailsState | undefined
  ): FieldDef[] {
    return [
      this.getInputDefByFieldName(
        cmsFormData,
        PersonalDetailsFormField.Title,
        prefilledForm?.title
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        PersonalDetailsFormField.FirstName,
        prefilledForm?.firstName
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        PersonalDetailsFormField.LastName,
        prefilledForm?.lastName
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        PersonalDetailsFormField.Email,
        prefilledForm?.email
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        PersonalDetailsFormField.MobileNumber,
        prefilledForm?.mobileNumber
      ),
      this.getAddressDef(cmsFormData, prefilledForm?.address),
    ] as FieldDef[];
  }

  private getAddressDef(
    cmsFormData: CmsFormField[],
    initialValue: PersonalAddress | undefined | null
  ): AddressDef {
    const addressFormField = CmsHelpers.getFormField<AddressFormField>(
      cmsFormData,
      PersonalDetailsFormField.Address
    );

    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      addressFormField,
      PersonalDetailsFormField.Address
    );

    return {
      ...defaultFieldDef,
      controlType: ControlType.ADDRESS,
      validators: [Validators.required],
      initialValue,
      addressLine1Label: {
        text: addressFormField.addressLine1Label
          ? addressFormField.addressLine1Label
          : 'Home address line 1',
      },
      addressLine1Placeholder: addressFormField.addressLine1Placeholder
        ? addressFormField.addressLine1Placeholder
        : 'Enter address line 1',
      addressLine2Label: {
        text: addressFormField.addressLine2Label
          ? addressFormField.addressLine2Label
          : 'Home address line 2',
      },
      addressLine2Placeholder: addressFormField.addressLine2Placeholder
        ? addressFormField.addressLine2Placeholder
        : 'Enter address line 2',
      cityLabel: {
        text: addressFormField.cityLabel
          ? addressFormField.cityLabel
          : 'Town/City',
      },
      cityPlaceholder: addressFormField.cityPlaceholder
        ? addressFormField.cityPlaceholder
        : 'Enter Town/City',
      countyLabel: {
        text: addressFormField.countyLabel
          ? addressFormField.countyLabel
          : 'County',
      },
      countyPlaceholder: addressFormField.countyPlaceholder
        ? addressFormField.countyPlaceholder
        : 'Enter County',
      postcodeLabel: {
        text: addressFormField.postcodeLabel
          ? addressFormField.postcodeLabel
          : 'Postcode',
      },
      postcodePlaceholder: addressFormField.postcodePlaceholder
        ? addressFormField.postcodePlaceholder
        : 'Enter postcode',
      sanitise: 'block',
    } as AddressDef;
  }

  private getInputDefByFieldName(
    cmsFormData: CmsFormField[],
    formField: PersonalDetailsFormField,
    initialValue?: string
  ): FieldDef {
    let fieldDef: FieldDef;
    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      formField
    );

    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      formField
    );

    switch (formField) {
      case PersonalDetailsFormField.Title:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.SELECT,
          validators: [Validators.required],
          initialValue,
          placeholder: cmsFormField.placeholder
            ? cmsFormField.placeholder
            : 'Select',
          options: TITLE_OPTIONS,
          sanitise: 'block',
        } as SelectDef;
        break;

      case PersonalDetailsFormField.FirstName:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          initialValue,
          validators: [
            Validators.required,
            this.customValidatorService.nameValidator,
            Validators.minLength(1),
            Validators.maxLength(15),
          ],
          type: 'text',
          sanitise: 'block',
        } as TextInputDef;
        break;

      case PersonalDetailsFormField.LastName:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          initialValue,
          validators: [
            Validators.required,
            this.customValidatorService.nameValidator,
            Validators.minLength(1),
            Validators.maxLength(20),
          ],
          type: 'text',
          sanitise: 'block',
        } as TextInputDef;
        break;

      case PersonalDetailsFormField.Email:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          initialValue,
          validators: [
            Validators.required,
            this.customValidatorService.emailValidator,
          ],
          sanitise: 'block',
        } as TextInputDef;
        break;

      case PersonalDetailsFormField.MobileNumber:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          initialValue,
          validators: [
            Validators.required,
            Validators.minLength(7),
            Validators.maxLength(11),
            this.customValidatorService.mobileNumberValidator,
          ],
          sanitise: 'block',
        } as TextInputDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }
}
