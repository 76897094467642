import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  NavigationLinks,
  NavigationLink,
} from '@domgen/dgx-fe-business-models';

const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0ms 200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('0ms 200ms', style({ opacity: 0 }))]),
]);
@Component({
  selector: 'dgx-ui-wl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class HeaderComponent {
  @Input() navigationLinks!: NavigationLinks;
  @Input() extraNavigationLinks: NavigationLink[] = [];
  @Input() isMyAccountLayout = false;
  @Input() displayHamburger = true;
  @Input() isLoggedIn = false;
  /**
   * Receives a link to a client's logo, if required and it will render the powered by logo
   */
  @Input() clientLogoPath: string | undefined;

  /**
   * Emits a click event back to the parent component
   */
  @Output() clicked: EventEmitter<{
    event: MouseEvent;
    link: string;
  }> = new EventEmitter();

  @Output() logout = new EventEmitter();

  /**
   * Emits a toggled event back to the parent component
   */
  @Output() toggled: EventEmitter<string> = new EventEmitter();
  /**
   * Tracks menu open state
   */
  isOpenMainNav = false;
  isOpenLoggedIn = false;

  /**
   * Toggle mobile menu
   */
  onToggleMenu() {
    this.isOpenMainNav = !this.isOpenMainNav;
    this.toggled.emit(this.isOpenMainNav ? 'open' : 'closed');
  }
  /**
   * Toggle mobile menu
   */
  onToggleLoggedInMenu() {
    this.isOpenLoggedIn = !this.isOpenLoggedIn;
    this.toggled.emit(this.isOpenLoggedIn ? 'open' : 'closed');
  }
  /**
   * Listens and fires emit `clicked` when clicked
   */
  onClick(event: MouseEvent, link: string | undefined) {
    this.clicked.emit({
      event,
      link: link || 'Unknown',
    });
    this.isOpenMainNav = false;
    this.isOpenLoggedIn = false;
  }

  logoutClick() {
    this.logout.emit(null);
  }
}
