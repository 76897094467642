import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AoFooterComponent } from './ao-footer/ao-footer.component';

@NgModule({
  declarations: [AoFooterComponent],
  exports: [AoFooterComponent],
  imports: [CommonModule, RouterModule],
})
export class SharedAoComponentsModule {}
