import { ActionReducerMap } from '@ngrx/store';
import {
  appliancesReducer,
  AppliancesState,
  APPLIANCES_KEY,
} from './appliances/appliances.reducer';
import {
  brandsReducer,
  BrandsState,
  BRANDS_KEY,
} from './brands/brands.reducer';
import {
  applianceFormDataReducer,
  ApplianceFormDataState,
  APPLIANCE_FORM_DATA,
} from './appliance-form-data/appliance-form-data.reducer';

export const APPLIANCE_DETAILS_FEATURE_KEY = 'applianceDetails';

export interface ApplianceDetailsPartialState {
  [APPLIANCE_DETAILS_FEATURE_KEY]: ApplianceDetailsState;
}

export interface ApplianceDetailsState {
  [APPLIANCES_KEY]: AppliancesState;
  [BRANDS_KEY]: BrandsState;
  [APPLIANCE_FORM_DATA]: ApplianceFormDataState;
}

export const applianceDetailsReducer: ActionReducerMap<ApplianceDetailsState> =
  {
    [APPLIANCES_KEY]: appliancesReducer,
    [BRANDS_KEY]: brandsReducer,
    [APPLIANCE_FORM_DATA]: applianceFormDataReducer,
  };
