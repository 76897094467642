import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CheckoutBasketActions,
  CheckoutBasketPartialState,
  CheckoutBasketSelectors,
} from '@common/data-access-checkout-basket';
import {
  BuildConfigService,
  ErrorService,
  LoaderService,
} from '@common/util-foundation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthFacade } from '@shared/data-access-auth';
import { asyncScheduler, forkJoin, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  observeOn,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserProfileApiService } from '../services/user-profile-api.service';
import * as UserProfileActions from './user-profile.actions';
import { UserProfilePartialState } from './user-profile.reducer';
import * as UserProfileSelectors from './user-profile.selectors';

@Injectable()
export class UserProfileEffects {
  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserProfileActions.loginSuccess,
        CheckoutBasketActions.checkoutAlreadyLoggedIn
      ),
      map(() => UserProfileActions.loadPersonalAndDirectDebitDetails())
    )
  );

  checkoutAsGuest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.checkoutAsGuest),
        tap(() =>
          this.router.navigateByUrl(this.config.checkoutPersonalDetailsPage)
        )
      ),
    { dispatch: false }
  );

  loadPersonalAndDirectDebitDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.loadPersonalAndDirectDebitDetails),
      exhaustMap(() => {
        return forkJoin({
          personalDetails: this.userProfileService.getPersonalDetails(),
          directDebitDetails: this.userProfileService.getDirectDebitDetails(),
        }).pipe(
          withLatestFrom(this.authFacade.user$),
          map(
            ([{ personalDetails: person, directDebitDetails }, selectUser]) => {
              this.store.dispatch(
                UserProfileActions.tagPersonalDetailsProfile({ person })
              );
              return UserProfileActions.loadPersonalAndDirectDebitDetailsSuccess(
                {
                  person,
                  directDebitDetails,
                  selectUser,
                }
              );
            }
          ),
          catchError((error: Error) =>
            of(
              UserProfileActions.loadPersonalAndDirectDebitDetailsFailure({
                error,
              })
            )
          )
        );
      })
    )
  );

  loadPersonalAndDirectDebitDetailsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.loadPersonalAndDirectDebitDetailsSuccess),
        withLatestFrom(
          this.store.pipe(
            select(CheckoutBasketSelectors.isDirectDebitOnlyPaymentOption)
          ),
          this.store.select(UserProfileSelectors.getDirectDebitDetailsValid)
        ),
        tap(([, isDirectDebitOnlyPaymentOption, directDebitDetailsValid]) => {
          if (isDirectDebitOnlyPaymentOption && directDebitDetailsValid) {
            this.router.navigateByUrl(this.config.checkoutLoggedInUserPage);
          } else if (isDirectDebitOnlyPaymentOption) {
            this.router.navigateByUrl(this.config.checkoutDirectDebitPage);
          } else {
            this.router.navigateByUrl(this.config.selectPaymentPage);
          }
        })
      ),
    { dispatch: false }
  );

  loadPersonalAndDirectDebitDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.loadPersonalAndDirectDebitDetailsFailure),
        tap(() => this.handleError())
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.loadPersonalAndDirectDebitDetails),
        observeOn(asyncScheduler),
        tap(() => this.loaderService.showLoader())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userProfileService: UserProfileApiService,
    private store: Store<CheckoutBasketPartialState & UserProfilePartialState>,
    private buildConfig: BuildConfigService,
    private router: Router,
    private loaderService: LoaderService,
    private errorService: ErrorService,
    private authFacade: AuthFacade
  ) {}

  get config() {
    return this.buildConfig.config;
  }

  handleError() {
    this.errorService.handleError();
  }
}
