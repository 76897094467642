import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  CmsFormField,
  PlanDocumentsFormField,
} from '@domgen/dgx-fe-business-models';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PlanDocumentsFormConfigService } from './plan-documents-form-config.service';
export interface PlanDocumentsFormState {
  cmsFormData: CmsFormField[];
  form: UntypedFormGroup;
  formBuilderConfig: FieldDef[];
  prefilledForm: boolean | null;
  validate: boolean;
}

export interface ViewModel {
  fieldDef: FieldDef[];
  formGroup: UntypedFormGroup;
  validate: boolean;
}

@Injectable()
export class PlanDocumentsFormStateService extends ComponentStore<PlanDocumentsFormState> {
  // Selectors
  readonly formBuilderConfig$: Observable<FieldDef[]> = this.select(
    (state: PlanDocumentsFormState) => state.formBuilderConfig
  );

  readonly formGroup$: Observable<UntypedFormGroup> = this.select(
    (state: PlanDocumentsFormState) => state.form
  );

  readonly validate$: Observable<boolean> = this.select(
    (state: PlanDocumentsFormState) => state.validate
  );

  readonly cmsFormData$: Observable<CmsFormField[]> = this.select(
    (state: PlanDocumentsFormState) => state.cmsFormData
  );

  readonly prefilledForm$: Observable<boolean | null> = this.select(
    (state: PlanDocumentsFormState) => state.prefilledForm
  );

  readonly valueChanges$: Observable<Record<PlanDocumentsFormField, boolean>> =
    this.formGroup$.pipe(
      switchMap((formGroup) =>
        this.dynamicFormBuilder.selectValidFormValue$<
          Record<PlanDocumentsFormField, boolean>
        >(formGroup)
      )
    );

  readonly setCmsFormData = this.updater(
    (state: PlanDocumentsFormState, value: CmsFormField[]) => ({
      ...state,
      cmsFormData: value,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (state: PlanDocumentsFormState, value: boolean | null) => ({
      ...state,
      prefilledForm: value,
    })
  );

  private fieldDefByTermsUpdater$ = this.cmsFormData$.pipe(
    withLatestFrom(this.prefilledForm$),
    map(([cmsFormData, prefilledForm]) => {
      if (!cmsFormData.length) {
        return [];
      }
      return this.formConfig.getFormbuilderConfig(cmsFormData, prefilledForm);
    })
  );

  readonly validate = this.updater((state: PlanDocumentsFormState) => ({
    ...state,
    validate: true,
  }));

  private readonly cmsFieldDefByTermsUpdater = this.updater(
    (state: PlanDocumentsFormState, value: FieldDef[]) => ({
      ...state,
      formBuilderConfig: value,
    })
  )(this.fieldDefByTermsUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(
        map((conf) => this.dynamicFormBuilder.generateFormGroup(conf))
      )
    )
  );

  private readonly formGroupUpdater = this.updater(
    (state: PlanDocumentsFormState, form: UntypedFormGroup) => ({
      ...state,
      form,
    })
  )(this.formGroupUpdater$);

  readonly vm$: Observable<ViewModel> = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.validate$,
    (fieldDef, formGroup, validate): ViewModel => ({
      fieldDef,
      formGroup,
      validate,
    })
  );

  constructor(
    private formConfig: PlanDocumentsFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      cmsFormData: [],
      form: new UntypedFormGroup({}),
      formBuilderConfig: [],
      prefilledForm: null,
      validate: false,
    });
  }

  patchFormValue(
    formValue: Partial<Record<PlanDocumentsFormField, boolean>>
  ): void {
    this.formGroup$.pipe(first()).subscribe((formGroup: UntypedFormGroup) => {
      formGroup.patchValue(formValue);
    });
  }
}
