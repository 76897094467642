import { CheckoutBasketActions } from '@common/data-access-checkout-basket';
import { PersonalDetailsSharedActions } from '@common/data-access-shared';
import {
  DirectDebit,
  PersonalDetailsFormSubmitType,
  PersonalDetailsState,
} from '@common/util-models';
import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import { Action, createReducer, on } from '@ngrx/store';
import * as PersonalDetailsActions from './personal-details.actions';

export const PERSONAL_DETAILS_FEATURE_KEY = 'guestPersonalDetails';

export interface PersonalDetailsPartialState {
  readonly [PERSONAL_DETAILS_FEATURE_KEY]: PersonalDetailsState;
}

export const initialState: PersonalDetailsState = {
  title: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  mobileNumber: undefined,
  address: undefined,
  marketingPreferences: undefined,
  directDebitDetails: undefined,
  paperlessPlanDocuments: undefined,
};

export const personalDetailsReducer = createReducer(
  initialState,
  on(
    PersonalDetailsActions.updatePersonalDetails,
    PersonalDetailsSharedActions.setPersonalDetails,
    (
      state,
      action: {
        payload: Partial<Omit<PersonalDetailsFormSubmitType, 'payment'>>;
      }
    ): PersonalDetailsState => ({
      ...state,
      title: action.payload.title,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      mobileNumber: action.payload.mobileNumber,
      address: action.payload.address,
    })
  ),
  on(
    PersonalDetailsActions.updateDirectDebit,
    (state, action: { payload: DirectDebit }): PersonalDetailsState => ({
      ...state,
      directDebitDetails: { ...action.payload },
    })
  ),
  on(
    PersonalDetailsActions.updateMarketingPreference,
    (
      state,
      action: { payload: MarketingPreferences }
    ): PersonalDetailsState => ({
      ...state,
      marketingPreferences: { ...action.payload },
    })
  ),
  on(
    PersonalDetailsActions.exitingPersonalDetails,
    (state): PersonalDetailsState => ({
      ...state,
      paperlessPlanDocuments:
        state.paperlessPlanDocuments === undefined
          ? true
          : state.paperlessPlanDocuments,
    })
  ),
  on(
    PersonalDetailsActions.updatePlanDocumentPreference,
    (state, { paperless: paperlessPlanDocuments }): PersonalDetailsState => ({
      ...state,
      paperlessPlanDocuments,
    })
  ),
  on(
    CheckoutBasketActions.setBasket,
    (state): PersonalDetailsState => ({
      ...state,
      paperlessPlanDocuments: undefined,
    })
  )
);

export function reducer(
  state: PersonalDetailsState | undefined,
  action: Action
) {
  return personalDetailsReducer(state, action);
}
