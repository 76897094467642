import { NavigationLink } from '@domgen/dgx-fe-business-models';

export interface ErrorPageData {
  additionalSupport?: string;
  contactCustomerService?: string;
  contactHelpNumber?: string;
  header?: string;
  link?: NavigationLink;
  support?: string;
}
