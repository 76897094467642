import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CheckboxToggleDef,
  ControlType,
} from '@domgen/dgx-fe-dynamic-form-builder';

@Component({
  selector: 'wl-direct-debit-confirmation-checkbox',
  templateUrl: './direct-debit-confirmation-checkbox.component.html',
  styleUrls: ['./direct-debit-confirmation-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectDebitConfirmationCheckboxComponent implements OnChanges {
  field: CheckboxToggleDef = {
    controlType: ControlType.CHECKBOX,
    controlName: 'isConfirmed',
    initialValue: false,
    validators: [Validators.requiredTrue],
    validationMessages: [
      {
        type: 'required',
        message:
          'Please check the box to confirm that your information is correct',
      },
    ],
    label: {
      text: "I confirm that these bank details are correct, and that I'm the only person required to authorise debits from this account",
    },
  };
  @Input() requiredMessage: string | undefined;
  @Input() checkboxText: string | undefined;
  @Input() validateField = false;
  @Output() valid: EventEmitter<boolean> = new EventEmitter();
  public formGroup: UntypedFormGroup;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validateField?.currentValue === true) {
      this.formGroup.markAllAsTouched();
    }
    if (
      changes?.requiredMessage?.currentValue &&
      this.field.validationMessages
    ) {
      this.field.validationMessages[0].message =
        changes?.requiredMessage?.currentValue;
    }
    if (changes?.checkboxText?.currentValue && this.field.label) {
      this.field.label.text = changes?.checkboxText?.currentValue;
    }
  }

  constructor() {
    this.formGroup = new UntypedFormGroup({
      isConfirmed: new UntypedFormControl(false, Validators.requiredTrue),
    });
    this.formGroup.statusChanges.subscribe((result) =>
      this.valid.emit(result === 'VALID')
    );
  }
}
