import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import {
  Appliance,
  ApplianceResponse,
  BrandResponse,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppliancesMapperService } from './appliances-mapper.service';

@Injectable()
export class AppliancesApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService,
    private appliancesMapper: AppliancesMapperService
  ) {}

  getBrandsForAppliance(applianceCode: string) {
    const itemsUrl = `${this.config.catalogueApi}/appliances/${applianceCode}/brands`;
    return this.httpClient
      .get<{ result: BrandResponse[] }>(itemsUrl, {
        headers: {
          ...this.getHeaders(),
        },
      })
      .pipe(
        map((brandsResponse) =>
          brandsResponse.result.map((brandResponse) =>
            this.appliancesMapper.mapToBrand(brandResponse)
          )
        )
      );
  }

  getAppliances(): Observable<Appliance[]> {
    const itemsUrl = `${this.config.catalogueApi}/appliances`;
    return this.httpClient
      .get<{ result: ApplianceResponse[] }>(itemsUrl, {
        observe: 'response',
        headers: {
          ...this.getHeaders(),
        },
      })
      .pipe(
        map(
          (appliancesResponse) =>
            appliancesResponse.body?.result.map((applianceResponse) =>
              this.appliancesMapper.mapToAppliance(applianceResponse)
            ) as Appliance[]
        )
      );
  }

  private getHeaders() {
    return {
      'wl-client': this.buildConfigService.config.wlClient,
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }

  private get config() {
    return this.buildConfigService.config;
  }
}
