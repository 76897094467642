import { CmsAsset } from '@domgen/dgx-fe-business-models';
export interface goToMyAccount {
  title: string;
  summary: string;
}

export interface paymentOption {
  paymentType: string;
  preferredPayment: boolean;
  paymentProfile: string;
  numPayments: number;
  paymentFrequency: number;
  firstPayment: number;
  subsequentPayment: number;
  fee: number;
}

export interface confirmation {
  planNumber: string;
  breakdownStartDate: Date;
  renewalDate: Date;
}

export interface applianceDetails {
  purchaseMonth: number;
  purchaseYear: number;
  purchasePrice: number;
  applianceCode: string;
  applianceName: number;
  applianceIcon: string;
  applianceImage: string;
  brandCode: string;
  brandName: string;
  warranty: number;
}

export interface planDetails {
  paymentDay: number;
  paymentOption: paymentOption;
  confirmation: confirmation;
  applianceDetails: applianceDetails;
}

export interface OrderConfirmationCmsData {
  jcrName: string;
  formName: string;
  goToMyAccount: goToMyAccount;
  ipidAsset: CmsAsset;
  termsAndConditionsAsset: CmsAsset;
}
