import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export function getAllFormControls(
  control?: AbstractControl | null
): AbstractControl[] {
  if (control instanceof UntypedFormGroup) {
    return Object.values(control.controls).reduce(
      (
        descendantControls: AbstractControl[],
        descendantControl: AbstractControl
      ) => {
        return descendantControls.concat(getAllFormControls(descendantControl));
      },
      [control]
    );
  } else if (control instanceof UntypedFormControl) {
    return [control];
  }
  return [];
}

export function getAllDisabledFormControls(
  control?: AbstractControl | null
): AbstractControl[] {
  const controls = getAllFormControls(control);
  const disabledControls = controls.filter(
    (descendantControl: AbstractControl) => descendantControl.disabled
  );
  return disabledControls;
}

export function enableFormControls(
  ...controls: (AbstractControl | null | undefined)[]
): void {
  controls.forEach((control) => {
    if (control instanceof UntypedFormGroup) {
      enableFormControls(...Object.values(control.controls));
    }
    control?.enable();
  });
}

export function disableFormControls(
  ...controls: (AbstractControl | null | undefined)[]
): void {
  controls.forEach((control) => {
    if (control instanceof UntypedFormGroup) {
      disableFormControls(...Object.values(control.controls));
    }
    control?.disable();
  });
}

export function resetForm(form: UntypedFormGroup | undefined | null): void {
  resetFormControls(form, ...Object.keys(form?.controls ?? {}));
}

export function resetFormControls(
  form: UntypedFormGroup | undefined | null,
  ...controlKeys: string[]
): void {
  form?.patchValue(
    controlKeys.reduce(
      (patchValue: { [key: string]: undefined }, controlKey: string) => ({
        ...patchValue,
        [controlKey]: undefined,
      }),
      {}
    )
  );

  controlKeys.forEach((controlKey: string) => {
    const control: AbstractControl | null | undefined = form?.get(controlKey);
    control?.markAsPristine();
    control?.markAsUntouched();
    control?.setErrors(null);
    control?.updateValueAndValidity();
  });
}
