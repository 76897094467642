<ng-container *ngIf="vm$ | async as vm">
  <div class="form-panel" [ngClass]="hideBorder ? '' : 'form-panel--border'">
    <h2 class="form-panel__title">Your details</h2>
    <dgx-dfb-form
      [fieldset]="vm.fieldDef"
      [form]="vm.formGroup"
      [validate]="vm.validate"
      (analytics)="handleAnalytics($event)"
      class="spacing-min-lg"
    >
    </dgx-dfb-form>
  </div>
</ng-container>
