import { Component, Input } from '@angular/core';
import { ServiceReasssurance } from './service-reassurance.interface';

@Component({
  selector: 'sales-service-reassurance',
  templateUrl: './service-reassurance.component.html',
  styleUrls: ['./service-reassurance.component.scss'],
})
export class ServiceReassuranceComponent {
  @Input() iconSet!: Array<ServiceReasssurance>;
}
