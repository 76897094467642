<dgx-ui-wl-footer
  copyrightOwner="Domestic & General Group Limited"
  [legalLinks]="legalLinks"
  [moreLinks]="moreLinks"
  [protectLinks]="protectLinks"
  [usefulLinks]="usefulLinks"
  [socialLinks]="socialLinks"
  (clicked)="handleFooterLinkClicked($event)"
>
  <p>
    Service plans, maintenance and support plans are provided by Domestic &
    General Services Limited. Insurance policies are provided by Domestic &
    General Insurance PLC. Domestic & General Insurance PLC is an insurance
    undertaking, not an intermediary. We're the underwriter of the insurance
    policies and don't provide personal recommendations or advice.
  </p>
  <p>
    Domestic & General Insurance PLC is authorised by the Prudential Regulation
    Authority and regulated by the Financial Conduct Authority and the
    Prudential Regulation Authority (Financial Services Register Number 202111).
    Our address and details of our authorisation can be checked on the
    <a href="https://register.fca.org.uk/s/" class="subcatlink">FCA website</a>.
  </p>
</dgx-ui-wl-footer>
