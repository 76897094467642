export enum CmsPage {
  Appliance = 'dgx-forms',
  Quote = 'dgx-policy-features',
  Checkout = 'dgx-checkout',
  CheckoutPersonalDetails = 'dgx-forms-checkout',
  CheckoutDirectDebit = 'dgx-forms-checkout-direct-debit',
  CheckoutLoggedInUser = 'dgx-forms-checkout-logged-in-user',
  CheckoutSelectPayment = 'dgx-forms-checkout-select-payment',
  CheckoutPaymentOptions = 'dgx-checkout-payment-options',
  FAQs = 'dgx-sales-faqs',
  OrenLandingOffer = 'dgx-landing-offer',
  OrenLandingRenewal = 'dgx-landing-renewal',
  OrderConfirmationDetail = 'dgx-orderConfirmation-detail',
  Reinstate = 'dgx-forms-reinstate',
}

export enum CmsSalesForm {
  PersonalDetailsFormName = 'personalDetailsFormName',
  MarketingPreferenceFormName = 'marketingPreferencesForm',
  DirectDebitFormName = 'directDebitForm',
  DirectDebitConfirmationFormName = 'directDebitConfirmationForm',
  PlanDocumentPreferenceFormName = 'planDocumentPreferenceForm',
  TermsAndConditionsFormName = 'termsAndConditionsForm',
  EmailDetailsFormName = 'emailDetailForm',
}

export type CmsDataUrl = Partial<Record<CmsPage, CmsDataResolverConfig>>;

export interface CmsDataResolverConfig {
  url: string;
  id: CmsPage;
  mapper?: (...args: never[]) => unknown;
}

export interface CmsFAQsResponse {
  results: [
    {
      answer: string;
      question: string;
    }
  ];
}

export type CmsData = unknown;
