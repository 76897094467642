import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { RadioListItemValue } from '@domgen/dgx-fe-components-core';
import { PaymentDiscount, PaymentType } from '@common/util-models';

export interface Payment extends PaymentDiscount {
  directDebitDiscount?: number;
  firstMonthPayment?: number;
  id: number | string;
  numPayments: number;
  numSubsequentPayments?: number;
  perMonthPrice?: number;
  paymentType: string;
  preferredPayment?: boolean;
  title: string;
  totalPrice: number;
}
@Component({
  selector: 'wl-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnChanges {
  @Input() selected = false;
  @Input() selectable = false;
  @Input() payment: Payment | undefined;
  @Input() discountThreshold = 1;
  @Output()
  selectionChange: EventEmitter<RadioListItemValue> = new EventEmitter();
  isCard = false;
  equalFirstAndSubsequentPayments = true;
  discount = 0;

  ngOnChanges() {
    this.isCard = this.payment?.paymentType === PaymentType.Card;
    this.equalFirstAndSubsequentPayments =
      this.payment?.firstMonthPayment === this.payment?.perMonthPrice;

    this.calculateDiscount();
  }

  onClick() {
    if (
      this.selectable &&
      this.selectionChange &&
      this.selectionChange.observers &&
      this.selectionChange.observers.length
    ) {
      this.selectionChange.emit(this.payment && this.payment.id);
    }
  }

  isSelected(value: boolean) {
    if (
      this.selectionChange &&
      this.selectionChange.observers &&
      this.selectionChange.observers.length
    ) {
      if (value) {
        this.selectionChange.emit(this.payment && this.payment.id);
      }
    } else {
      this.selected = value;
    }
  }

  calculateDiscount() {
    if (
      !this.isCard &&
      this.payment?.directDebitDiscount &&
      this.payment.directDebitDiscount >= this.discountThreshold
    ) {
      this.discount = this.payment.directDebitDiscount;
    }
  }
}
