import { Injectable } from '@angular/core';
import { Quote } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as QuotesPageSelectors from '../+state/quotes-page.selectors';
import { QuotesPartialState } from '../+state/quotes.reducer';
import * as QuotesSelectors from '../+state/quotes.selectors';

/**
 * This will only contain selectors that we need to reuse across pages. This will ensure that we do not duplicate selectors across
 * pages going forward in each and every facade. There will never be methods here that dispatch actions to the store. These
 * will be injected across various feature pages/ component services/ state services. So having methods here that dispatch actions
 * will lead to action reuse and use of actions as commands. We want to avoid actions reuse and have unique actions for every specific
 * event that happens across pages.
 *
 * @export
 * @class QuotesCommonSelectorsFacade
 */
@Injectable()
export class QuotesCommonSelectorsFacade {
  quote$: Observable<Quote> = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filterNullUndefined()
  );

  quoteError$: Observable<Error> = this.store.pipe(
    select(QuotesSelectors.getQuotesError),
    filterNullUndefined()
  );

  constructor(private store: Store<QuotesPartialState>) {}
}
