import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SelectQuotePaymentOptionsFacade } from '@common/data-access-quotes';
import { ResetStateFacade } from '@common/data-access-shared';
import { fadeInOut } from '@common/ui-sales-components';
import {
  BuildConfigService,
  LoaderService,
  SessionInactivityService,
} from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import { AlertDialogService } from '@domgen/dgx-fe-components-core';
import { NavigationLinks } from '@domgen/dgx-fe-business-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthStateService } from '@shared/feature-auth';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  confirmationStep,
  paymentDetailsStep,
  yourDetailsStep,
} from './edf-appliances-app.component.config';

@UntilDestroy()
@Component({
  selector: 'edf-appliances-app-root',
  templateUrl: './edf-appliances-app.component.html',
  styleUrls: ['./edf-appliances-app.component.scss'],
  animations: [fadeInOut],
})
export class EdfAppliancesAppComponent implements OnInit {
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLinks = this.config.header;
  loading$!: Observable<boolean>;

  public progressSteps$:
    | Observable<{ label: string; routes: string[] }[]>
    | undefined;
  public currentRoute = '';

  constructor(
    public readonly buildConfigService: BuildConfigService,
    private router: Router,
    private loaderService: LoaderService,
    private selectQuotePaymentOptionsFacade: SelectQuotePaymentOptionsFacade,
    private authStateService: AuthStateService,
    private readonly resetStateFacade: ResetStateFacade,
    private readonly sessionInactivityService: SessionInactivityService,
    private readonly alertDialogService: AlertDialogService
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url) {
          this.currentRoute = e.url;
        }
      });

    this.progressSteps$ = combineLatest([
      this.selectQuotePaymentOptionsFacade.paymentOptions$,
      of(false),
    ]).pipe(map(([, isAuthenticated]) => this.getSteps(isAuthenticated)));
  }

  ngOnInit(): void {
    this.loading$ = this.loaderService.loading$;
    this.authStateService.setUpdateAuthState();
    this.monitorSessionInactivity();
  }

  private getSteps(
    isAuthenticated: boolean
  ): { label: string; routes: string[] }[] {
    return [
      ...(!isAuthenticated ? [yourDetailsStep] : []),
      paymentDetailsStep,
      confirmationStep,
    ];
  }

  private monitorSessionInactivity(): void {
    this.sessionInactivityService
      .monitor()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.alertDialogService.openError({
          ariaLabel: 'session expired',
          disableClose: true,
          data: {
            title: 'Your session has timed out due to inactivity',
            body: 'Click below to restart the journey',
            primaryCta: {
              text: 'Restart journey',
              click: () => this.resetStateFacade.sessionInactivityTimeout(),
            },
          },
        });
      });
  }
}
