<dgx-dfb-address-tabs-wrapper
  *ngIf="vm$ | async as vm"
  [disableSelection]="vm.noAddressItemsFound"
  [selectionMode]="vm.mode"
  [field]="field"
  (selectedMode)="selectedModeOutput($event)"
>
  <div lookup>
    <ng-container>
      <dgx-dfb-list-box
        [field]="vm.listBoxDef!"
        [formControl]="vm.listboxControl"
        (selectedIndex)="onSelectionChange($event)"
        (clearSelection)="onClearSelection()"
        [validate]="validate"
      ></dgx-dfb-list-box>
      <a
        *ngIf="vm.addressForm.invalid || ngControl?.control?.untouched"
        (click)="goToManual()"
        class="link address-list__link"
      >
        Can't find my address in the list
      </a>
    </ng-container>
  </div>

  <div manual>
    <ng-container [formGroup]="vm.addressForm">
      <dgx-dfb-manual-address-entry
        [fieldDef]="vm.fieldDef!"
        [formControlName]="'manualAddress'"
        [validateForm]="validate"
      ></dgx-dfb-manual-address-entry>
    </ng-container>
  </div>
</dgx-dfb-address-tabs-wrapper>
