<div class="pt-6" [formGroup]="formGroup">
  <dgx-dfb-checkbox [field]="field" [formControlName]="field.controlName">
  </dgx-dfb-checkbox>
  <dgx-dfb-error
    *ngIf="displayError$ | async"
    errorMessage="Please check the box to confirm that your information is correct"
  ></dgx-dfb-error>
  <p class="pt-4">
    If you can't confirm these statements, we are unable to accept payment by
    Direct Debit.
  </p>
</div>
