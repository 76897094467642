import {
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  CheckoutCompletedItem,
  CheckoutItemDetails,
  CheckoutRequest,
  CheckoutResponse,
} from '@common/util-models';

export function formatCheckoutCompletedItems(
  request: CheckoutRequest,
  response: CheckoutResponse
): CheckoutCompletedItem[] {
  let completedItems: CheckoutCompletedItem[] =
    removeUnusedCheckoutCompletedItemQuotes(
      request.items,
      response.completedItems
    );

  completedItems = removeUnusedCheckoutCompletedItemPaymentOptions(
    request,
    completedItems
  );

  return completedItems;
}

export function removeUnusedCheckoutCompletedItemPaymentOptions(
  request: CheckoutRequest,
  completedItems: CheckoutCompletedItem[]
): CheckoutCompletedItem[] {
  return completedItems.map(
    (completedItem: CheckoutCompletedItem) =>
      ({
        ...completedItem,
        item: {
          ...completedItem.item,
          data: {
            ...completedItem.item.data,
            quotes: completedItem.item.data.quotes.map(
              (quote: BasketItemQuoteDetails) => ({
                ...quote,
                paymentOptions: quote.paymentOptions.filter(
                  (paymentOption: BasketItemPaymentOption) =>
                    paymentOption.paymentType === request.payment.paymentType
                ),
              })
            ),
          },
        },
      } as CheckoutCompletedItem)
  );
}

export function removeUnusedCheckoutCompletedItemQuotes(
  items: CheckoutItemDetails[],
  completedItems: CheckoutCompletedItem[]
): CheckoutCompletedItem[] {
  const quoteIds: string[] = items.map(
    (item: CheckoutItemDetails) => item.quoteId
  );

  return completedItems.map(
    (completedItem: CheckoutCompletedItem) =>
      ({
        ...completedItem,
        item: {
          ...completedItem.item,
          data: {
            ...completedItem.item.data,
            quotes: completedItem.item.data.quotes.filter(
              (quote: BasketItemQuoteDetails) =>
                quoteIds.includes(quote.quoteId)
            ),
          },
        },
      } as CheckoutCompletedItem)
  );
}
