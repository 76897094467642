import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectDebitUiModule } from '@domgen/dgx-fe-business-components';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { SavedDirectDebitDetailsComponent } from './saved-direct-debit-details/saved-direct-debit-details.component';
import { CheckoutUiModule } from './checkout-ui.module';
import { DgxQmModule } from '@domgen/dgx-fe-qm';

@NgModule({
  imports: [
    CommonModule,
    DirectDebitUiModule,
    CheckoutUiModule,
    SharedUiDynamicFormBuilderModule,
    DgxQmModule,
  ],
  declarations: [SavedDirectDebitDetailsComponent],
  exports: [SavedDirectDebitDetailsComponent],
})
export class LoggedInUserUiModule {}
