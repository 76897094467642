import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CardComponentsModule,
  GraphicComponentModule,
} from '@domgen/dgx-fe-components-core';
import { TrustReassuranceComponent } from './trust-reassurance/trust-reassurance.component';

@NgModule({
  imports: [CommonModule, GraphicComponentModule, CardComponentsModule],
  declarations: [TrustReassuranceComponent],
  exports: [TrustReassuranceComponent],
})
export class TrustReassuranceComponentModule {}
