import { PaymentType } from './basket-item.types';

export interface QuoteTaggingData {
  quoteId: string | null;
  customerId: string;
  digitalId: string;
  customerType: string;
  price: number | null;
  total: number | null;
  orderDetails: string | null;
  companyType: string | null;
  name: string | null;
  coverName: string;
  applianceName: string | null;
  brandName: string | null;
  schemeCode: string | null;
  paymentType?: PaymentType | undefined;
  paymentTerm?: string;
}

export interface FeeAndPaymentData {
  fee: number | null;
  paymentType: PaymentType | undefined;
  paymentTerm?: string;
  total: number | null;
}
