<div errorPageGaTagging class="error container mt-12">
  <h1 class="error__header mb-4">Sorry, something’s not right</h1>
  <p class="mb-6">
    It looks like there’s been a technical issue but we’re working to fix it
  </p>
  <strong class="error__support">
    Try calling our friendly team to receive your quote on:
  </strong>
  <p class="error__help-number mb-8">
    <a class="link" href="tel:08005978600">0800 5978600</a>
  </p>
  <strong class="error__support"> We’re available: </strong>
  <p>8am to 8pm Monday – Friday</p>
  <p>9am to 5pm Saturday – Sunday</p>
</div>
