import { CmsModuleConfig } from '@domgen/dgx-fe-content-management';
import {
  HeadlineComponent,
  HeroImageComponent,
  ParagraphComponent,
  ThumbnailBulletElementComponent,
} from '@common/ui-cms-templates';

export const COMPONENT_MAPPING: CmsModuleConfig['componentMapping'] = {
  'dgx-components:components/headline': HeadlineComponent,
  'dgx-components:components/heroImage': HeroImageComponent,
  'dgx-components:components/paragraph': ParagraphComponent,
  'dgx-components:components/thumbnailBulletElement':
    ThumbnailBulletElementComponent,
};
