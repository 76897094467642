import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SelectPaymentState,
  SELECTPAYMENT_FEATURE_KEY,
} from './select-payment.reducer';

export const getSelectPaymentState = createFeatureSelector<SelectPaymentState>(
  SELECTPAYMENT_FEATURE_KEY
);

export const getSelectedPaymentType = createSelector(
  getSelectPaymentState,
  (state: SelectPaymentState) => state?.selectedPaymentType
);
