import { filter, first } from 'rxjs/operators';
import { FeatureConfigFacade } from '../../+state/feature-config.facade';

export const featureConfigInitializer =
  (
    featureConfigFacade: FeatureConfigFacade
  ): (() => Promise<boolean | undefined>) =>
  () => {
    return featureConfigFacade.remoteStateLoaded$
      .pipe(
        filter((remoteStateLoaded: boolean) => remoteStateLoaded),
        first()
      )
      .toPromise();
  };
