<div class="footer-social">
  <ul *ngIf="socialLinks" class="flex justify--center">
    <li *ngFor="let link of socialLinks" class="mx-4">
      <a
        [href]="link.href"
        class="icon icon--xl icon-{{ link.icon }} footer-social__link flex"
        [attr.aria-label]="link.label"
        target="_blank"
        rel="noopener noreferrer"
      ></a>
    </li>
  </ul>
</div>
