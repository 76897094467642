import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TrustReassurance } from './trust-reassurance.interface';

@Component({
  selector: 'sales-trust-reassurance',
  templateUrl: './trust-reassurance.component.html',
  styleUrls: ['./trust-reassurance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TrustReassuranceComponent {
  @Input() features!: Array<TrustReassurance>;
  @Input() minWidth = '228px';
  @Input() minHeight = '228px';
}
