import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgxQmModule } from '@domgen/dgx-fe-qm';
import { WorldPayCardPaymentComponent } from './world-pay-card-payment.component';

@NgModule({
  declarations: [WorldPayCardPaymentComponent],
  exports: [WorldPayCardPaymentComponent],
  imports: [CommonModule, DgxQmModule],
})
export class WorldPayCardPaymentModule {}
