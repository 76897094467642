import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { TextPasswordDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

@Component({
  selector: 'dgx-dfb-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class PasswordComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor
{
  @Input() field!: TextPasswordDef;
  @Input() validate = false;

  valueSubject = new BehaviorSubject<string>('');
  value$ = this.valueSubject.asObservable();

  fieldTextType = false;
  blurred = false;
  tooltipName = 'tooltip';
  precision = '2';

  passwordError = false;
  passwordValue = '';
  passwordStrengthChecker = {
    showLength: true,
    showUppercase: true,
    showLowercase: true,
    showNumber: true,
  };

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.valueSubject.next(this.field?.initialValue || '');
  }

  toggleFieldPasswordType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onChange(event: { target: { value: string } }): void {
    if (this.blurred) {
      const val = event.target.value || '';
      this.emitValue(val);
      super.writeValue(val, false);
    }
    this.passwordError = this.validate;
    this.passwordValue = event.target.value || '';
  }

  onBlur(event: { target: { value: string } }): void {
    if (!this.blurred) {
      this.blurred = true;
      this.onChange(event);
    }
    this.emitAnalyticsData(event.target.value);
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string) {
    this.emitValue(val);
  }

  emitValue(val: string) {
    this.valueSubject.next(val);
  }
}
