<div class="covers-exclusions">
  <div class="stack-view">
    <h3>{{ includedTitle }}</h3>
    <ng-container *ngTemplateOutlet="whatIncluded"></ng-container>
    <h3 style="margin-top: 30px">{{ excludedTitle }}</h3>
    <ng-container *ngTemplateOutlet="whatExcluded"></ng-container>
  </div>

  <div *ngIf="deviceExcluded" class="stack-view mt-6">
    <p class="text--center">{{ deviceExcluded }}</p>
  </div>
</div>

<ng-template #whatIncluded>
  <ul class="list" [ngClass]="{ 'mb-4 py-4': displayAs === 'stacked' }">
    <li
      class="list__item list__item"
      [ngClass]="customTickIcon ? '' : 'icon--md icon-large-tick'"
      *ngFor="let includedItem of included"
    >
      <div *ngIf="customTickIcon" class="icon--md icon-container mr-6">
        <img alt="tick icon" [src]="customTickIcon" />
      </div>
      {{ includedItem }}
    </li>
  </ul>
</ng-template>

<ng-template #whatExcluded>
  <ul class="list" [ngClass]="{ 'pt-4 whatExcluded': displayAs === 'stacked' }">
    <li
      class="list__item list__item"
      [ngClass]="customCrossIcon ? '' : 'icon-large-cross'"
      *ngFor="let excludedItem of excluded"
    >
      <div *ngIf="customCrossIcon" class="icon-container mr-6">
        <img alt="cross icon" [src]="customCrossIcon" />
      </div>
      {{ excludedItem }}
    </li>
  </ul>
</ng-template>
