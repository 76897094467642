import { Injectable } from '@angular/core';
import {
  QuotesPageSelectors,
  QuotesPartialState,
} from '@common/data-access-quotes';
import {
  DirectDebit,
  PersonalDetailsFormSubmitType,
  PersonalDetailsState,
  TaggingMetadata,
  TaggingPersonalDetails,
} from '@common/util-models';
import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as fromPersonalDetailsActions from './personal-details.actions';
import * as fromPersonalDetailsSelectors from './personal-details.selectors';

@Injectable()
export class PersonalDetailsFacade {
  taggingMetadata$ = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  personalDetails$ = this.store.select(
    fromPersonalDetailsSelectors.getPersonalDetails
  );

  personalDetailsState$: Observable<PersonalDetailsState> = this.store.select(
    fromPersonalDetailsSelectors.getPersonalDetailsState
  );

  directDebit$ = this.store.select(fromPersonalDetailsSelectors.getDirectDebit);

  address$ = this.store.select(fromPersonalDetailsSelectors.getAddress);

  isMarketingPreferenceSet$ = this.store.select(
    fromPersonalDetailsSelectors.isMarketingPreferenceSet
  );

  isPaperlessPlanDocumentPreferenceSet$ = this.store.select(
    fromPersonalDetailsSelectors.isPaperlessPlanDocumentPreferenceSet
  );

  marketingPreferences$ = this.store
    .select(fromPersonalDetailsSelectors.getMarketingPreferences)
    .pipe(filterNullUndefined());

  constructor(
    private store: Store<PersonalDetailsState & QuotesPartialState>
  ) {}

  updatePersonalDetails(data: PersonalDetailsFormSubmitType) {
    this.store.dispatch(
      fromPersonalDetailsActions.updatePersonalDetails({ payload: data })
    );
  }

  updateNewDirectDebitDetails(directDebitDetails: DirectDebit) {
    this.store.dispatch(
      fromPersonalDetailsActions.updateDirectDebit({
        payload: directDebitDetails,
      })
    );
  }

  updateMarketingPreference(marketingPreference: MarketingPreferences) {
    this.store.dispatch(
      fromPersonalDetailsActions.updateMarketingPreference({
        payload: marketingPreference,
      })
    );
  }

  updatePlanDocumentPreference(paperless: boolean) {
    this.store.dispatch(
      fromPersonalDetailsActions.updatePlanDocumentPreference({ paperless })
    );
  }

  proceedGuestToPayment(): void {
    this.store.dispatch(fromPersonalDetailsActions.proceedToPayment());
  }

  exitingPersonalDetails() {
    this.store.dispatch(fromPersonalDetailsActions.exitingPersonalDetails());
  }

  tagPersonalDetails(personalDetails: TaggingPersonalDetails) {
    this.store.dispatch(
      fromPersonalDetailsActions.tagPersonalDetails({
        taggingDetails: personalDetails,
      })
    );
  }
}
