import { OptionItem } from './form.types';

export interface ApplianceResponse {
  applianceName: string;
  applianceCode: string;
  applianceCodeLt?: string;
  category?: string;
  superCategory: string;
  searchTerms: string[];
  icon: string;
  image: string;
  priceBand: OptionItem[];
}

export interface Appliance {
  description: string;
  code: string;
  icon: string;
  image: string;
  category?: string;
  applianceCodeLt?: string;
  priceBand: OptionItem[];
}
