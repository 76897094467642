import { UntypedFormGroup } from '@angular/forms';
import { distinctUntilChangedDeep } from '@domgen/dgx-fe-common';
import { OperatorFunction, pipe } from 'rxjs';
import { filter, map, skip, startWith, switchMap } from 'rxjs/operators';

export function formValue<T>(): OperatorFunction<
  UntypedFormGroup | null | undefined,
  T
> {
  return pipe(
    filter((form: UntypedFormGroup | null | undefined) => !!form),
    map(
      (form: UntypedFormGroup | null | undefined) => form as UntypedFormGroup
    ),
    switchMap((form: UntypedFormGroup) =>
      form.valueChanges.pipe(
        startWith(form.getRawValue()),
        map(() => form.getRawValue()),
        distinctUntilChangedDeep()
      )
    )
  );
}

export function formValueChanges<T>(): OperatorFunction<
  UntypedFormGroup | null | undefined,
  T
> {
  return pipe(formValue<T>(), skip(1));
}
