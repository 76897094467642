import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  RadioItemData,
  RadioListItemValue,
} from '@domgen/dgx-fe-components-core';
import { ExcessPrice } from '@common/util-models';
@Component({
  selector: 'wl-excess-selection',
  templateUrl: './excess-selection.component.html',
  styleUrls: ['./excess-selection.component.scss'],
})
export class ExcessSelectionComponent implements OnChanges {
  @Input() value: RadioListItemValue | undefined;
  @Input() availableExcessPrices: ExcessPrice[] | null = [];
  @Input() isInsurance: boolean | null = false;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();
  radioItems: RadioItemData[] = [];
  selectedValue: RadioListItemValue = 0;
  excessData = {
    title: 'Customise your plan',
    desc: 'Pick the plan that works best for you: a higher call-out charge means a lower monthly payment.',
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInsurance) {
      this.excessData.title = 'Customise your cover';
      this.excessData.desc =
        'Pick the cover that works best for you: higher excess means a lower monthly payment.';
    } else {
      this.excessData.title = 'Customise your plan';
      this.excessData.desc =
        'Pick the plan that works best for you: a higher call-out charge means a lower monthly payment.';
    }

    if (!(changes.availableExcessPrices || changes.value)) {
      return;
    }
    if (
      this.availableExcessPrices?.length &&
      !this.availableExcessPrices.find(
        (excess) => excess.excessAmount === this.value
      )
    ) {
      this.selectedValue = this.availableExcessPrices[0].excessAmount;
    } else {
      this.selectedValue = this.value || 0;
    }

    this.generateRadioItems(
      this.availableExcessPrices ? this.availableExcessPrices : []
    );
  }

  onExcessChange(excess: RadioListItemValue) {
    this.valueChange.emit(typeof excess === 'number' ? excess : 0);
  }

  private generateRadioItems(excessItems: ExcessPrice[]) {
    const excessLabel = this.isInsurance ? 'excess' : 'call-out charge';

    this.radioItems = excessItems.map((excess: ExcessPrice) => {
      const dd = excess.paymentOptions.directDebit;
      const isDiscount = dd?.discount;

      let desc = '';
      let subDesc = '';
      let subDesc2 = null;

      if (isDiscount) {
        const discountPeriod = `1 to ${dd?.discountPeriod}`;
        const fullPricePeriod = `${(dd?.discountPeriod || 0) + 1} to ${
          dd?.numPayments
        }`;
        desc = `£${(dd?.firstDiscountPayment || 0).toFixed(
          2
        )} per month ${discountPeriod}`;
        subDesc = `£${(dd?.subsequentDiscountPayment || 0).toFixed(
          2
        )} per month ${fullPricePeriod}`;
        subDesc2 = `or £${(dd?.fee || 0).toFixed(2)} for the year`;
      } else {
        desc = `£${(dd?.subsequentPayment || 0).toFixed(2)} per month`;
        subDesc = `or £${(dd?.fee || 0).toFixed(2)} for the year`;
      }

      return {
        value: excess.excessAmount,
        label: `£${excess.excessAmount.toFixed(2)} ${excessLabel}`,
        desc,
        subDesc,
        subDesc2,
      };
    });
  }
}
