import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { RuntimeConfig } from '@common/util-models';
import {
  CmsConfigToken,
  CmsDataAccessService,
  CmsModuleConfig,
  CmsPageContentResolver,
  CmsPageResolverData,
} from '@domgen/dgx-fe-content-management';
import { Observable } from 'rxjs';

import { RUNTIME_CONFIG } from '../../tokens';

@Injectable({ providedIn: 'root' })
export class JsonCmsPageContentResolver
  implements Resolve<CmsPageResolverData>
{
  constructor(
    private cmsService: CmsDataAccessService,
    private cmsPageContentResolver: CmsPageContentResolver,
    @Inject(CmsConfigToken) private config: CmsModuleConfig,
    @Inject(RUNTIME_CONFIG) private runtimeConfig: RuntimeConfig
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CmsPageResolverData> {
    return this.runtimeConfig.cmsForceJsonExtension
      ? this.jsonCmsPageResolve(state)
      : this.cmsPageContentResolver.resolve(route, state);
  }

  private jsonCmsPageResolve(
    state: RouterStateSnapshot
  ): Observable<CmsPageResolverData> {
    const pageRoot = this.config.pagesRoot;
    const rawPagePath = `${pageRoot}${state?.url}`;
    const JSON_EXTENSION = '.json';

    let pagePath = this.config.useCachedCmsOutput
      ? `${state?.url}${JSON_EXTENSION}`
      : state.url === '/'
      ? pageRoot + JSON_EXTENSION
      : rawPagePath + JSON_EXTENSION;
    pagePath =
      pagePath.split('?').length > 1
        ? `${pagePath.split('?')[0]}${JSON_EXTENSION}`
        : pagePath;

    return this.cmsService.getPage(pagePath);
  }
}
