export const yourDetailsStep = {
  label: 'Your Details',
  routes: ['/checkout'],
};

export const paymentDetailsStep = {
  label: 'Payment Details',
  routes: ['/checkout/direct-debit-payment', '/checkout/logged-in'],
};

export const confirmationStep = {
  label: 'Confirmation',
  routes: ['/checkout/order-confirmation'],
};
