import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import {
  combineLatest,
  fromEvent,
  iif,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  timer,
} from 'rxjs';
import {
  debounce,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  OptionItem,
  TypeAheadDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';

export interface TemplateEvent {
  type: 'keyDown' | 'input' | 'mouseEnter' | 'blur' | 'click';
  payload: KeyboardEvent | number | Event | string;
}

export interface TypeaheadState {
  options: (string | OptionItem)[];
  filteredOptions: (string | OptionItem)[];
  highlightedFilteredOptions: (string | OptionItem)[][];
  activeOptionIndex: number;
  selectedOption: string | OptionItem;
  tooltipName: string;
  optionClass: string;
  blurred: boolean;
  showOptions: boolean;
  fieldDef: TypeAheadDef | undefined;
  value: string; // Can be either enteredInputSubject, initialInput OR selectedOption
  analytics: AnalyticsData | undefined;
  formControl: AbstractControl | undefined;
  errorMessage: string | undefined;
  nativeDomElement: HTMLElement | undefined;
  templateEvent: TemplateEvent | undefined;
  validate: boolean;
  disable: boolean;
  loading: boolean;
}

export const initialState: TypeaheadState = {
  options: [],
  filteredOptions: [],
  highlightedFilteredOptions: [],
  activeOptionIndex: -1,
  selectedOption: '',
  tooltipName: 'tooltip',
  optionClass: 'typeahead-option',
  blurred: false,
  showOptions: false,
  fieldDef: undefined,
  value: '',
  analytics: undefined,
  formControl: undefined,
  errorMessage: undefined,
  nativeDomElement: undefined,
  templateEvent: undefined,
  validate: false,
  disable: false,
  loading: false,
};

/** @dynamic */
@Injectable()
export class TypeaheadStateService extends ComponentStore<TypeaheadState> {
  debounceTime = 100;
  optionsLimit = 10;

  // ************ Selectors **********
  private readonly fieldDef$ = this.select(
    (state: TypeaheadState) => state.fieldDef
  );
  private readonly options$ = this.select(
    (state: TypeaheadState) => state.options
  );

  private readonly showOptions$ = this.select(
    (state: TypeaheadState) => state.showOptions
  );
  private readonly highlightedFilteredOptions$ = this.select(
    (state: TypeaheadState) => state.highlightedFilteredOptions
  );

  private readonly selectBlurred$ = this.select(
    (state: TypeaheadState) => state.blurred
  );
  private readonly formControl$ = this.select(
    (state: TypeaheadState) => state.formControl
  );
  private readonly nativeDomElement$ = this.select(
    (state: TypeaheadState) => state.nativeDomElement
  );
  private readonly templateEvent$ = this.select(
    (state: TypeaheadState) => state.templateEvent
  );
  private readonly validate$ = this.select(
    (state: TypeaheadState) => state.validate
  );
  private readonly optionClass$ = this.select(
    (state: TypeaheadState) => state.optionClass
  );
  private readonly loading$ = this.select(
    (state: TypeaheadState) => state.loading
  );

  // ************ Public Selectors **********
  readonly value$ = this.select((state: TypeaheadState) => state.value);

  readonly selectedOption$ = this.select(
    (state: TypeaheadState) => state.selectedOption
  ).pipe(filter((selectedOption) => !!selectedOption));

  readonly analytics$ = this.select(
    (state: TypeaheadState) => state.analytics
  ).pipe(
    filter((analytics) => !!analytics),
    distinctUntilChanged((x, y) => this.analyticsEventIsEqual(x, y))
  );

  readonly vm$ = this.select((state: TypeaheadState) => ({
    fieldDef: state.fieldDef,
    showOptions: state.showOptions,
    selectedOption: state.selectedOption,
    value: state.value,
    activeOptionIndex: state.activeOptionIndex,
    filteredOptions: state.filteredOptions,
    highlightedFilteredOptions: state.highlightedFilteredOptions,
    tooltipName: state.tooltipName,
    errorMessage: state.errorMessage,
    optionClass: state.optionClass,
    disable: state.disable,
    loading: state.loading,
  }));

  // *********** Updater Streams ***********
  private updateOptions$ = this.fieldDef$.pipe(
    switchMap((fieldDef) => fieldDef?.optionsStream$ || of([]))
  );

  private enteredInputEvent$ = this.templateEvent$.pipe(
    this.filterTemplateEvent<string>('input')
  );

  public filteredOptions$ = this.enteredInputEvent$.pipe(
    withLatestFrom(this.fieldDef$, this.options$),
    filter(([, fieldDef]) => !!fieldDef),
    debounce(([, fieldDef]) =>
      timer(fieldDef?.debounceTime || this.debounceTime)
    ),
    distinctUntilChanged(
      ([previousInput], [currentInput, fieldDef]) =>
        previousInput === currentInput && fieldDef?.showAllOptions === false
    ),
    tap(([enteredInput, fieldDef]) => {
      if (
        fieldDef?.fetchOptionEntities &&
        enteredInput.length >= (fieldDef?.startSearchFromCharacter || 1)
      ) {
        this.setLoading(true);
      }
    }),
    switchMap(([enteredInput, fieldDef, options]) =>
      iif(
        () => !!fieldDef?.fetchOptionEntities,

        fieldDef?.fetchOptionEntities &&
          enteredInput.length >= (fieldDef?.startSearchFromCharacter || 1)
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            fieldDef?.fetchOptionEntities!(enteredInput).pipe(
              tap(() => {
                this.updateDynamicOptions(options);
                this.setLoading(false);
              }),
              map((options: OptionItem[]) => ({
                enteredInput,
                filteredOptions: this.filterOptions(
                  enteredInput,
                  options,
                  fieldDef?.optionsLimit || this.optionsLimit,
                  fieldDef
                ),
              }))
            )
          : of(),
        of({
          enteredInput,
          filteredOptions: this.filterOptions(
            enteredInput,
            options,
            fieldDef?.optionsLimit || this.optionsLimit,
            fieldDef
          ),
        })
      )
    )
  );

  public noResultsFound$ = combineLatest([
    this.templateEvent$,
    this.filteredOptions$,
    this.value$,
    this.fieldDef$,
  ]).pipe(
    map(([templateEvent, options, value, fieldDef]) => {
      return (
        templateEvent?.type === 'input' &&
        options?.filteredOptions?.length === 0 &&
        value.length >= (fieldDef?.startSearchFromCharacter || 1)
      );
    })
  );

  private keyPressedEvent$ = this.templateEvent$.pipe(
    this.filterTemplateEvent<KeyboardEvent>('keyDown')
  );

  private increaseActiveOptionIndex$ = this.keyPressedEvent$.pipe(
    this.filterAndPreventDefault('ArrowDown', 'Down'),
    mapTo(true)
  );

  private decreaseActiveOptionIndex$ = this.keyPressedEvent$.pipe(
    this.filterAndPreventDefault('ArrowUp', 'Up'),
    mapTo(false)
  );

  private selectActiveOptionEnterTab$ = this.keyPressedEvent$.pipe(
    this.filterAndPreventDefault('Enter', 'Tab')
  );

  private escapeKeyDown$ = fromEvent<KeyboardEvent>(
    this.document,
    'keydown'
  ).pipe(this.filterAndPreventDefault('Escape'));

  clickOutside$ = fromEvent<MouseEvent>(this.document, 'mousedown').pipe(
    withLatestFrom(this.nativeDomElement$),
    map(([event, element]) =>
      element?.contains(event.target as HTMLElement) ? false : true
    ),
    filter((canDismissOptions) => !!canDismissOptions)
  );

  private mouseEnterEvent$ = this.templateEvent$.pipe(
    this.filterTemplateEvent<number>('mouseEnter')
  );

  private activeOptionIndex$ = this.mouseEnterEvent$.pipe(
    this.filterOnShowOptions(),
    map(([event]) => event)
  );

  private dismissOptionsUpdater$ = merge(
    this.clickOutside$,
    this.escapeKeyDown$
  );

  private errorMessage$ = this.enteredInputEvent$.pipe(
    withLatestFrom(this.selectBlurred$, this.formControl$, this.fieldDef$),
    filter(
      ([, blurred, formControl, fieldDef]) =>
        !!blurred && !!formControl && !!fieldDef
    ),
    tap(([input]) => this.updateModel(input)),
    map(([, , formControl, fieldDef]) =>
      this.getErrorMessage(formControl, fieldDef)
    )
  );

  private validateFormControl$ = this.validate$.pipe(
    filter((validate) => !!validate),
    withLatestFrom(this.formControl$, this.fieldDef$),
    tap(([, formControl]) => {
      formControl?.markAsTouched();
    }),
    map(([, formControl, fieldDef]) =>
      this.getErrorMessage(formControl, fieldDef)
    )
  );

  private selectOptionClick$ = this.templateEvent$.pipe(
    this.filterTemplateEvent<number>('click')
  );

  private blurEvent$ = this.templateEvent$.pipe(
    this.filterTemplateEvent<Event>('blur')
  );

  private blur$ = this.blurEvent$.pipe(
    withLatestFrom(
      this.selectBlurred$,
      this.value$,
      this.formControl$,
      this.fieldDef$,
      this.optionClass$
    ),
    filter(
      ([event, , , formControl, fieldDef, optionClass]) =>
        !!formControl &&
        !!fieldDef &&
        // Filter blurs if they have been on one of the options
        !(
          (event as FocusEvent)?.relatedTarget as HTMLElement
        )?.classList?.contains(optionClass)
    ),
    map(([, blurred, input, formControl, fieldDef]) => {
      if (!blurred) {
        this.updateModel(input);
        return this.getErrorMessage(formControl, fieldDef);
      }
      return undefined;
    })
  );

  // *********** Updaters *********** //
  readonly setTemplateEvent = this.updater(
    (state, templateEvent: TemplateEvent) => ({
      ...state,
      templateEvent,
    })
  );

  readonly clearInput = this.updater((state) => {
    state.formControl?.reset();
    return {
      ...state,
      templateEvent: {
        type: 'input',
        payload: '',
      },
      disable: false,
    };
  });

  readonly setFormControl = this.updater(
    (state, formControl: AbstractControl) => ({
      ...state,
      formControl,
    })
  );

  readonly setValidate = this.updater((state, validate: boolean) => ({
    ...state,
    validate,
  }));

  readonly setNativeDomElement = this.updater(
    (state, nativeDomElement: HTMLElement) => ({
      ...state,
      nativeDomElement,
    })
  );

  readonly setFieldDef = this.updater((state, fieldDef: TypeAheadDef) => ({
    ...state,
    fieldDef,
    value: state.value || fieldDef.initialValue || '',
  }));

  private readonly updateValueFromEnteredInput = this.updater(
    (state, enteredInput: string) => ({
      ...state,
      value: enteredInput,
    })
  )(this.enteredInputEvent$);

  readonly updateForWriteValue = this.updater(
    (state, value: string | undefined) => {
      return {
        ...state,
        value: value || '',
      };
    }
  );

  private readonly updateOptions = this.updater(
    (state, options: (string | OptionItem)[]) => ({
      ...state,
      options,
    })
  )(this.updateOptions$);

  readonly updateDynamicOptions = this.updater(
    (state, options: (string | OptionItem)[]) => {
      return {
        ...state,
        options,
      };
    }
  );

  private readonly updateFilteredOptions = this.updater(
    (
      state,
      {
        filteredOptions,
      }: { enteredInput: string; filteredOptions: (string | OptionItem)[] }
    ) => {
      return { ...state, filteredOptions };
    }
  )(this.filteredOptions$);

  private readonly updateHighlightedOptions = this.updater(
    (
      state,
      {
        enteredInput,
        filteredOptions,
      }: { enteredInput: string; filteredOptions: (string | OptionItem)[] }
    ) => {
      return {
        ...state,
        highlightedFilteredOptions: filteredOptions.map(
          (fo: string | OptionItem) => this.highlightOption(enteredInput, fo)
        ),
      };
    }
  )(this.filteredOptions$);

  private readonly updateShowOptionsAndIndex = this.updater(
    (state, highlightedFilteredOptions: (string | OptionItem)[][]) => {
      return {
        ...state,
        showOptions: !!highlightedFilteredOptions?.length,
        activeOptionIndex: highlightedFilteredOptions?.length ? 0 : -1,
      };
    }
  )(this.highlightedFilteredOptions$);

  private readonly updateActiveOptionIndex = this.updater(
    (state, index: number) => ({
      ...state,
      activeOptionIndex: index,
    })
  )(this.activeOptionIndex$);

  private readonly increaseDecreaseActiveOptionIndex = this.updater(
    (state, increase: boolean) => {
      const optionsLength = state.filteredOptions.length;
      const activeOptionIndex = state.activeOptionIndex;
      return {
        ...state,
        activeOptionIndex: increase
          ? (activeOptionIndex + 1) % optionsLength
          : (activeOptionIndex + optionsLength - 1) % optionsLength,
      };
    }
  )(merge(this.increaseActiveOptionIndex$, this.decreaseActiveOptionIndex$));

  private readonly updateSelectionOnEnterTab = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, _tabEnterEvent: KeyboardEvent) => {
      const selectedOption: string | OptionItem =
        state.filteredOptions[state.activeOptionIndex];
      this.updateModel(selectedOption);
      return {
        ...state,
        ...this.resetStateForOptionSelection(
          selectedOption,
          state.fieldDef?.controlName,
          state.fieldDef?.disableOnSelect
        ),
      };
    }
  )(this.selectActiveOptionEnterTab$);

  private readonly updateSelectionOnClick = this.updater(
    (state, clickedIndex: number) => {
      const selectedOption: string | OptionItem =
        state.filteredOptions[clickedIndex];
      this.updateModel(state.filteredOptions[clickedIndex]);
      return {
        ...state,
        ...this.resetStateForOptionSelection(
          selectedOption,
          state.fieldDef?.controlName,
          state.fieldDef?.disableOnSelect
        ),
      };
    }
  )(this.selectOptionClick$);

  private dismissOptions = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, _dismissEvent: boolean | KeyboardEvent | string) => {
      return {
        ...state,
        filteredOptions: [],
        highlightedFilteredOptions: [],
      };
    }
  )(this.dismissOptionsUpdater$);

  private readonly updateErrorMessage = this.updater(
    (state, errorMessage: string | undefined) => ({
      ...state,
      errorMessage,
    })
  )(merge(this.errorMessage$, this.validateFormControl$));

  private readonly updateErrorMessageAnalytics = this.updater(
    (state, errorMessage: string | undefined) => {
      if (!state.blurred) {
        return {
          ...state,
          blurred: true,
          errorMessage,
          analytics: {
            controlName: state.fieldDef?.controlName,
            value: state.value,
            error: errorMessage,
          } as AnalyticsData,
        };
      } else {
        return {
          ...state,
          analytics: {
            controlName: state.fieldDef?.controlName,
            value: state.value,
            error: state.errorMessage,
          } as AnalyticsData,
        };
      }
    }
  )(this.blur$);
  private readonly setLoading = this.updater((state, loading: boolean) => {
    return { ...state, loading };
  });

  private onTouched: () => void = () => {
    return undefined;
  };
  private onChanged: (val: string) => void = () => {
    return undefined;
  };

  constructor(@Inject(DOCUMENT) private document: Document) {
    super(initialState);
  }

  saveOnChangeReference(fn: (val: string) => void) {
    this.onChanged = fn;
  }

  saveOnTouchedReference(fn: () => void) {
    this.onTouched = fn;
  }

  private filterOptions(
    enteredInput: string,
    options: (string | OptionItem)[],
    optionsLimit: number,
    fieldDef: TypeAheadDef | undefined
  ) {
    if (fieldDef?.showAllOptions) {
      return options;
    }

    return !enteredInput?.length ||
      enteredInput.length < (fieldDef?.startSearchFromCharacter || 0)
      ? []
      : options
          .filter((option: string | OptionItem) => {
            const label: string =
              typeof option === 'string' ? option : option.label;
            return label.toLowerCase().indexOf(enteredInput.toLowerCase()) > -1;
          })
          .slice(0, optionsLimit);
  }

  private highlightOption(text: string, option: string | OptionItem) {
    const label = typeof option === 'string' ? option : option.label;
    const parts = text.length
      ? label.split(new RegExp(`(${text})`, 'gmi'))
      : [text];
    return parts;
  }

  private getErrorMessage(
    control: AbstractControl | undefined,
    field: TypeAheadDef | undefined
  ) {
    const firstErrorType = control?.errors
      ? Object.keys(control.errors)[0]
      : null;
    return field?.validationMessages?.find((msg) => msg.type === firstErrorType)
      ?.message;
  }

  private updateModel(input: string | OptionItem) {
    const value: string = typeof input === 'string' ? input : input?.value;
    this.onChanged(value);
    this.onTouched();
  }

  private analyticsEventIsEqual(
    x: AnalyticsData | undefined,
    y: AnalyticsData | undefined
  ): boolean {
    if (!x && !y) {
      return true;
    }

    if (
      x?.controlName === y?.controlName &&
      x?.error === y?.error &&
      x?.value === y?.value
    ) {
      return true;
    }

    return false;
  }

  private filterAndPreventDefault(
    ...keysToFilter: string[]
  ): MonoTypeOperatorFunction<KeyboardEvent> {
    return (source$: Observable<KeyboardEvent>) => {
      return source$.pipe(
        this.filterOnShowOptions(),
        map(([event]) => event),
        filter(
          (event) =>
            !!keysToFilter.find((keyToFilter) => keyToFilter === event.key)
        ),
        this.preventDefault()
      );
    };
  }

  private filterOnShowOptions<T>(): OperatorFunction<T, [T, boolean]> {
    return (source$: Observable<T>) => {
      return source$.pipe(
        withLatestFrom(this.showOptions$),
        filter(([, showOptions]: [T, boolean]) => !!showOptions)
      );
    };
  }

  private preventDefault(): MonoTypeOperatorFunction<KeyboardEvent> {
    return (source$: Observable<KeyboardEvent>) => {
      return source$.pipe(tap((event) => event.preventDefault()));
    };
  }

  private filterTemplateEvent<
    T extends string | number | KeyboardEvent | Event
  >(eventType: string): OperatorFunction<TemplateEvent | undefined, T> {
    return (source$: Observable<TemplateEvent | undefined>) => {
      return source$.pipe(
        filter((templateEvent) => templateEvent?.type === eventType),
        map((templateEvent) => templateEvent?.payload as T)
      );
    };
  }

  private resetStateForOptionSelection(
    selectedOption: string | OptionItem,
    control: string | undefined,
    disableOnSelect: boolean | undefined
  ) {
    const value: string =
      typeof selectedOption === 'string'
        ? selectedOption
        : selectedOption?.value;

    return {
      selectedOption,
      value,
      activeOptionIndex: -1,
      filteredOptions: [],
      highlightedFilteredOptions: [],
      disable: !!disableOnSelect,
      errorMessage: undefined,
      analytics: {
        controlName: control,
        value: selectedOption,
        error: undefined,
      } as AnalyticsData,
    };
  }
}
