import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { AddressListDef, SelectionMode } from '../../interfaces';

@Component({
  selector: 'dgx-dfb-address-tabs-wrapper',
  templateUrl: './address-tabs-wrapper.component.html',
  styleUrls: ['./address-tabs-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressTabsWrapperComponent {
  public addressHeading = 'Your address';
  public addressSubheading =
    'Please provide your home address before continuing to payment.';
  @Input() disableSelection?: boolean;
  @Input() selectionMode = SelectionMode.Lookup;
  @Input() set field(value: AddressListDef) {
    if (value.addressHeading) {
      this.addressHeading = value.addressHeading;
    }

    if (value.addressSubheading) {
      this.addressSubheading = value.addressSubheading;
    }
  }
  @Output() selectedMode = new EventEmitter<SelectionMode>();

  setSelectionMode(selectionMode: SelectionMode, clickEvent: Event) {
    this.selectedMode.emit(selectionMode);
    clickEvent.preventDefault();
  }
}
