export interface CmsAsset {
  '@name': string;
  '@link': string;
  metadata: CmsMetadata;
}

export interface CmsMetadata {
  fileName: string;
  title: string;
}
