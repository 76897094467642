import { Appliance } from '@common/util-models';
import { createAction, props } from '@ngrx/store';

export const loadAppliances = createAction('[Appliances] Load appliances');

export const loadAppliancesSuccess = createAction(
  '[Appliance Details] Appliances loaded successfully',
  props<{ appliances: Appliance[] }>()
);

export const loadAppliancesFailure = createAction(
  '[Appliance Details] Load appliances failed',
  props<{ error: Error }>()
);

export const applianceSelected = createAction(
  '[Appliance Details] Appliance selected',
  props<{ code: string }>()
);
