import { Component, Input } from '@angular/core';

@Component({
  selector: 'sales-ui-banner',
  templateUrl: './ui-banner.component.html',
  styleUrls: ['./ui-banner.component.scss'],
})
export class UiBannerComponent {
  @Input() iconName!: string;
  @Input() maxWidth?: string;
}
