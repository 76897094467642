import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { SafeHandsClientConfig } from '@common/util-models';

@Component({
  selector: 'sales-safe-hands',
  templateUrl: './safe-hands.component.html',
  styleUrls: ['./safe-hands.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
})
export class SafeHandsComponent {
  @Input() safeHandsClientConfig?: SafeHandsClientConfig;
}
