<div class="footer-legal">
  <div class="footer-legal__content text--center" data-test-hook="legal-text">
    <ng-content></ng-content>

    <p>
      This site is protected by reCAPTCHA and the Google
      <a
        href="https://policies.google.com/privacy"
        class="footer-legal__link"
        target="_blank"
      >
        privacy policy
      </a>
      and
      <a
        href="https://policies.google.com/terms"
        class="footer-legal__link"
        target="_blank"
      >
        terms of service
      </a>
      apply.
    </p>
    <p *ngIf="copyrightOwner" class="footer-social__copyright">
      &copy; {{ currentYear }} {{ copyrightOwner }}
    </p>
  </div>
</div>
