import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CheckboxToggleDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

@Component({
  selector: 'dgx-dfb-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor
{
  @Input() field!: CheckboxToggleDef;
  @Input() isReadonly = false;
  @Input() customValidity?: () => string;
  @Output() checked: EventEmitter<boolean> = new EventEmitter();

  get validity(): string {
    return this.customValidity ? this.customValidity() : super.validity;
  }

  isChecked = false;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isChecked =
      !!this.field?.initialValue || !!this.ngControl?.control?.value;
  }

  onClick() {
    this.isChecked = !this.isChecked;
    this.checked.emit(this.isChecked);
    this.writeValue(this.isChecked, true);
  }

  onBlur(): void {
    this.ngControl.control?.markAsTouched();
    this.updateErrorMessage();
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: boolean, emitAnalytics: boolean = false) {
    super.writeValue(val, emitAnalytics);
  }
}
