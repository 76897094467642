import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import { ServiceReassuranceComponent } from './service-reassurance/service-reassurance.component';

@NgModule({
  imports: [CommonModule, DgxComponentsIconModule],
  declarations: [ServiceReassuranceComponent],
  exports: [ServiceReassuranceComponent],
})
export class ServiceReassuranceComponentModule {}
