import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PersonalDetailsState } from '@common/util-models';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import { combineLatestObj } from '@domgen/dgx-fe-common';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { ComponentStore } from '@ngrx/component-store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PersonalDetailsFormConfigService } from './personal-details-form-config.service';

export interface PersonalDetailsFormState {
  cmsFormData: CmsFormField[];
  form: UntypedFormGroup;
  formBuilderConfig: FieldDef[];
  prefilledForm?: PersonalDetailsState;
  validate: boolean;
}

@Injectable()
export class PersonalDetailsFormStateService extends ComponentStore<PersonalDetailsFormState> {
  // Selectors
  readonly formBuilderConfig$ = this.select(
    (state: PersonalDetailsFormState) => state.formBuilderConfig
  );
  readonly formGroup$ = this.select(
    (state: PersonalDetailsFormState) => state.form
  );
  readonly cmsFormData$ = this.select(
    (state: PersonalDetailsFormState) => state.cmsFormData
  );

  readonly validate$ = this.select(
    (state: PersonalDetailsFormState) => state.validate
  );

  readonly prefilledForm$ = this.select(
    (state: PersonalDetailsFormState) => state.prefilledForm
  );

  readonly setCmsFormData = this.updater(
    (state: PersonalDetailsFormState, cmsFormData: CmsFormField[]) => ({
      ...state,
      cmsFormData,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (state: PersonalDetailsFormState, prefilledForm: PersonalDetailsState) => ({
      ...state,
      prefilledForm,
    })
  );

  readonly validate = this.updater((state: PersonalDetailsFormState) => ({
    ...state,
    validate: true,
  }));

  readonly valueChanges$ = this.formGroup$.pipe(
    switchMap((formGroup) => formGroup.valueChanges)
  );

  // *** Updater Sources ****
  private fieldDefinitionUpdater$ = this.cmsFormData$.pipe(
    withLatestFrom(this.prefilledForm$),
    map(([formFieldsData, prefilledForm]) => {
      return formFieldsData.length
        ? this.formConfig.getFormbuilderConfig(formFieldsData, prefilledForm)
        : [];
    })
  );

  private readonly cmsFieldDefinitionUpdater = this.updater(
    (state: PersonalDetailsFormState, formBuilderConfig: FieldDef[]) => ({
      ...state,
      formBuilderConfig,
    })
  )(this.fieldDefinitionUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(
        map((conf) => this.dynamicFormBuilder.generateFormGroup(conf))
      )
    )
  );

  private readonly formGroupUpdater = this.updater(
    (state: PersonalDetailsFormState, value: UntypedFormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  readonly vm$ = combineLatestObj({
    fieldDef: this.formBuilderConfig$,
    formGroup: this.formGroup$,
    validate: this.validate$,
  });

  constructor(
    private formConfig: PersonalDetailsFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      formBuilderConfig: [],
      cmsFormData: [],
      form: new UntypedFormGroup({}),
      validate: false,
    });
  }
}
