import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrustBoxType } from '@common/util-models';

@Component({
  selector: 'sales-trust-pilot-micro',
  templateUrl: './trust-pilot-micro.component.html',
  styleUrls: ['./trust-pilot-micro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustPilotMicroComponent {
  TrustBox = TrustBoxType;
  @Input() tagsFilter? = '';
}
