<div
  class="header-nav-link"
  [class.header-nav-link--with-icon]="navigationLink?.icon"
>
  <ui-link (click)="onClick($event)" [navigationLink]="navigationLink">
    <ui-graphic
      *ngIf="navigationLink?.icon"
      [iconName]="navigationLink?.icon"
      iconSize="xl"
    ></ui-graphic>
  </ui-link>
</div>
