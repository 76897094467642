import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NgControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

import { AddressFieldNames } from '../../_shared/interfaces';
import { Address } from '../../_shared/interfaces/address.interface';
import { AddressDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { CustomValidatorService } from '../../_shared/services/custom-validator.service';
import { DynamicFormbuilderService } from '../../_shared/services/dynamic-formbuilder.service';
import {
  addressLine1FieldDef,
  addressLine2FieldDef,
  compulsoryCheckboxFieldDef,
  cityFieldDef,
  countyFieldDef,
  postcodeFieldDef,
  typeFieldDef,
} from './manual-address-entry-form-config';

export type ManualAddressEntryFieldDef = Pick<
  AddressDef,
  | 'addressLine1Label'
  | 'addressLine1Placeholder'
  | 'addressLine2Label'
  | 'addressLine2Placeholder'
  | 'cityLabel'
  | 'cityPlaceholder'
  | 'countyLabel'
  | 'countyPlaceholder'
  | 'postcodeLabel'
  | 'postcodePlaceholder'
  | 'initialValue'
  | 'compulsoryCheckboxLabel'
>;

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-manual-address-entry',
  templateUrl: './manual-address-entry.component.html',
  styleUrls: ['./manual-address-entry.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualAddressEntryComponent
  implements OnInit, OnChanges, ControlValueAccessor, OnDestroy, Validator
{
  manualAddressEntryForm!: UntypedFormGroup;
  subscriptions = new Subscription();
  field: ManualAddressEntryFieldDef = {};
  @Input() validateForm = false;
  @Input() set fieldDef(fieldDef: ManualAddressEntryFieldDef) {
    if (fieldDef) {
      this.field = fieldDef;
      this.updateLabelsAndPlaceholders(fieldDef);
      this.setInitialValue(fieldDef);
    }
  }

  addressLine1FieldDef = { ...addressLine1FieldDef };
  addressLine2FieldDef = { ...addressLine2FieldDef };
  cityFieldDef = { ...cityFieldDef };
  countyFieldDef = { ...countyFieldDef };
  postcodeFieldDef = { ...postcodeFieldDef };
  compulsoryCheckboxFieldDef = { ...compulsoryCheckboxFieldDef };
  typeFieldDef = { ...typeFieldDef };

  onTouched: () => void = () => {
    return undefined;
  };

  constructor(
    private dynamicFormBuilderService: DynamicFormbuilderService,
    private customValidatorService: CustomValidatorService,
    @Optional() private ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.manualAddressEntryForm =
      this.dynamicFormBuilderService.generateFormGroup([
        this.addressLine1FieldDef,
        this.addressLine2FieldDef,
        this.cityFieldDef,
        this.countyFieldDef,
        this.postcodeFieldDef,
        this.compulsoryCheckboxFieldDef,
        this.typeFieldDef,
      ]);
  }

  ngOnInit(): void {
    this.customValidatorService.postCodeCustomValidators$
      .pipe(untilDestroyed(this))
      .subscribe((customValidators) => {
        const postCodeControl =
          this.manualAddressEntryForm.controls[AddressFieldNames.Postcode];
        postCodeControl.setValidators([
          ...this.postcodeFieldDef.validators,
          customValidators,
        ]);
      });

    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.validator = Validators.compose([
        this.ngControl.control.validator,
        this.validate.bind(this),
      ]);
    }
    this.updateInitialValuesWithFormControlValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validateForm?.currentValue) {
      this.manualAddressEntryForm.markAllAsTouched();
    }
  }

  writeValue(address: Address): void {
    // Form Group already defined for manualAddressEntryForm so no need to set its value
    // Remove code due to indefinite loop with validate and writeValue method
  }

  registerOnChange(fn: () => void): void {
    this.subscriptions.add(
      this.manualAddressEntryForm.valueChanges.subscribe(fn)
    );
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.manualAddressEntryForm.valid
      ? null
      : {
          invalidForm: true,
        };
  }

  private updateInitialValuesWithFormControlValues() {
    this.addressLine1FieldDef.initialValue =
      this.manualAddressEntryForm.value[this.addressLine1FieldDef.controlName];

    this.addressLine2FieldDef.initialValue =
      this.manualAddressEntryForm.value[this.addressLine2FieldDef.controlName];

    this.cityFieldDef.initialValue =
      this.manualAddressEntryForm.value[this.cityFieldDef.controlName];

    this.countyFieldDef.initialValue =
      this.manualAddressEntryForm.value[this.countyFieldDef.controlName];

    this.postcodeFieldDef.initialValue =
      this.manualAddressEntryForm.value[this.postcodeFieldDef.controlName];

    this.typeFieldDef.initialValue =
      this.manualAddressEntryForm.value[this.typeFieldDef.controlName];

    this.compulsoryCheckboxFieldDef.initialValue =
      this.manualAddressEntryForm.value[
        this.compulsoryCheckboxFieldDef.controlName
      ];
  }

  private updateLabelsAndPlaceholders(fieldDef: ManualAddressEntryFieldDef) {
    this.addressLine1FieldDef.label = fieldDef.addressLine1Label
      ? fieldDef.addressLine1Label
      : this.addressLine1FieldDef.label;
    this.addressLine2FieldDef.label = fieldDef.addressLine2Label
      ? fieldDef.addressLine2Label
      : this.addressLine2FieldDef.label;
    this.cityFieldDef.label = fieldDef.cityLabel
      ? fieldDef.cityLabel
      : this.cityFieldDef.label;
    this.countyFieldDef.label = fieldDef.countyLabel
      ? fieldDef.countyLabel
      : this.countyFieldDef.label;
    this.postcodeFieldDef.label = fieldDef.postcodeLabel
      ? fieldDef.postcodeLabel
      : this.postcodeFieldDef.label;

    this.addressLine1FieldDef.placeholder = fieldDef.addressLine1Placeholder
      ? fieldDef.addressLine1Placeholder
      : this.addressLine1FieldDef.placeholder;
    this.addressLine2FieldDef.placeholder = fieldDef.addressLine2Placeholder
      ? fieldDef.addressLine2Placeholder
      : this.addressLine2FieldDef.placeholder;
    this.cityFieldDef.placeholder = fieldDef.cityPlaceholder
      ? fieldDef.cityPlaceholder
      : this.cityFieldDef.placeholder;
    this.countyFieldDef.placeholder = fieldDef.countyPlaceholder
      ? fieldDef.countyPlaceholder
      : this.countyFieldDef.placeholder;
    this.postcodeFieldDef.placeholder = fieldDef.postcodePlaceholder
      ? fieldDef.postcodePlaceholder
      : this.postcodeFieldDef.placeholder;
    this.compulsoryCheckboxFieldDef.label = fieldDef.compulsoryCheckboxLabel
      ? fieldDef.compulsoryCheckboxLabel
      : this.compulsoryCheckboxFieldDef.label;
  }

  private setInitialValue(fieldDef: ManualAddressEntryFieldDef) {
    this.manualAddressEntryForm?.patchValue({ ...fieldDef.initialValue });

    const postcode = fieldDef.initialValue?.postcode;
    if (postcode) {
      this.manualAddressEntryForm
        .get(this.postcodeFieldDef.controlName)
        ?.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.validator = null;
    }
    this.subscriptions.unsubscribe();
  }
}
