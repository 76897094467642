export interface SafeHandsFigure {
  label: string;
  value: string;
}

export interface SafeHandsClientConfig {
  bannerImage?: string;
  enabled?: boolean;
  figures?: {
    col1_l1: SafeHandsFigure;
    col1_l2: SafeHandsFigure;
    col2_l1: SafeHandsFigure;
    col2_l2: SafeHandsFigure;
  };
  note?: string;
  title?: string;
}
