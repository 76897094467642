<ui-card
  *ngIf="product"
  [selected]="selected"
  [ngClass]="{ selectable: selectable }"
  (click)="onClick()"
>
  <ui-card-header>
    <ui-card-title *ngIf="product.title">{{ product.title }}</ui-card-title>
    <ui-card-radio
      [selected]="selected"
      (valueChange)="isSelected()"
    ></ui-card-radio>
  </ui-card-header>
  <ui-card-body>
    <div class="price-container">
      <div class="monthPrice">
        <div *ngIf="product?.discount">
          £{{ product.discountedMonthlyPrice | number: '1.2-2' }} per Month
          {{ product.discountTerm }}
        </div>
        £{{ product.perMonthPrice | number: '1.2-2' }} per Month
        <span *ngIf="product?.discount"> {{ product.subsequentTerm }}</span>
      </div>
      <div class="yearlyPrice">
        <strong>Total for 12 months:</strong><br />
        £{{ product.yearlyPrice | number: '1.2-2' }}
      </div>
    </div>
    <div *ngIf="product.extraCost" class="extraCost">
      <div *ngIf="product?.discountedExtraCost">
        + £{{ product.discountedExtraCost | number: '1.2-2' }} per Month
        {{ product.discountTerm }}
      </div>
      + £{{ product.extraCost | number: '1.2-2' }} per Month
      <span *ngIf="product?.discountedExtraCost">
        {{ product.subsequentTerm }}</span
      >
    </div>
    <div class="desc" *ngIf="product.desc">{{ product.desc }}</div>
  </ui-card-body>
</ui-card>
