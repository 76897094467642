import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  mapTo,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import {
  MonthYearDateDef,
  MonthYearUpdater,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

interface MonthYearType {
  month: number | undefined;
  year: number | undefined;
}
let nextId = 1;

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-month-year-date',
  templateUrl: './month-year-date.component.html',
  styleUrls: ['./month-year-date.component.scss'],
  providers: [TooltipService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthYearDateComponent
  extends FormElementBaseComponent
  implements OnChanges, OnInit, ControlValueAccessor
{
  @Input() field!: MonthYearDateDef;
  @Input() set monthYearDateUpdater(
    monthYearDateUpdater: MonthYearUpdater | undefined
  ) {
    this.monthYearDateUpdater$.next(monthYearDateUpdater);
  }

  id = `month-year-date-${nextId++}`;
  disabled = false;
  yearOptions: number[] = [];
  monthOptions: number[] = [];
  value: MonthYearType = {
    month: undefined,
    year: undefined,
  };
  isInvalid: { month: boolean | undefined; year: boolean | undefined } = {
    month: false,
    year: false,
  };
  isValid: { month: boolean | undefined; year: boolean | undefined } = {
    month: false,
    year: false,
  };

  control: AbstractControl | null = null;

  monthNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  tooltipName = 'tooltip';

  monthYearDateUpdater$ = new BehaviorSubject<MonthYearUpdater | undefined>(
    undefined
  );

  constructor(
    @Optional() public ngControl: NgControl,
    protected formGroupDir: FormGroupDirective,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setInitialValue();
    this.showValidYearOptions();
    this.setDefaultMonth();
    this.bindControlValue();

    this.formGroupDir.form.statusChanges
      .pipe(
        untilDestroyed(this),
        tap(() => this.updateValidity())
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes?.validate?.currentValue) {
      this.saveMonth(this.value.month ? String(this.value.month) : undefined);
      this.saveYear(this.value.year ? String(this.value.year) : undefined);
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  /**
   * Modifies boolean flags that are used to assign 'is-valid' or 'is-invalid' classes
   * to month and year select elements.
   * This validation takes place when either month or year is chosen.
   */
  updateValidity(event?: Event) {
    const errors = this.ngControl.errors;
    this.isInvalid = { month: false, year: false };
    this.isValid = { month: false, year: false };
    this.isInvalid.month =
      !!errors &&
      (((errors['invalidMonth'] || errors['monthNotSelected']) &&
        this.validate) ||
        errors['applianceTooOld'] ||
        errors['applianceTooNew']);
    this.isValid.month = !this.isInvalid.month && !!this.value.month;
    this.isInvalid.year =
      !!errors &&
      (((errors['yearNotSelected'] || errors['invalidYear']) &&
        this.validate) ||
        errors['applianceTooOld'] ||
        errors['applianceTooNew']);
    this.isValid.year = !this.isInvalid.year && !!this.value.year;
    this.errorMessage =
      this.isInvalid.year || this.isInvalid.month ? this.errorMessage : '';
    if (event) {
      (event.target as HTMLInputElement).blur();
    }
  }

  saveMonth(val?: string | number, event?: Event) {
    this.value = {
      ...this.value,
      month: val ? Number(val) : undefined, // /item/quote API accepts month as a number
    };
    super.writeValue(this.value, false);
    this.updateValidity(event);
    if (this.value.year) {
      this.emitAnalyticsData(this.value);
    }
  }

  saveYear(val?: string | number, event?: Event) {
    this.value = {
      ...this.value,
      year: val ? Number(val) : undefined, // /item/quote API accepts year as a number
    };
    super.writeValue(this.value, false);
    this.updateValidity(event);
    if (this.value.month) {
      this.emitAnalyticsData(this.value);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  get monthValidityStatus(): string {
    const control = this.ngControl.control;

    if (!control || control?.disabled || control?.untouched) {
      return 'untouched';
    } else if (this.isInvalid.month) {
      return 'invalid';
    } else if (this.isValid.month) {
      return 'valid';
    }
    return 'untouched';
  }

  get yearValidityStatus(): string {
    const control = this.ngControl.control;

    if (!control || control?.disabled || control?.untouched) {
      return 'untouched';
    } else if (this.isInvalid.year) {
      return 'invalid';
    } else if (this.isValid.year) {
      return 'valid';
    }
    return 'untouched';
  }

  private setInitialValue(): void {
    if (this.field?.initialValue) {
      this.value = {
        month: this.field.initialValue.month,
        year: this.field.initialValue.year,
      };
      this.updateValidity();
      this.field.initialValue = undefined;
    }
  }

  private showValidYearOptions(): void {
    this.monthYearDateUpdater$
      .pipe(untilDestroyed(this))
      .subscribe((updater: MonthYearUpdater | undefined) => {
        if (updater?.maxAgeInMonth) {
          return this.setMaxMonthYearOptions(updater.maxAgeInMonth);
        }

        if (updater?.minimumAgeInMonths) {
          return this.setMinYearOptions(updater);
        }
        this.yearOptions = this.field.yearOptions;
      });
  }

  setMinYearOptions(monthYearConfig: MonthYearUpdater) {
    const currentYear = new Date().getFullYear();
    const minAgeInYears = Number(
      Math.floor((monthYearConfig.minimumAgeInMonths || 0) / 12)
    );
    this.yearOptions = this.field.yearOptions.filter(
      (year) =>
        currentYear - year >= minAgeInYears &&
        currentYear - year <= (monthYearConfig.maxAge || 8)
    );
  }

  setMaxMonthYearOptions(maxAgeInMonth: number) {
    const maxAgeInYears = maxAgeInMonth / 12;
    this.yearOptions = this.field.yearOptions.filter(
      (_, idx) => idx < maxAgeInYears + 1
    );
  }

  private setDefaultMonth(): void {
    const control = this.ngControl.control;
    if (!control) {
      return;
    }
    this.monthYearDateUpdater$
      .pipe(
        switchMap((updater?: MonthYearUpdater) =>
          control.valueChanges.pipe(
            startWith(control.value),
            debounceTime(25),
            distinctUntilChanged(
              (previous?: MonthYearType, current?: MonthYearType) =>
                previous?.month === current?.month &&
                previous?.year === current?.year
            ),
            filter(
              (value?: MonthYearType) =>
                !!updater?.defaultMonth &&
                updater?.defaultMonth !== value?.month
            ),
            mapTo(updater)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe((updater?: MonthYearUpdater) => {
        this.saveMonth(updater?.defaultMonth);
      });
  }

  private bindControlValue(): void {
    if (!this.ngControl.control) {
      return;
    }

    this.ngControl.control?.valueChanges
      .pipe(
        filter(
          (value?: MonthYearType) =>
            value?.month !== this.value?.month ||
            value?.year !== this.value.year
        ),
        untilDestroyed(this)
      )
      .subscribe((value?: MonthYearType) => {
        this.value = value ?? { month: undefined, year: undefined };
        this.updateValidity();
      });
  }
}
