import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'wl-direct-debit-instruction-confirmation-text',
  templateUrl: './direct-debit-instruction-confirmation-text.component.html',
  styleUrls: ['./direct-debit-instruction-confirmation-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectDebitInstructionConfirmationTextComponent implements OnInit {
  @Input() isInsurance: boolean | null = false;
  @Input() workingDays = 10;

  orgName = '';

  ngOnInit(): void {
    this.orgName = this.isInsurance
      ? 'Domestic & General Insurance PLC'
      : 'Domestic & General Services Limited';
  }
}
