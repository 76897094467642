import { BasketItem } from './basket-item.types';

export interface CheckoutResponse {
  completedItems: Array<CheckoutCompletedItem>;
}

export interface CheckoutCompletedItem {
  item: BasketItem;
  confirmation:
    | CheckoutAPIQuoteConfirmation
    | CheckoutAPIOfferConfirmation
    | CheckoutAPIRenewConfirmation;
}

export interface CheckoutAPIQuoteConfirmation {
  planNumber: string;
  breakdownStartDate: string;
  renewalDate: string;
  digitalId?: string;
  bundlePlanNumbers?: string[];
}

export interface CheckoutAPIOfferConfirmation {
  planNumber: string;
  breakdownStartDate: string;
  renewalDate: string;
  digitalId?: string;
}

export interface CheckoutAPIRenewConfirmation {
  planNumber: string;
  coverStartDate: string;
  digitalId?: string;
}
