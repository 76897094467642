import { CmsPage } from '@common/util-models';
import { APPLIANCE_DETAIL_FORM } from './appliance-detail-form.data';
import { CHECKOUT_DIRECT_DEBIT_FORM } from './checkout-direct-debit-form.data';
import { CHECKOUT_LOGGED_IN_USER_FORM } from './checkout-logged-in-user-form.data';
import { CHECKOUT_PERSONAL_DETAILS_FORM } from './checkout-personal-details-form.data';
import { CHECKOUT_SELECT_PAYMENT_FORM } from './checkout-select-payment-form.data';
import { CHECKOUT } from './checkout.data';
import { COVERS_EXCLUSIONS } from './covers-exclusions.data';
import { FAQS } from './faqs.data';
import { ORDER_CONFIRMATION_DETAIL } from './order-confirmation-deatil.data';

export const CMS_DATA = {
  //todo: add actual fallback data? or remove fallback logic entirely
  [CmsPage.OrenLandingOffer]: { ...APPLIANCE_DETAIL_FORM },
  [CmsPage.OrenLandingRenewal]: { ...APPLIANCE_DETAIL_FORM },
  [CmsPage.Appliance]: { ...APPLIANCE_DETAIL_FORM },
  [CmsPage.Quote]: { ...COVERS_EXCLUSIONS },
  [CmsPage.Checkout]: { ...CHECKOUT },
  [CmsPage.CheckoutPersonalDetails]: { ...CHECKOUT_PERSONAL_DETAILS_FORM },
  [CmsPage.CheckoutDirectDebit]: { ...CHECKOUT_DIRECT_DEBIT_FORM },
  [CmsPage.CheckoutLoggedInUser]: { ...CHECKOUT_LOGGED_IN_USER_FORM },
  [CmsPage.CheckoutSelectPayment]: { ...CHECKOUT_SELECT_PAYMENT_FORM },
  [CmsPage.FAQs]: { ...FAQS },
  [CmsPage.OrderConfirmationDetail]: { ...ORDER_CONFIRMATION_DETAIL },
  [CmsPage.Reinstate]: {},
  [CmsPage.CheckoutPaymentOptions]: {},
};
