import { Injectable } from '@angular/core';
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  ControlType,
  CustomFieldDef,
  FieldDef,
  GenericFieldDef,
  TypeAheadDef,
} from '../interfaces/dynamic-formbuilder.interface';
import { FormServicesModule } from './form-services.module';

@Injectable({
  providedIn: FormServicesModule,
})
export class DynamicFormbuilderService {
  readonly FIELDSET_NON_CONTROLS: string[] = [ControlType.SUBMIT];

  constructor(private fb: UntypedFormBuilder) {}

  /**
   * Takes fieldset array and converts into a simple FormGroup object
   * @param fieldset
   */
  generateFormGroup(
    fieldset: FieldDef[],
    options?: AbstractControlOptions
  ): UntypedFormGroup {
    const seedObj = {};
    const controlsConfig: {
      [key: string]: unknown;
    } = fieldset.reduce((obj, item: GenericFieldDef) => {
      if (!item.controlType || item.excludeFromFormGroup) {
        // skip fields with no form controls to create
        return obj;
      }
      const initialValue =
        item.initialValue !== undefined ? item.initialValue : '';
      const validators = item.validators || [];
      const asyncValidators = item.asyncValidators || [];
      return {
        ...obj,
        [<string>item.controlName]: [initialValue, validators, asyncValidators],
      };
    }, seedObj);

    return this.fb.group(controlsConfig, options);
  }

  /**
   * An observable 'selector' for individual form values
   * @param form
   * @param fieldName
   * @param fieldset optional - if you want Type Aheads to limit to startSearchFromCharacter
   * @param excludeInvalid optional - true if you want only validated field values
   */
  selectFieldValue$(
    form: UntypedFormGroup,
    fieldName: string,
    fieldset: FieldDef[] = [],
    excludeInvalid: boolean = false
  ) {
    const typeAhead = fieldset.find(
      (field) =>
        (<CustomFieldDef>field).controlName === fieldName &&
        field.controlType === ControlType.TYPEAHEAD
    );
    const minChars = (<TypeAheadDef>typeAhead)?.startSearchFromCharacter || 0;

    return form.valueChanges.pipe(
      map((formValues) => formValues[fieldName]),
      filter(
        (value) =>
          (typeof value === 'string' && value.length >= minChars) ||
          typeof value !== 'string'
      ),
      filter(() => (excludeInvalid ? form.controls[fieldName].valid : true))
    );
  }

  /**
   * An observable 'selector' for valid form values
   * @param form
   */
  selectValidFormValue$<T = unknown>(form: UntypedFormGroup): Observable<T> {
    return combineLatest([form.statusChanges, form.valueChanges]).pipe(
      filter(([status]) => status === 'VALID'),
      map(([, valueChanges]) => valueChanges),
      distinctUntilChanged()
    ) as Observable<T>;
  }
}
