import { PaymentType } from '@domgen/dgx-fe-common';
import { PaymentByDirectDebitDetails } from './checkout-request.types';

export interface PaymentByPaymentFlexToken {
  directDebitDetails?: PaymentByDirectDebitDetails;
  paymentType: PaymentType;
  preferredPaymentDay?: number;
  reference?: string;
  sourceType?: string;
}
