import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  distinctUntilChangedDeep,
  filterNullUndefined,
} from '@domgen/dgx-fe-common';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMapTo } from 'rxjs/operators';
import {
  queryParamsInit,
  saveQueryParamsAWC,
  saveQueryParamsStore,
} from './query-params.action';

@Injectable()
export class QueryParamsEffects implements OnInitEffects {
  awc$: Observable<string> = this.route.queryParamMap.pipe(
    filterNullUndefined(),
    distinctUntilChangedDeep(),
    map((queryParamMap) => queryParamMap.get('awc')),
    filterNullUndefined(),
    distinctUntilChangedDeep()
  );

  store$: Observable<string> = this.route.queryParamMap.pipe(
    filterNullUndefined(),
    distinctUntilChangedDeep(),
    map((queryParamMap) => queryParamMap.get('store')),
    filterNullUndefined(),
    distinctUntilChangedDeep()
  );

  affiliateStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(queryParamsInit),
      switchMapTo(this.store$),
      map((store: string) =>
        saveQueryParamsStore({
          store,
        })
      )
    )
  );

  affiliateAwc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(queryParamsInit),
      switchMapTo(this.awc$),
      map((awc: string) =>
        saveQueryParamsAWC({
          awc,
        })
      )
    )
  );

  constructor(private actions$: Actions, private route: ActivatedRoute) {}

  ngrxOnInitEffects(): Action {
    return queryParamsInit();
  }
}
