import {
  CheckoutCompletedItem,
  CheckoutPlanDetails,
} from '@common/util-models';

export function mapMultiPlanDetails(
  checkedOutItems: CheckoutCompletedItem[] | undefined,
  paymentDay: number | undefined
): CheckoutPlanDetails[] | undefined {
  if (!checkedOutItems?.length) {
    return undefined;
  }

  return checkedOutItems.map((checkedOutItem: CheckoutCompletedItem) => {
    const confirmation = checkedOutItem.confirmation;
    const applianceDetails = checkedOutItem.item.data.applianceDetails;
    const quote = checkedOutItem.item.data.quotes[0];

    return {
      paymentDay,
      paymentOption: quote?.paymentOptions[0],
      confirmation,
      applianceDetails,
      periodOfCover: quote?.periodOfCover,
      itemType: checkedOutItem?.item.itemType,
      paymentTerm: quote?.paymentTerm,
      excessAmount: quote?.excessAmount || 0,
      productType: quote?.productType,
    };
  });
}
