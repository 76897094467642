import { Component, Input } from '@angular/core';

@Component({
  selector: 'sales-feature-hero',
  templateUrl: './feature-hero.component.html',
  styleUrls: ['./feature-hero.component.scss'],
})
export class FeatureHeroComponent {
  @Input() featureTitle!: string;
  @Input() topFeatures!: Array<{ title: string }>;
  @Input() src!: string;
  @Input() altTag!: string;
  @Input() iconName!: string;
}
