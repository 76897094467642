<dgx-ui-wl-header
  clientLogoPath="assets/images/edf-logo.svg"
  [navigationLinks]="headerNavigationLinks"
></dgx-ui-wl-header>
<main>
  <ui-progress-tracker-route-based
    [steps]="progressSteps$ | async"
    [currentRoute]="currentRoute"
  ></ui-progress-tracker-route-based>
  <router-outlet></router-outlet>
</main>

<wl-footer></wl-footer>
<sales-loader [@fadeInOut] *ngIf="loading$ | async"></sales-loader>
