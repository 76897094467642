export interface RuntimeConfig {
  catalogueApi: string;
  basketApi: string;
  personApi?: string;
  //todo: not used? remove?
  domain: string;
  //todo: not used? remove?
  sales: string;
  //todo: rename to commonApi
  common: string;
  identityApiUrl: string;
  worldpayIframeHelperURL: string;
  recaptchaSiteKey: string;
  cmsRestUrlBase: string;
  cmsTemplateDefinitionBase: string;
  cmsContentAppBase: string;
  cmsDamRawUrl: string;
  cmsForceJsonExtension: boolean;
  cookieProDomainScript: string;
  // Auth Lib needs the version in config.json. This is the reason this property is included here even though its always static
  identityApiUrlVersion: string;
  imeiApi?: string;
  refreshAPI?: string;
  saleReporting?: string;
  csp?: {
    domain: string;
    [key: string]: string;
  };
  awinTrackingTestMode?: boolean;
  mentionMeApi?: string;
  paymentApiUrl?: string;
}
