import {
  BasketItemPaymentOption,
  ContractType,
  CoverType,
  CoverTypeName,
  ItemType,
  PaymentType,
  WhichTitle,
} from './basket-item.types';
import { OfferDetails, RenewDetails } from './offer-renewal-quotes.types';

export interface CoverPaymentOptions {
  directDebit?: BasketItemPaymentOption;
  card?: BasketItemPaymentOption;
}
export interface Cover {
  breakdownStartDate: string;
  companyCode: string;
  contractTypeCode: ContractType;
  coverType: CoverType;
  description?: string;
  excessAmount?: number;
  isBundle: boolean;
  name?: CoverTypeName;
  offerDetails?: OfferDetails;
  paymentOptions: CoverPaymentOptions;
  paymentTerm: string;
  periodOfCover: number;
  productType: string;
  quoteId: string;
  renewDetails?: RenewDetails;
  schemeCode: string;
  waitDays: number;
}

export interface ExcessPrice {
  excessAmount: number;
  paymentOptions: CoverPaymentOptions;
}
export interface AvailableCover extends Cover {
  isUpgrade: boolean;
}

export interface UpgradableCover {
  base: Cover;
  upgrade: Cover;
}

export interface Quote {
  applianceCategory: string;
  applianceCode: string;
  applianceIcon: string;
  applianceName: string;
  brandCode: string;
  brandName: string;
  cover: Cover;
  itemId: string;
  itemType: ItemType;
  purchaseDate: Date;
}

export interface QuoteSummaryConfig {
  hideTrustMarks?: boolean;
  discountPercentage?: number;
  isForSmallScreen: boolean;
  whichTitle: WhichTitle;
}

export interface TaggingMetadata {
  companyCode: string;
  inWarranty: boolean;
  outOfWarranty: boolean;
  schemeCode: string;
  applianceCode: string;
  applianceName: string;
  brandCode: string;
  brandName: string;
  purchasePrice: number;
  purchaseMonth: number;
  purchaseYear: number;
  applianceCategory: string;
  isHeating?: boolean;
}

export interface UpgradeCoverConfig {
  description: string;
  linkText: string;
  linkUrl?: string;
  coverType?: CoverType;
}

export interface Stats {
  label: string;
  amount: string;
}

export function calculateNumberSubsequentPayments(
  paymentOption: BasketItemPaymentOption
): number | undefined {
  if (!paymentOption.firstPayment || !paymentOption.subsequentPayment) {
    return undefined;
  }
  const fee: number = Math.floor(paymentOption.fee * 100);
  const firstPayment: number = Math.floor(paymentOption.firstPayment * 100);
  const subsequentPayment: number = Math.floor(
    paymentOption.subsequentPayment * 100
  );

  const numSubsequentPayments: number = Math.floor(
    (fee - firstPayment) / subsequentPayment
  );

  return numSubsequentPayments;
}

export const convertPaymentOptionsToObject = (
  paymentOptions: BasketItemPaymentOption[]
): CoverPaymentOptions => ({
  card: paymentOptions.find((po) => po.paymentType === PaymentType.Card),
  directDebit: paymentOptions.find(
    (po) => po.paymentType === PaymentType.DirectDebit
  ),
});
