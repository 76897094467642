import {
  CheckoutCompletedItem,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
  StopAutoRenewalCode,
} from '@common/util-models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as CheckoutActions from './checkout.actions';

export const CHECKOUT_FEATURE_KEY = 'checkout';

export interface CheckoutState extends EntityState<CheckoutCompletedItem> {
  remoteStateCheckout: RemoteData<null>;
  remoteStateValidateDirectDebitDetails: RemoteData<null>;
  directDebitDetailsValid?: boolean;
  autoRenewalPreference?: StopAutoRenewalCode | undefined;
}

export interface CheckoutPartialState {
  readonly [CHECKOUT_FEATURE_KEY]: CheckoutState;
}

export const checkoutAdapter: EntityAdapter<CheckoutCompletedItem> =
  createEntityAdapter<CheckoutCompletedItem>({
    selectId: (checkedOutItem) => checkedOutItem.item.itemId,
  });

export const initialState: CheckoutState = checkoutAdapter.getInitialState({
  remoteStateCheckout: remoteDataNotFetched(),
  remoteStateValidateDirectDebitDetails: remoteDataNotFetched(),
  directDebitDetailsValid: undefined,
  autoRenewalPreference: undefined,
});

const checkoutReducer = createReducer(
  initialState,

  on(CheckoutActions.startCheckout, (state) => {
    return checkoutAdapter.removeAll({
      ...state,
      remoteStateValidateDirectDebitDetails: remoteDataNotFetched(),
      remoteStateCheckout: remoteDataNotFetched(),
    });
  }),

  on(CheckoutActions.validateDirectDebitDetails, (state) => ({
    ...state,
    remoteStateValidateDirectDebitDetails: remoteDataLoading(),
    directDebitDetailsValid: undefined,
  })),

  on(
    CheckoutActions.validateDirectDebitDetailsSuccess,
    (state, { validateDirectDebitResult }) => ({
      ...state,
      remoteStateValidateDirectDebitDetails: remoteDataOK(null),
      directDebitDetailsValid: validateDirectDebitResult.is_valid,
    })
  ),

  on(CheckoutActions.validateDirectDebitDetailsFailure, (state, { error }) => ({
    ...state,
    remoteStateValidateDirectDebitDetails: remoteDataError(error),
    directDebitDetailsValid: undefined,
  })),

  on(
    CheckoutActions.checkoutCardPayment,
    CheckoutActions.checkoutDirectDebitPayment,
    (state) => ({
      ...state,
      remoteStateCheckout: remoteDataLoading(),
    })
  ),

  on(
    CheckoutActions.exitingCheckoutGuestUser,
    CheckoutActions.exitingCheckoutLoggedInUser,
    (state) => ({
      ...state,
      directDebitDetailsValid: undefined,
    })
  ),

  on(CheckoutActions.checkoutFailure, (state, { error }) => {
    return {
      ...state,
      remoteStateCheckout: remoteDataError(error),
    };
  }),

  on(CheckoutActions.checkoutSuccess, (state, { checkoutResponse }) => {
    return checkoutAdapter.setAll(checkoutResponse.completedItems, {
      ...state,
      remoteStateCheckout: remoteDataOK(null),
    });
  }),

  on(
    CheckoutActions.saveAutoRenewalPreference,
    (state, { autoRenewalPreference }) => ({
      ...state,
      autoRenewalPreference,
    })
  )
);

export function reducer(state: CheckoutState | undefined, action: Action) {
  return checkoutReducer(state, action);
}
