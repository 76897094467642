import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-terms-and-conditions-upsell-text',
  templateUrl: './terms-and-conditions-upsell-text.component.html',
  styleUrls: ['./terms-and-conditions-upsell-text.component.scss'],
})
export class TermsAndConditionsUpsellTextComponent {
  @Input() termsUrl: string | undefined = undefined;
  @Input() appliancePolicyUrl: string | undefined = undefined;
  @Input() plumbingAndDrainagePolicyUrl: string | undefined = undefined;
  @Input() applianceInsuranceUrl: string | undefined = undefined;
  @Input() plumbingAndDrainageInsuranceUrl: string | undefined = undefined;
}
