import { BasketItem } from './basket-item.types';

export interface Basket {
  requestSource: string;
  requestAction?: string;
  basketId: string;
  digitalId?: string;
  status: string;
  created: string;
  updated: string;
  expiry?: string;
  items: Array<BasketItem>;
}
