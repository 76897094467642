import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DEFAULT_ERROR_MESSAGES } from './default-error-messages';

interface ErrorMessages {
  header: string;
  support: string;
  customerServiceTitle: string;
  helpNumber: string;
  availabilityText: string;
  availabilityHours: string[];
}

@Component({
  selector: 'dgx-ui-wl-sky-error',
  templateUrl: './sky-error.component.html',
  styleUrls: ['./sky-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkyErrorComponent implements OnInit {
  @Input()
  messagesOverride!: Partial<ErrorMessages>;

  messages!: ErrorMessages;

  ngOnInit() {
    this.messages = { ...DEFAULT_ERROR_MESSAGES, ...this.messagesOverride };
  }
}
