<div class="address-tabs-wrapper">
  <div class="address-tabs-wrapper__title-section">
    <div class="address-tabs-wrapper__title">{{ addressHeading }}</div>
    <div class="address-tabs-wrapper__description">{{ addressSubheading }}</div>
  </div>

  <div class="address-tabs-wrapper__tabs">
    <div class="address-tabs-wrapper__mode-section">
      <button
        class="address-tabs-wrapper__mode-button"
        [ngClass]="{
          'address-tabs-wrapper__mode-button--active':
            selectionMode === 'lookup'
        }"
        (click)="setSelectionMode($any('lookup'), $event)"
        [disabled]="disableSelection"
      >
        Find address
      </button>
      <button
        class="address-tabs-wrapper__mode-button"
        [ngClass]="{
          'address-tabs-wrapper__mode-button--active':
            selectionMode === 'manual'
        }"
        (click)="setSelectionMode($any('manual'), $event)"
        [disabled]="disableSelection"
      >
        Manual address
      </button>
    </div>

    <div
      class="address-tabs-wrapper__lookup-mode"
      *ngIf="selectionMode === 'lookup'"
    >
      <ng-content select="[lookup]"></ng-content>
    </div>

    <div
      class="address-tabs-wrapper__manual-mode"
      *ngIf="selectionMode === 'manual'"
    >
      <ng-content select="[manual]"></ng-content>
    </div>
  </div>
</div>
