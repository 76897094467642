import { Injectable } from '@angular/core';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';
import { ComponentStore } from '@ngrx/component-store';
import { AuthFacade } from '@shared/data-access-auth';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { CookieManagerService } from '../cookie-manager/cookie-manager.service';
import { JwtManagerService } from '../jwt-manager/jwt-manager.service';
import { RefreshTokenService } from '../refresh-token/refresh-token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService extends ComponentStore<never> {
  // ****** Signals (Trigger a state change or a side effect, but do not end in the state)
  private jwtUpdaterSubject = new Subject<boolean>();

  // *********** Updater Streams *********** //
  private jwtUpdaterHandler$: Observable<User | undefined> =
    this.jwtUpdaterSubject.pipe(
      filter(() => this.cookieManagerService.hasAuthenticationCookies()),
      map(() => this.jwtManagerService.decryptJWT()),
      filter((jwtDecoded) => jwtDecoded !== undefined)
    );

  // *********** Effects *********** //

  readonly updateAuthStateFromCookie = this.effect(
    (decryptJWT$: Observable<User | undefined>) =>
      decryptJWT$.pipe(
        tap((jwt) =>
          jwt
            ? this.authFacade.setAuthStateFromCookieLoggedIn(<User>jwt)
            : this.authFacade.setAuthStateFromCookieLoggedOut()
        )
      )
  )(this.jwtUpdaterHandler$);

  constructor(
    private jwtManagerService: JwtManagerService,
    private refreshTokenService: RefreshTokenService,
    private cookieManagerService: CookieManagerService,
    private authFacade: AuthFacade
  ) {
    super();
  }

  setUpdateAuthState() {
    this.jwtUpdaterSubject.next(true);
  }
}
