import { Injectable } from '@angular/core';
import {
  CorrespondenceAddress,
  DirectDebit,
  PaymentDetails,
  Person,
  PersonalDetails,
  YourDetails,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as UserProfilePageSelectors from './user-profile-page.selectors';
import * as UserProfileActions from './user-profile.actions';
import { UserProfilePartialState } from './user-profile.reducer';
import * as UserProfileSelectors from './user-profile.selectors';

@Injectable()
export class UserProfileFacade {
  yourDetails$: Observable<YourDetails> = this.store.pipe(
    select(UserProfilePageSelectors.getYourDetails)
  );

  directDebitDetailsValid$: Observable<boolean> = this.store.pipe(
    select(UserProfileSelectors.getDirectDebitDetailsValid)
  );

  getLoggedInAddress$: Observable<CorrespondenceAddress> = this.store.select(
    UserProfilePageSelectors.getAddress
  );

  personalDetails$: Observable<PersonalDetails> = this.store.pipe(
    select(UserProfilePageSelectors.personalDetails)
  );

  personDetails$: Observable<Person | undefined> = this.store.select(
    UserProfileSelectors.getPersonalDetails
  );

  paymentDetails$: Observable<PaymentDetails> = this.store.pipe(
    select(UserProfilePageSelectors.getPaymentDetails)
  );

  isPaperlessPlanDocumentPreferenceSet$: Observable<boolean> = this.store.pipe(
    select(UserProfileSelectors.isPaperlessPlanDocumentPreferenceSet)
  );

  constructor(private store: Store<UserProfilePartialState>) {}

  loginSuccess() {
    this.store.dispatch(UserProfileActions.loginSuccess());
  }

  proceedCheckoutAsGuest() {
    this.store.dispatch(UserProfileActions.checkoutAsGuest());
  }

  saveNewDirectDebitDetails(newDirectDebitDetails: DirectDebit | null) {
    this.store.dispatch(
      UserProfileActions.updateDirectDebit({ newDirectDebitDetails })
    );
  }

  changePaymentDay(paymentDay: number) {
    this.store.dispatch(UserProfileActions.changePaymentDay({ paymentDay }));
  }

  savePlanDocumentPreference(byPost: boolean) {
    this.store.dispatch(
      UserProfileActions.updatePlanDocumentPreference({ paperless: !byPost })
    );
  }
}
