import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import { UiBannerComponent } from './ui-banner/ui-banner.component';

@NgModule({
  imports: [CommonModule, DgxComponentsIconModule],
  declarations: [UiBannerComponent],
  exports: [UiBannerComponent],
})
export class UiBannerComponentModule {}
