import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { combineLatestObj, filterNullUndefined } from '@domgen/dgx-fe-common';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  Address,
  AddressItem,
  AddressListDef,
  ListBoxDef,
  SelectionMode,
} from '../../_shared/interfaces';
import { AddressListStateHelpersService } from './address-list-state-helpers.service';

const addressFormControlName = 'address';
const manualAddressFormControlName = 'manualAddress';

export interface AddressListState {
  addressForm: UntypedFormGroup;
  listboxControl: UntypedFormControl;
  fieldDef?: AddressListDef;
  controlName: string;
  listBoxDef?: ListBoxDef;
  addressItems?: AddressItem[];
  formControl?: AbstractControl;
  mode: SelectionMode;
  listSelectedIndex: number;
}

export const initialState: AddressListState = {
  addressForm: new UntypedFormGroup({
    [addressFormControlName]: new UntypedFormControl(undefined),
    [manualAddressFormControlName]: new UntypedFormControl(undefined, [
      (control) => {
        if (!control.value || !control.value.line1) {
          return {
            'invalid-address': true,
          };
        }
        return null;
      },
    ]),
  }),
  listboxControl: new UntypedFormControl(undefined),
  fieldDef: undefined,
  controlName: addressFormControlName,
  listBoxDef: undefined,
  addressItems: undefined,
  formControl: undefined,
  mode: SelectionMode.Lookup,
  listSelectedIndex: -1,
};

export interface AddressListStateViewModel {
  addressForm: UntypedFormGroup;
  controlName: string;
  fieldDef: AddressListDef;
  listboxControl: UntypedFormControl;
  listBoxDef?: ListBoxDef;
  mode: SelectionMode;
  noAddressItemsFound: boolean;
}

@Injectable()
export class AddressListStateService extends ComponentStore<AddressListState> {
  addressTextValue$ = new BehaviorSubject<string[]>([]);

  // ************  Selectors **********
  private readonly fieldDef$ = this.select(
    (state: AddressListState) => state.fieldDef
  ).pipe(filter((fieldDef) => !!fieldDef)) as Observable<AddressListDef>;

  private readonly listBoxDef$ = this.select(
    (state: AddressListState) => state.listBoxDef
  ).pipe(filter((listBoxDef) => !!listBoxDef));

  private readonly postCode$ = this.select(
    (state: AddressListState) => state.fieldDef
  ).pipe(
    filter((fieldDef) => !!fieldDef),
    map((fieldDef) => fieldDef?.initialValue?.postcode),
    filter((postCode) => !!postCode)
  ) as Observable<string>;

  private readonly addressItems$ = this.select(
    (state: AddressListState) => state.addressItems
  ).pipe(filterNullUndefined());

  private readonly noAddressItemsFound$ = this.select(
    (state: AddressListState) => state.addressItems
  ).pipe(
    filterNullUndefined(),
    map((addressItems: AddressItem[]) => addressItems.length === 0)
  );

  private readonly formControl$ = this.select(
    (state: AddressListState) => state.formControl
  ).pipe(filter((formControl) => !!formControl));

  private readonly addressForm$ = this.select(
    (state: AddressListState) => state.addressForm
  ).pipe(filter((addressForm) => !!addressForm));

  private readonly controlName$ = this.select(
    (state: AddressListState) => state.controlName
  ).pipe(filter((controlName) => !!controlName));

  private readonly listboxControl$ = this.select(
    (state: AddressListState) => state.listboxControl
  ).pipe(filter((listboxControl) => !!listboxControl));

  private readonly addressText$ = combineLatest([
    this.addressItems$,
    this.postCode$,
  ]).pipe(
    filter(([, postCode]) => !!postCode),
    map(([addressItems, postCode]) =>
      addressItems.map(({ Text }) => `${Text}, ${postCode}`)
    )
  );

  private readonly mode$ = this.select((state: AddressListState) => state.mode);
  private readonly listSelectedIndex$ = this.select(
    (state: AddressListState) => state.listSelectedIndex
  );

  // // ******** Public Updaters *********
  readonly setFieldDef = this.updater(
    (state: AddressListState, fieldDef: AddressListDef) => {
      return {
        ...state,
        fieldDef: {
          ...fieldDef,
          label: this.addressListStateHelpersService.updateLabel(fieldDef),
          hightlightedText: fieldDef?.initialValue?.postcode,
          optionsStream$: this.addressTextValue$,
          sanitise: fieldDef?.sanitise ?? 'block',
        },
      };
    }
  );

  readonly setFormControl = this.updater(
    (state: AddressListState, formControl: AbstractControl) => {
      return {
        ...state,
        formControl,
      };
    }
  );

  readonly setAddressItems = this.updater(
    (state: AddressListState, addressItems: AddressItem[]) => {
      return {
        ...state,
        addressItems,
      };
    }
  );

  readonly clearSelection = this.updater((state: AddressListState) => {
    if (state.listboxControl?.value) {
      state.listboxControl?.reset();
    }
    const control = state.addressForm.get(addressFormControlName);
    control?.setValue(null);
    state.listboxControl.markAsUntouched();
    return state;
  });

  readonly setMode = this.updater(
    (state: AddressListState, mode: SelectionMode) => {
      return {
        ...state,
        mode,
      };
    }
  );

  readonly setSelectedIndex = this.updater(
    (state: AddressListState, listSelectedIndex: number) => {
      return {
        ...state,
        listSelectedIndex,
      };
    }
  );
  readonly onTouched = this.updater((state: AddressListState) => {
    state.addressForm.markAllAsTouched();
    state.listboxControl.markAllAsTouched();
    return state;
  });

  // // ******** Private Updaters *********

  private readonly addressFormUpdater = this.updater(
    (state: AddressListState, address: Address | undefined | null) => {
      state.addressForm.get(addressFormControlName)?.setValue(
        address
          ? {
              ...address,
            }
          : undefined
      );
      return {
        ...state,
      };
    }
  );

  private readonly listBoxDefUpdater = this.updater(
    (state: AddressListState, fieldDef: AddressListDef) => {
      if (fieldDef.validators) {
        const validators = fieldDef.validators as ValidatorFn[];
        state.listboxControl.setValidators(validators);
        state.listboxControl.updateValueAndValidity();
      }
      return {
        ...state,
        listBoxDef: this.addressListStateHelpersService.getListBoxDef(fieldDef),
      };
    }
  )(this.fieldDef$);

  // // ********* Public Effects *********
  readonly setSelectedAddress = this.effect((payload$: Observable<number>) =>
    combineLatest([payload$, this.addressItems$]).pipe(
      map(([payload, addressItems]) => {
        return this.addressListStateHelpersService.getAddressIdFromAddressItemList(
          addressItems,
          payload
        );
      }),
      switchMap((addressId: string) =>
        this.addressListStateHelpersService.getRetrieveAddress(addressId)
      ),
      tap((address: Address) => {
        this.addressFormUpdater(address);
      })
    )
  );

  readonly addressFormValueChanges$ = this.addressForm$.pipe(
    map(
      (formGroup) => formGroup.get(addressFormControlName) as UntypedFormControl
    ),
    switchMap((addressFormControl) => addressFormControl.valueChanges),
    withLatestFrom(this.postCode$),
    map(([address, postcode]) => {
      if (!address) {
        return undefined;
      }
      return {
        ...address,
        postcode,
      };
    })
  ) as Observable<Address | undefined>;

  readonly manualAddressFormValueChanges$ = this.addressForm$.pipe(
    map(
      (formGroup) =>
        formGroup.get(manualAddressFormControlName) as UntypedFormControl
    ),
    switchMap((addressFormControl) => addressFormControl.valueChanges),
    withLatestFrom(this.postCode$, this.addressForm$),
    map(([address, postcode, form]) => {
      if (!form.get(manualAddressFormControlName)?.valid) {
        return null;
      }
      return {
        ...address,
        postcode,
      };
    })
  ) as Observable<Address | null>;

  // // ********* Private Effects *********
  private readonly searchAddresses$ = this.effect(
    (postCode$: Observable<string>) =>
      postCode$.pipe(
        switchMap((postcode) =>
          this.addressListStateHelpersService.getAddressByPostcode(postcode)
        ),
        withLatestFrom(this.postCode$),
        tap(([addresses, postcode]) => {
          this.setAddressItems(addresses);
          this.setMode(
            addresses.length ? SelectionMode.Lookup : SelectionMode.Manual
          );
          const addressText = addresses.map(
            ({ Text }) => `${Text}, ${postcode}`
          );
          this.addressTextValue$.next(addressText);
        })
      )
  )(this.postCode$);

  private readonly addressFormSetValueEffect$ = this.effect(
    (data$: Observable<[SelectionMode, number, Address | null]>) =>
      data$.pipe(
        tap(([mode, selectedIndex, manualAddress]) => {
          if (mode === SelectionMode.List || mode === SelectionMode.Lookup) {
            if (selectedIndex === -1) {
              this.clearSelection();
            } else {
              this.setSelectedAddress(selectedIndex);
            }
          } else {
            this.addressFormUpdater(manualAddress);
          }
        })
      )
  )(
    combineLatest([
      this.mode$,
      this.listSelectedIndex$.pipe(startWith(-1)),
      this.manualAddressFormValueChanges$.pipe(startWith(null)),
    ])
  );

  // ************ Selectors **********
  readonly vm$ = combineLatestObj<AddressListStateViewModel>({
    addressForm: this.addressForm$,
    controlName: this.controlName$,
    fieldDef: this.fieldDef$,
    listboxControl: this.listboxControl$,
    listBoxDef: this.listBoxDef$,
    mode: this.mode$,
    noAddressItemsFound: this.noAddressItemsFound$,
  });

  constructor(
    private addressListStateHelpersService: AddressListStateHelpersService
  ) {
    super(initialState);
  }
}
