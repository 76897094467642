<ui-panel *ngIf="panel" variant="highlight-info">
  <ui-panel-header>
    <ui-panel-title>
      {{ isHeating ? 'Plan' : 'Cover' }} start date
    </ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <ng-container
      [ngTemplateOutlet]="showPDContent ? contentPD : content"
    ></ng-container>
  </ui-panel-body>
</ui-panel>
<ng-container *ngIf="!panel">
  <ng-container
    [ngTemplateOutlet]="showPDContent ? contentPD : content"
  ></ng-container>
</ng-container>
<ng-template #contentPD>
  <div class="cover-start-date-information__container">
    <div *ngIf="!hasMultiQuotes">
      <p>
        Your cover will start after a 30 day wait. You'll see that start date in
        the certificate you receive after purchase. This policy provides
        continuous cover, so your monthly payments will continue for as long as
        you like.
      </p>
      <p>
        Your first payment will be taken on your chosen date or 14 days after we
        process your details, whichever one is later.
      </p>
    </div>
    <div *ngIf="hasMultiQuotes && showPDContent">
      <p>Your plumbing and drainage cover will start after a 30 day wait.</p>
      <p>
        If your appliance is in guarantee, its cover will start once we’ve
        processed your details. If it’s no longer in guarantee, the cover will
        start after a 30 day wait.
      </p>
      <p>
        All these start dates will be in the certificates you receive after
        purchase.
      </p>
      <p>
        Your policies provide continuous cover, so your monthly payments will
        continue for as long as you like.
      </p>
      <p>
        Your first payment will be taken on your chosen date or 14 days after we
        process your details, whichever one is later.
      </p>
    </div>
  </div>
</ng-template>
<ng-template #content>
  <p *ngIf="isHeating">
    If your appliance is in guarantee, the plan will start at the end of the
    guarantee. If your appliance is out of guarantee, the plan will start once
    we have processed your application. If your chosen payment date is within
    the next 14 days, your first and second payments will be taken at the same
    time next month.
  </p>
  <p *ngIf="!isHeating">
    {{ whiteGoodsCoverInformation }}<br /><br />
    The policies provide continuous cover, so payments will be taken monthly for
    as long as you like. If your chosen payment date is within the next 14 days,
    your first and second payments will be taken at the same time next month.
  </p>
</ng-template>
