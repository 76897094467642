import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { Observable } from 'rxjs';
import { REFRESH_API_CONFIG } from '../../tokens/refresh-api.token';

@Injectable({
  providedIn: 'any',
})
export class RefreshTokenService {
  constructor(
    private readonly httpClient: HttpClient,
    @Optional()
    @Inject(REFRESH_API_CONFIG)
    private readonly refreshApi: string,
    private buildConfigService: BuildConfigService
  ) {}

  getRefreshToken(): Observable<string> {
    if (!this.refreshApi) {
      throw new Error(
        'getRefreshToken requires a provider for REFRESH_API_CONFIG <string> for refreshApi url'
      );
    }

    return this.httpClient.post<string>(
      this.refreshApi,
      {},
      {
        headers: {
          ...this.getHeaders(),
        },
        withCredentials: true,
      }
    );
  }

  private getHeaders() {
    return {
      ...(this.buildConfigService.config.wlClient
        ? { 'wl-client': this.buildConfigService.config.wlClient }
        : {}),
      ...(this.buildConfigService.config.catalogueStore
        ? { 'request-source': this.buildConfigService.config.catalogueStore }
        : {}),
    };
  }
}
