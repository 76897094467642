export enum TrustBoxType {
  MICRO = 'micro',
  SLIDER = 'slider',
  BOTH = 'both',
}

export interface TrustBoxTemplate {
  id: string;
  name: TrustBoxType;
}

export interface TrustBoxConfig {
  businessUnitId: string;
  templates: TrustBoxTemplate[];
}

export interface TrustBoxClientConfig {
  enabled?: boolean;
  type?: TrustBoxType;
  tags?: string;
}
