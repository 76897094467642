interface NavigationLinkBase {
  section?: string;
  icon?: string;
  iconAfterContent?: boolean;
  isHidden?: boolean;
  label: string;
  newWindow?: boolean;
  isHeading?: boolean;
  additionalNavigationLinks?: NavigationLink[];
}

export interface NavigationLinkExternal extends NavigationLinkBase {
  href: string;
  routerPath?: undefined;
}

// These undefined properties are to get around angular strict templates
// It blocks you defining them, but they technically exist in the union type so Angular is happy.
export interface NavigationLinkInternal extends NavigationLinkBase {
  routerPath: string;
  href?: undefined;
}

export type NavigationLink = NavigationLinkExternal | NavigationLinkInternal;

export interface NavigationLinks {
  home?: NavigationLink;
  products?: NavigationLink;
  repair?: NavigationLink;
  faq?: NavigationLink;
  profile?: NavigationLink;
  basket?: NavigationLink;
  myDetails?: NavigationLink;
  logout?: NavigationLink;
  myAccountHome?: NavigationLink;
  poweredBy?: NavigationLink;
}
