import { Injectable } from '@angular/core';
import { PaymentType } from '@common/util-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as SelectPaymentActions from './select-payment.actions';
import { SelectPaymentPartialState } from './select-payment.reducer';
import * as SelectPaymentSelectors from './select-payment.selectors';

@Injectable()
export class SelectPaymentFacade {
  selectedPayment$: Observable<PaymentType | undefined> = this.store.select(
    SelectPaymentSelectors.getSelectedPaymentType
  );

  constructor(private store: Store<SelectPaymentPartialState>) {}

  selectPaymentOption(paymentType: PaymentType): void {
    this.store.dispatch(
      SelectPaymentActions.selectPaymentOption({ paymentType })
    );
  }

  proceedToPayment(): void {
    this.store.dispatch(SelectPaymentActions.proceedToPayment());
  }
}
