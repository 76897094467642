<div class="checkout" *ngIf="!showForgotPassword">
  <h2 class="title">{{ cmsData?.registeredCheckout?.title }}</h2>
  <h3 class="sub-heading inline-flex">
    <i class="icon icon--xl icon-my-account"></i
    >{{ cmsData?.registeredCheckout?.subtitle }}
  </h3>
  <p class="description">
    {{ cmsData?.registeredCheckout?.description }}
  </p>
  <div class="checkout__login-area">
    <athome-myaccount-log-in
      [dgxQmSanitise]="'block'"
      (loginResponseSuccess)="onLoginSucceed()"
      (loginResponseError)="onLoginFailed()"
      (forgotPasswordLinkClicked)="onForgotPassword()"
    ></athome-myaccount-log-in>
  </div>
</div>
<div
  class="checkout__forgot-password"
  *ngIf="showForgotPassword && !isPasswordResponseSuccess"
>
  <h2 class="mb-6">Forgotten your password?</h2>
  <p class="description mb-6">
    Tell us your email address and we’ll send you an email with a link to reset
    your password.
  </p>
  <athome-myaccount-forgot-password
    [dgxQmSanitise]="'block'"
    (backToLoginButtonClicked)="backToLoginButtonClicked()"
    (forgotPasswordResponseSuccess)="handleForgotPasswordResponseSuccess()"
  ></athome-myaccount-forgot-password>
</div>
<div *ngIf="isPasswordResponseSuccess">
  <h2 class="mb-6">Check your inbox</h2>
  <p class="description mb-6">
    We’ve sent an email with password reset instructions to your address
  </p>
  <p class="description mb-6">
    If it doesn’t arrive soon, check your spam folder
  </p>
</div>
