import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { TrustBoxType } from '@common/util-models';
import {
  TrustPilotManager,
  TRUST_PILOT_MANAGER,
} from '@common/util-foundation';
import { TrustboxService } from './trustbox.service';

@Component({
  selector: 'sales-trustbox',
  templateUrl: './trustbox.component.html',
  styleUrls: ['./trustbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrustboxService],
})
export class TrustboxComponent implements AfterViewInit {
  @ViewChild('microRef', { static: false }) microRef: ElementRef | undefined;
  @ViewChild('sliderRef', { static: false }) sliderRef: ElementRef | undefined;
  TrustBox = TrustBoxType;
  type: TrustBoxType = TrustBoxType.MICRO;
  templateId = '';
  businessUnitId = '';

  @Input() set trustBoxType(type: TrustBoxType) {
    if (type) {
      this.type = type;
      this.businessUnitId = this.trustBoxFacade.getBusinessId();
      this.templateId = this.trustBoxFacade.getTemplateIdByType(type);
    }
  }

  @Input() tagsFilter? = '';

  constructor(
    @Inject(TRUST_PILOT_MANAGER) private trustPilot: TrustPilotManager,
    private trustBoxFacade: TrustboxService
  ) {}

  ngAfterViewInit() {
    const trustboxRef =
      this.type === TrustBoxType.MICRO
        ? this.microRef?.nativeElement
        : this.sliderRef?.nativeElement;
    this.trustPilot.loadFromElement(trustboxRef);
  }
}
