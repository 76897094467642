import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getRequestSourceSuffix } from '@common/util-foundation';
import { WhyGetProtectedPathType } from '@common/util-models';

@Component({
  selector: 'sales-why-get-protected',
  templateUrl: './why-get-protected.component.html',
  styleUrls: ['./why-get-protected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhyGetProtectedComponent {
  @Input() set areaName(setAreaName: WhyGetProtectedPathType | null) {
    if (setAreaName) {
      this.area = setAreaName;
    }
  }
  @Input() set clientName(setClientName: string | null | undefined) {
    if (setClientName) {
      this.client = setClientName;
    }
  }

  client = 'Domestic & General';
  area: WhyGetProtectedPathType = getRequestSourceSuffix().includes('DISC10')
    ? 'why_get_protected_DISC10'
    : 'why_get_protected';
}
