import {
  CardPaymentRedirectResponse,
  ContractType,
  CreateQuoteResponse,
  ItemType,
  PaymentType,
} from '@common/util-models';

export const MOCK_CREATE_QUOTE_RESPONSE = {
  requestedItemId: '2',
  basket: {
    basketId: '1',
    created: '2020-12-08T10:39:12.022Z',
    updated: '2020-12-08T10:39:12.557Z',
    expiry: '2020-12-15T10:39:13.000Z',
    status: 'ACTIVE',
    requestSource: 'DandGUK',
    items: [
      {
        requestSource: 'DandGUK',
        basketId: '1',
        itemId: '1',
        itemType: ItemType.Quote,
        created: '2020-12-08T10:39:12.557Z',
        updated: '2020-12-08T10:39:12.557Z',
        expiry: '2020-12-08T13:27:13.000Z',
        status: 'ACTIVE',
        data: {
          quotes: [
            {
              quoteId: '1',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              coverType: 'STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 8,
                  subsequentPayment: 8,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 96,
                },
              ],
            },
            {
              quoteId: '2',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              coverType: 'G-STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 10,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 10,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 120,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 120,
                },
              ],
            },
            {
              quoteId: '3',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              coverType: 'P-STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 144,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 144,
                },
              ],
            },
          ],
          applianceDetails: {
            purchaseMonth: 3,
            purchaseYear: 2018,
            purchasePrice: 200,
            applianceCode: 'AW',
            applianceName: 'Washing Machine',
            applianceIcon:
              'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
            applianceImage:
              'https://sit-cms.domesticandgeneral.com/dam/jcr:1804b857-53c9-4315-99f9-e0161b00c737/placeholder-why-get-protected.png',
            brandCode: 'HOTP',
            brandName: 'Hotpoint',
            warranty: 12,
            inWarranty: true,
          },
        },
      },
      {
        requestSource: 'DandGUK',
        basketId: '1',
        itemId: '2',
        itemType: ItemType.Quote,
        created: '2020-12-08T11:39:12.557Z',
        updated: '2020-12-08T11:39:12.557Z',
        expiry: '2020-12-08T14:27:13.000Z',
        status: 'ACTIVE',
        data: {
          quotes: [
            {
              quoteId: '4',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              coverType: 'G-STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 10,
                  subsequentPayment: 10,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 120,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  fee: 120,
                },
              ],
            },
            {
              quoteId: '5',
              contractTypeCode: ContractType.Insurance,
              productType: 'I',
              companyCode: 'D',
              schemeCode: 'VU6',
              paymentTerm: 'IPM',
              isBundle: false,
              coverType: 'P-STD',
              breakdownStartDate: '2021-01-07',
              periodOfCover: 0,
              waitDays: 30,
              excessAmount: 0,
              paymentOptions: [
                {
                  preferredPayment: true,
                  paymentType: 'D',
                  paymentProfile: 'P',
                  firstPayment: 12,
                  subsequentPayment: 12,
                  numPayments: 11,
                  numSubsequentPayments: 10,
                  paymentFrequency: 1,
                  fee: 144,
                },
                {
                  preferredPayment: true,
                  paymentType: 'C',
                  paymentProfile: 'P',
                  firstPayment: 0,
                  subsequentPayment: 0,
                  numPayments: 1,
                  paymentFrequency: 1,
                  excessAmount: 0,
                  fee: 144,
                },
              ],
            },
          ],
          applianceDetails: {
            purchaseMonth: 3,
            purchaseYear: 2018,
            purchasePrice: 200,
            applianceCode: 'E7',
            applianceName: 'Condensing Boiler',
            brandCode: 'WR',
            brandName: 'Worcester',
            warranty: 12,
            inWarranty: true,
          },
        },
      },
    ],
  },
} as CreateQuoteResponse;

export const MOCK_CARD_PAYMENT_REDIRECT_RESPONSE = {
  client_reference_number: 'Reference Number',
  order_number: 'Order Number',
  status: 'PENDING',
  url: 'foo',
} as CardPaymentRedirectResponse;

export const MOCK_GET_PERSON_DETAILS = {
  person: {
    isLandlord: true,
    personalDetails: {
      title: 'MISS',
      initial: 'S',
      firstName: 'SHAREEN L W',
      surname: 'EVMOBN-AAAB',
      gender: '2',
    },
    marketingPreferences: {
      email: true,
      post: true,
      telephone: true,
    },
    correspondenceAddress: {
      addressLine1: '28 ROSEBERY GARDENS',
      addressLine2: 'BELFAST',
      addressLine3: '',
      addressLine4: '',
      postalCode: 'BT6 8JG',
      countryCode: 'GBR',
    },
    contact: {
      email: 'ILUVSNAKE+60@GMAIL.COM',
      mobilePhoneNumber: '+447531111111',
      landlinePhoneNumber: '+447111111111',
    },
  },
};

export const MOCK_GUEST_USER_DETAILS = {
  title: 'Mr.',
  firstName: 'Joe',
  lastName: 'Bloggs',
  email: 'test@test.com',
  mobileNumber: '0897878888',
  address: {
    line1: '10 Downing Street',
    line2: '',
    city: 'London',
    county: 'London',
    postcode: 'N1 1N',
  },
  paperlessPlanDocuments: true,
  marketingPreferences: {
    email: true,
    post: false,
    telephone: false,
  },
  directDebitDetails: {
    accountName: 'J B Williams',
    accountNumber: '12345678',
    monthlyPaymentDay: 2,
    sortCode: '12-34-56',
  },
};

export const MOCK_CHECKOUT_BASKET = {
  basketId: 'basketId',
  items: [
    {
      itemId: 'itemId',
      itemType: ItemType.Quote,
      quoteId: 'quoteId',
      paymentOptions: {
        directDebit: {
          preferredPayment: true,
          paymentType: 'D' as PaymentType,
          paymentProfile: 'P',
          firstPayment: 12,
          subsequentPayment: 12,
          numPayments: 11,
          paymentFrequency: 1,
          fee: 140,
        },
        card: {
          preferredPayment: true,
          paymentType: 'C' as PaymentType,
          paymentProfile: 'P',
          firstPayment: 0,
          subsequentPayment: 0,
          numPayments: 1,
          paymentFrequency: 1,
          fee: 140,
        },
      },
      contractType: ContractType.Insurance,
      companyCode: 'companyCode',
      schemeCode: 'schemeCode',
    },
  ],
};
