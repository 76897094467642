import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'dgx-uib-expanded-covers-exclusions',
  templateUrl: './expanded-covers-exclusions.component.html',
  styleUrls: ['./expanded-covers-exclusions.component.scss'],
})
export class ExpandedCoversExclusionsComponent {
  @Input() displayAs: 'tabs' | 'stacked' = 'tabs';
  @Input() customTickIcon = '';
  @Input() customCrossIcon = '';

  @Input() includedTitle = `What is covered`;
  @Input() included: string[] = [];
  @Input() excludedTitle = `What is not covered`;
  @Input() excluded: string[] = [];
  @Input() deviceExcluded: string | undefined;

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();
}
