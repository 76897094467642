import { GoogleTagConfig } from '@common/util-models';

export const GA_TAGS_MOCK: GoogleTagConfig = {
  checkoutSteps: {
    checkoutLanding: 1,
    quote: 1,
    personalDetails: 2,
    loggedIn: 3,
    selectPayment: 3,
    paymentDetails: 3,
    paymentDetailsSummary: 4,
  },
  dimensions: {
    brandName: 'dimension100',
    paymentType: 'dimension24',
    companyCode: 'dimension2',
    applianceName: 'dimension65',
    applianceDetailsValues: 'dimension68',
    excess: null,
    reference: 'dimension69',
    coverType: 'dimension70',
    paymentNumber: 'dimension109',
    fee: 'metric15',
    schemeCode: null,
    purchaseMonth: null,
    purchaseYear: null,
    purchasePrice: null,
    lt: null,
    ltPrice: null,
  },
  config: {
    showLegacyTags: true,
  },
  checkoutLoggedInUser: {
    event: 'salesGA',
    pagename: 'payment - speedy',
    genericpagename: 'checkout',
    category: 'sales-quote',
    eventSales: 'eecCheckout',
  },
  applianceDetailsLandingTags: {
    category: 'sales-quote',
    pagename: 'get-protected-appliance-details',
    event: 'sales-pageview',
  },
  paymentDetailsSummaryCommonData: {
    event: 'salesGA',
  },
  quoteLandingTags: {
    event: 'salesGA',
    eventSales: 'eecCheckoutButton',
    pagename: 'quote-gen',
    genericpagename: 'quote-gen',
  },
  checkoutLandingPage: {
    event: 'sales-pageview',
    eventSales: 'eecCheckoutOption',
    pagename: 'checkout-option',
    genericpagename: 'checkout-option',
    category: 'sales-quote',
  },
  checkoutPersonalDetails: {
    event: 'sales-pageview',
    pagename: 'policy-details-guest',
    category: 'sales-quote',
    checkoutOption: 'normal',
  },
  applianceFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceDetails',
    pagename: 'get-protected-appliance-details',
  },
  brandFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceDetails',
    pagename: 'get-protected-appliance-details',
  },
  workingOrderFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GoodWorkingOrder',
  },
  purchaseDateFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'PurchaseDate',
  },
  underGuaranteeFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceGuarantee',
  },
  guaranteeDurationFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'ApplianceGuaranteeLength',
  },
  purchasePriceFormData: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'PurchasePrice',
  },
  quotePriceData: {
    event: 'salesGA',
    eventCategory: 'sales-quote-price-impression',
    eventSales: 'quote-impression',
  },
  onApplianceFormSubmission: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GetQuote',
  },
  addItemToBasket: {
    event: 'eec.add',
  },
  addAnotherAppliance: {
    event: 'sales-pageview',
    pagename: 'add-another-appliance',
    category: 'sales-quote',
    genericpagename: 'goal-search',
  },
  proceedToCheckoutMultiBasket: {
    event: 'salesGA',
    eventCategory: 'Form - GoalSearch',
    eventSales: 'GetQuote',
    eventLabel: 'get-quote submitted',
  },
  deleteItemFromBasket: {
    event: 'salesGA',
    eventSales: 'remove-cart-x',
    eventCategory: 'remove-from-cart-interaction',
    eventAction: 'x click',
  },
  confirmItemDeletionFromBasket: {
    event: 'eec.remove',
  },
  cancelItemDeletionFromBasket: {
    event: 'salesGA',
    eventCategory: 'remove-from-cart-interaction',
    eventAction: 'cancel cta',
  },
  invalidApplianceFormSubmission: {
    event: 'salesGA',
    eventSales: 'errorForm',
    pagename: 'get-protected-appliance-details-error',
    eventCategory: 'Form - GoalSearch',
    eventAction: 'error',
  },
  boilerMultiItemReplace: {
    event: 'salesGA',
    eventSales: 'multi-item-boiler',
    pagename: 'get-protected-appliance-details',
    eventCategory: 'Form - GoalSearch',
  },
  personalDetailsFieldCompletionData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'completed',
  },
  personalDetailsCityCompletionData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
  },
  marketingPreferencesData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'marketing-preferences',
  },
  planDocumentPreferencesData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - PolicyDetails',
    eventAction: 'document plan',
    eventLabel: 'post',
  },
  checkoutSelectPaymentCommonData: {
    event: 'salesGA',
    eventSales: 'payment-options',
    pagename: 'payment-options',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutDirectDebitCommonData: {
    event: 'salesGA',
    eventSales: 'eecCheckout',
    pagename: 'payment',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutCardCommonData: {
    event: 'salesGA',
    eventSales: 'eecCheckout',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  paymentDetailsFormFieldCompletion: {
    event: 'salesGA',
    eventSales: 'eecCheckoutForm',
    eventCategory: 'Form - payment',
    eventAction: 'completed',
  },
  paymentDetailsFormBuyNowData: {
    event: 'salesGA',
    eventSales: 'eecCheckoutFormCTA',
    eventCategory: 'Form - payment',
    eventAction: 'buy now',
  },
  orderConfirmationTaggingData: {
    event: 'sales-pageview',
    pagename: 'order-confirmation',
    genericpagename: 'order-confirmation',
    category: 'sales-quote',
    quoteType: 'dg-qb',
  },
  inclusionExclusionsData: {
    eventCategory: 'quote-summary',
    eventAction: 'quote tab clicked',
  },
  quoteSummaryPage: {
    landing: {
      event: 'sales-pageview',
      pagename: 'quote-gen',
    },
    primaryCTA: {
      event: 'salesCheckout',
    },
    secondaryCTA: {
      event: 'salesGA',
      eventSales: 'add-another-cta',
      eventCategory: 'quote-summary',
      eventAction: 'add another appliance',
      eventLabel: 'CTA',
    },
  },
};
