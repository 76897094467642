<div class="pb-4">
  <ng-content> </ng-content>
</div>
<div class="tc-container">
  <div class="pt-4" *ngIf="field">
    <dgx-dfb-checkbox
      [field]="field"
      (checked)="onAcceptanceChange($event)"
      [validate]="validate"
    ></dgx-dfb-checkbox>
  </div>
</div>
