export interface AwinTrackedSale {
  amount: string;
  channel: string;
  // Setting click property to awc makes awin JS lib send this property as cks
  click?: string;
  currency: string;
  custom: string[];
  orderRef: string;
  parts: string;
  test: string;
  voucher?: string;
}
