export type NavigationRuleConfig = {
  /**
   * The step for which this rule should apply to
   *
   * @type {string}
   */
  target: string;
  /**
   * The steps that need to be completed before allowing navigation to the target
   *
   * @type {string[]}
   */
  stepsToComplete: string[];
  /**
   * The redirect Url to redirect to if the required steps ae not completed when there is a navigation to target
   *
   * @type {string}
   */
  redirectUrl?: string;

  /**
   * Indicates if we need to disable navigation on back button even if the steps are complete
   *
   * @type {boolean}
   */
  disableNavigationForBackButton?: boolean;
};

export interface NavigationConfig {
  /**
   * This is the default redirect Url that will be used if there is no
   * redirect Url specified in the NavigationRuleConfig
   *
   * @type {string}
   * @memberof NavigationConfig
   */
  defaultRedirectUrl: string;

  /**
   * These are the collection of navigation rules
   *
   * @type {NavigationRuleConfig[]}
   * @memberof NavigationConfig
   */
  navigationRules: NavigationRuleConfig[];
}
