import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { WorldPayCardPaymentOptions } from '../../_shared/interfaces/world-pay-card-payment.types';
import { WorldPayCardPaymentStateService } from './world-pay-card-payment-state.service';

@Component({
  selector: 'dgx-dfb-world-pay-card-payment',
  templateUrl: './world-pay-card-payment.component.html',
  styleUrls: ['./world-pay-card-payment.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorldPayCardPaymentStateService],
})
export class WorldPayCardPaymentComponent implements ControlValueAccessor {
  @Input() set paymentOptions(value: WorldPayCardPaymentOptions | null) {
    if (value) {
      this.worldpayCardPaymentStateService.updatePaymentOptions(value);
    }
  }

  /**
   * Allows interaction with the underlying world pay card payment provider
   */
  @Output() api = this.worldpayCardPaymentStateService.api$;

  /**
   * The payment processing response
   */
  @Output() paymentResponse =
    this.worldpayCardPaymentStateService.paymentResponse$;

  constructor(
    @Optional() ngControl: NgControl,
    private readonly worldpayCardPaymentStateService: WorldPayCardPaymentStateService
  ) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  /**
   * We dont support writing values to a card payment IFrame at the moment. Although there is an API from
   * worldpay to do this, we dont have a need for this
   * @returns {void}
   * @memberof WorldPayCardPaymentComponent
   */
  writeValue(): void {
    return undefined;
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.worldpayCardPaymentStateService.saveOnChangeReference(fn);
  }

  registerOnTouched(fn: () => void) {
    this.worldpayCardPaymentStateService.saveOnTouchedReference(fn);
  }
}
