import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, startWith, switchAll, takeUntil } from 'rxjs/operators';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import {
  OptionItem,
  SelectDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

let nextId = 1;

@Component({
  selector: 'dgx-dfb-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class SelectComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
  @Input() field!: SelectDef;
  @Input() validate!: boolean;
  @Input() hasPlumbingAndDrainage?: boolean | null;
  id = `select-${nextId++}`;
  loadingStreams$ = new BehaviorSubject<Observable<boolean>>(of(false));

  value!: string;
  blurred = false;
  disabled$ = of(false);
  loading$ = this.loadingStreams$.pipe(switchAll());
  private destroyed$: ReplaySubject<void> = new ReplaySubject();
  options: (string | OptionItem)[] = [];
  disabled = false;

  ngOnChanges(changes: SimpleChanges) {
    if (this.field?.disabledStream$) {
      this.disabled$ = this.field.disabledStream$;
    }
    if (this.field?.loadingStream$) {
      this.loadingStreams$.next(this.field.loadingStream$);
    }
    if (this.field?.optionsStream$) {
      this.field.optionsStream$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((options) => {
          this.options = options;
        });
    } else if (this.field?.options && this.field?.options?.length) {
      this.options = this.field.options;
    }
    return super.ngOnChanges(changes);
  }

  constructor(
    @Optional() public ngControl: NgControl,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscribeToControlChanges();
    this.clearInitialValue();
    this.bindControlValue();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string, emitAnalytics: boolean = false): void {
    this.value = val;
    super.writeValue(val, emitAnalytics);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  onChange(value: string, emitAnalytics: boolean = false) {
    const placeholder = this.field?.placeholder;
    if (placeholder && placeholder === value) {
      return;
    }
    this.writeValue(value, emitAnalytics);
  }

  clearInitialValue() {
    const { initialValue } = this.field;
    if (initialValue) {
      this.value = initialValue || '';
      this.field.initialValue = '';
    }
  }

  private subscribeToControlChanges(): void {
    this.ngControl?.control?.statusChanges
      .pipe(
        filter((status: string) => status === 'VALID'),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => super.updateErrorMessage());

    this.ngControl?.control?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  private bindControlValue(): void {
    this.ngControl?.control?.valueChanges
      .pipe(
        startWith(this.ngControl?.control?.value),
        filter((value: string) => value !== this.value),
        takeUntil(this.destroyed$)
      )
      .subscribe((value: string) => {
        this.value = value;
        this.ngControl?.control?.markAsUntouched();
        super.updateErrorMessage();
        this.changeDetectorRef.detectChanges();
      });
  }
}
