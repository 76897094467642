import { UntypedFormGroup } from '@angular/forms';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { resetFormControls } from '../../utils/form/form.utils';

export function resetControls(
  ...controlKeys: string[]
): MonoTypeOperatorFunction<UntypedFormGroup | null | undefined> {
  return pipe(
    tap((form: UntypedFormGroup | null | undefined) =>
      resetFormControls(form, ...controlKeys)
    )
  );
}
