import { createSelector } from '@ngrx/store';
import {
  QuotesPageSelectors,
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import {
  BasketItem,
  PaymentType,
  Quote,
  QuoteTaggingData,
  FeeAndPaymentData,
  CoverPaymentOptions,
} from '@common/util-models';

export const getQuoteId = createSelector<
  QuotesPartialState,
  [BasketItem | undefined],
  string | null
>(
  QuotesSelectors.getCurrentItem,
  (item: BasketItem | undefined) => item?.basketId || null
);

export const getCustomerId = createSelector(() => '');

export const getDigitalId = createSelector(() => '');

export const getCustomerType = createSelector(() => 'G');

export const getPaymentInformation = createSelector<
  QuotesPartialState & SelectPaymentPartialState,
  [Quote | undefined, PaymentType | undefined],
  FeeAndPaymentData
>(
  QuotesPageSelectors.getQuote,
  SelectPaymentSelectors.getSelectedPaymentType,
  (quote, selectedPayment) => {
    const paymentOptions: CoverPaymentOptions | undefined =
      quote?.cover.paymentOptions;
    return {
      paymentType: selectedPayment,
      paymentTerm: quote?.cover.paymentTerm,
      fee:
        (selectedPayment === PaymentType.Card
          ? paymentOptions?.card?.fee
          : paymentOptions?.directDebit?.subsequentPayment) || null,
      total:
        (selectedPayment === PaymentType.Card
          ? paymentOptions?.card?.fee
          : paymentOptions?.directDebit?.fee) || null,
    };
  }
);

export const getOrderDetails = createSelector(
  QuotesPageSelectors.getTaggingMetadata,
  SelectPaymentSelectors.getSelectedPaymentType,
  getPaymentInformation,
  (taggingMetadata, selectedPaymentType, paymentInformation) => {
    if (taggingMetadata && selectedPaymentType && paymentInformation) {
      return `${taggingMetadata.brandName} ${taggingMetadata.applianceName}|${
        selectedPaymentType === PaymentType.Card
          ? 'Credit/Debit Card'
          : 'Direct Debit'
      }|${paymentInformation.fee}|`;
    }
    return null;
  }
);

export const getCompanyType = createSelector(
  QuotesPageSelectors.getTaggingMetadata,
  (taggingMetadata) => taggingMetadata?.companyCode || null
);

export const getName = createSelector(
  QuotesPageSelectors.getTaggingMetadata,
  (taggingMetadata) =>
    taggingMetadata
      ? `${taggingMetadata?.brandName} ${taggingMetadata.applianceName}`
      : null
);

export const getApplianceName = createSelector(
  QuotesPageSelectors.getTaggingMetadata,
  (taggingMetadata) => (taggingMetadata ? taggingMetadata.applianceName : null)
);

export const getBrandName = createSelector(
  QuotesPageSelectors.getTaggingMetadata,
  (taggingMetadata) => (taggingMetadata ? taggingMetadata.brandName : null)
);

export const getTaggingData = createSelector(
  getQuoteId,
  getCustomerId,
  getDigitalId,
  getCustomerType,
  getPaymentInformation,
  getOrderDetails,
  QuotesPageSelectors.getTaggingMetadata,
  QuotesSelectors.getSelectedCoverName,
  (
    quoteId,
    customerId,
    digitalId,
    customerType,
    paymentInformation,
    orderDetails,
    taggingMetadata,
    coverName
  ) => {
    return {
      quoteId,
      customerId,
      digitalId,
      customerType,
      price: paymentInformation.fee,
      total: paymentInformation.total,
      orderDetails,
      companyType: taggingMetadata?.companyCode || null,
      name: taggingMetadata
        ? `${taggingMetadata?.brandName} ${taggingMetadata.applianceName}`
        : null,
      coverName,
      applianceName: taggingMetadata ? taggingMetadata.applianceName : null,
      brandName: taggingMetadata ? taggingMetadata.brandName : null,
      schemeCode: taggingMetadata ? taggingMetadata.schemeCode : null,
      paymentType: paymentInformation.paymentType,
      paymentTerm: paymentInformation.paymentTerm,
    } as QuoteTaggingData;
  }
);
