<ui-link
  *ngIf="navigationLink.href?.length !== 0; else justLogo"
  [navigationLink]="navigationLink"
  (click)="onClick($event)"
>
  <ng-container *ngTemplateOutlet="logo"></ng-container>
</ui-link>

<ng-template #justLogo>
  <ng-container *ngTemplateOutlet="logo"></ng-container>
</ng-template>

<ng-template #logo>
  <span class="powered-by-text">Powered by</span>
  <img
    class="powered-by-logo"
    src="assets/images/dg-logo-colour.png"
    role="img"
    alt="Domestic & General"
  />
</ng-template>
