import { Validators } from '@angular/forms';
import {
  CheckboxToggleDef,
  postCodeMismatchMessage,
} from '../../_shared/interfaces';
import { AddressFieldNames } from '../../_shared/interfaces/address-lookup.interface';
import {
  ControlType,
  TextInputDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';

const regexPatternForAddressFields =
  "^[0-9A-Za-z-,.&\\/' s\u00e4\u00f6\u00fc\u00df\u00f9\u00fb\u00fc\u00ff\u00e0\u00e2\u00e6\u00e7\u00e9\u00e8\u00ea\u00eb\u00e9\u00eb\u00ef\u00f3\u00f6\u00fc\u00e0\u00e8\u00e9\u00ec\u00f2\u00f3\u00f9\u0105\u0107\u0119\u0142\u0144\u00f3\u015b\u017a\u017c\u00e3\u00e1\u00e0\u00e2\u00e7\u00e9\u00ea\u00ed\u00f5\u00f3\u00f4\u00fa\u00fc\u00e1\u00e9\u00ed\u00f1\u00f3\u00fa\u00fc]*$";

export const addressLine1FieldDef: TextInputDef = {
  controlName: AddressFieldNames.Line1,
  controlType: ControlType.INPUT,
  validators: [
    Validators.required,
    Validators.pattern(regexPatternForAddressFields),
  ],
  validationMessages: [
    { type: 'required', message: 'Address line 1 is required' },
    {
      type: 'pattern',
      message: 'Your home address contains restricted characters',
    },
  ],
  label: { text: 'Home address line 1' },
  initialValue: '',
  placeholder: 'Home address line 1',
  type: 'text',
  sanitise: 'block',
};

export const addressLine2FieldDef: TextInputDef = {
  controlName: AddressFieldNames.Line2,
  controlType: ControlType.INPUT,
  validators: [Validators.pattern(regexPatternForAddressFields)],
  validationMessages: [
    {
      type: 'pattern',
      message: 'Your home address contains restricted characters',
    },
  ],
  label: { text: 'Home address line 2' },
  initialValue: '',
  placeholder: 'Home address line 2',
  type: 'text',
  sanitise: 'block',
};

export const cityFieldDef: TextInputDef = {
  controlName: AddressFieldNames.City,
  controlType: ControlType.INPUT,
  validators: [
    Validators.required,
    Validators.pattern(regexPatternForAddressFields),
  ],
  validationMessages: [
    { type: 'required', message: 'Town/City is required' },
    {
      type: 'pattern',
      message: 'Your home address contains restricted characters',
    },
  ],
  label: { text: 'Town/City' },
  initialValue: '',
  placeholder: 'Town/City',
  type: 'text',
  sanitise: 'block',
};

export const countyFieldDef: TextInputDef = {
  controlName: AddressFieldNames.County,
  controlType: ControlType.INPUT,
  validators: [Validators.pattern(regexPatternForAddressFields)],
  validationMessages: [
    {
      type: 'pattern',
      message: 'Your home address contains restricted characters',
    },
  ],
  label: { text: 'County' },
  initialValue: '',
  placeholder: 'County',
  type: 'text',
  sanitise: 'block',
};

export const postcodeFieldDef: TextInputDef = {
  controlName: AddressFieldNames.Postcode,
  controlType: ControlType.INPUT,
  validators: [
    Validators.required,
    Validators.pattern(
      '[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]{0,1} ?[0-9][A-Za-z]{2}'
    ),
  ],
  validationMessages: [
    { type: 'required', message: 'Postcode is required' },
    {
      type: 'pattern',
      message: 'Enter a valid postcode',
    },
    postCodeMismatchMessage,
  ],
  label: { text: 'Postcode' },
  initialValue: '',
  placeholder: 'Postcode',
  type: 'text',
  sanitise: 'encrypt',
};

export const compulsoryCheckboxFieldDef: CheckboxToggleDef = {
  controlName: AddressFieldNames.Compulsory,
  controlType: ControlType.CHECKBOX,
  validators: [Validators.requiredTrue],
  validationMessages: [
    {
      type: 'required',
      message: 'Please answer this question to proceed',
    },
  ],
  label: { text: 'I confirm that this appliance is for domestic use only' },
  initialValue: false,
};

export const typeFieldDef: TextInputDef = {
  controlName: AddressFieldNames.Type,
  controlType: ControlType.INPUT,
  label: { text: 'type' },
  validators: [],
  validationMessages: [],
  initialValue: '',
  placeholder: 'type',
  type: 'text',
};
