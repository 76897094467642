import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureConfigPartialState } from '@common/data-access-feature-config';
import {
  QuotesActions,
  QuotesApiService,
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import { ErrorService } from '@common/util-foundation';
import { CreateQuoteResponse } from '@common/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delayWhen,
  filter,
  first,
  map,
  withLatestFrom,
} from 'rxjs/operators';
import * as ApplianceDetailsActions from './appliance-details.actions';
import { ApplianceDetailsPartialState } from './appliance-details.reducer';

@Injectable()
export class ApplianceDetailsEffects {
  createQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuote),
      map(() => QuotesActions.createQuote())
    )
  );

  createQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuoteSuccess),
      map(
        (action: {
          quoteResponse: CreateQuoteResponse;
          autoSubmitFlow?: boolean;
        }) =>
          QuotesActions.createQuoteSuccess({
            quoteApiResult: action.quoteResponse,
            autoSubmitFlow: action.autoSubmitFlow,
          })
      )
    )
  );

  createQuoteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuoteFailure),
      map((action: { error: HttpErrorResponse }) =>
        QuotesActions.createQuoteFailure({
          error: action.error,
        })
      )
    )
  );

  createApplianceQuoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuoteRequest),
      delayWhen(() => this.quoteRemoteStateUnstable$),
      map(({ formData, autoSubmitFlow, quoteRequest }) =>
        ApplianceDetailsActions.createQuote({
          formData,
          quoteRequest,
          autoSubmitFlow,
        })
      )
    )
  );

  createApplianceQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuote),
      withLatestFrom(this.store.pipe(select(QuotesSelectors.getBasketId))),
      concatMap(([action, basketId]) => {
        return this.quotesService
          .createQuote({ ...action.quoteRequest, basketId })
          .pipe(
            map((quoteResponse) => {
              return ApplianceDetailsActions.createQuoteSuccess({
                quoteResponse,
                formData: action.formData,
                autoSubmitFlow: action.autoSubmitFlow,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              this.handleError();
              return of(ApplianceDetailsActions.createQuoteFailure({ error }));
            })
          );
      })
    )
  );

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private readonly actions$: Actions,
    private readonly quotesService: QuotesApiService,
    private readonly errorService: ErrorService,
    private readonly store: Store<
      ApplianceDetailsPartialState &
        QuotesPartialState &
        FeatureConfigPartialState
    >
  ) {}

  private get quoteRemoteStateUnstable$(): Observable<boolean> {
    return this.store.pipe(
      select(QuotesSelectors.getQuotesRemoteStateUnstable),
      filter((unstable: boolean) => !unstable),
      first()
    );
  }
}
