import {
  BasketItemApplianceDetails,
  BasketItemQuoteDetails,
  ItemType,
} from './basket-item.types';
import { Basket } from './basket.types';
import { CustomerDetails } from './checkout-request.types';
import { Upsell } from './upsell.types';

export interface CreateQuoteResponse {
  requestedItemId: string;
  basket: Basket;
  requestedItemType: ItemType;
  customer?: CustomerDetails;
  upsell?: Upsell;
}

export interface CreateQuoteRequest {
  applianceCategory?: string;
  applianceCode: string;
  basketId?: string;
  brandCode: string;
  inWarranty?: boolean;
  isOwned?: boolean;
  itemType: ItemType.Quote;
  modelNumber?: string;
  purchaseMonth: number;
  purchasePrice: number;
  purchaseYear: number;
  serialNumber?: string;
  warranty?: number;
}

export interface GetOfferRenewalQuoteRequest {
  basketId?: string;
  itemType: ItemType.Offer | ItemType.Renewal;
  postalCode: string;
  reference: string;
}

export interface SelectedBasketItem {
  itemId: string;
  quoteId: string;
  productType?: string;
  isPlumbingAndDrainage?: boolean;
}

export interface SelectedBasketItemApplianceQuote {
  itemId: string;
  itemType: ItemType;
  applianceDetails: BasketItemApplianceDetails;
  quote: BasketItemQuoteDetails;
}
