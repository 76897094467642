import { Injectable } from '@angular/core';

export enum HeaderNames {
  REQUEST_SOURCE = 'request-source',
  REQUEST_ACTION = 'request-action',
  CLIENT = 'wl-client',
}

export enum OldAuthApiEnum {
  REFRESH = 'identity/refresh',
  REQUEST_PASSWORD_URL = 'identity/password/forgot',
  LOGIN_USER_URL = 'identity/signin',
}

export enum NewAuthApiEnum {
  REFRESH = 'refresh',
  REQUEST_PASSWORD_URL = 'forgot-password',
  LOGIN_USER_URL = 'signin',
}

@Injectable({
  providedIn: 'root',
})
export class AuthUrlManagerService {
  updateEndpoint(url: string) {
    if (url.includes(OldAuthApiEnum.LOGIN_USER_URL)) {
      return url.replace(
        OldAuthApiEnum.LOGIN_USER_URL,
        NewAuthApiEnum.LOGIN_USER_URL
      );
    } else if (url.includes(OldAuthApiEnum.REQUEST_PASSWORD_URL)) {
      return url.replace(
        OldAuthApiEnum.REQUEST_PASSWORD_URL,
        NewAuthApiEnum.REQUEST_PASSWORD_URL
      );
    } else if (url.includes(OldAuthApiEnum.REFRESH)) {
      return url.replace(OldAuthApiEnum.REFRESH, NewAuthApiEnum.REFRESH);
    } else {
      return url;
    }
  }
}
