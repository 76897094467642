import { BuildConfig } from '@common/util-models';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

const headerLinks: BuildConfig['header'] = {
  home: {
    href: '#',
    label: '',
    isHidden: false,
  },
  products: {
    routerPath: '/insurance',
    label: 'Get protected',
    isHidden: true,
  },
  repair: {
    href: '#',
    label: 'Book a repair',
    isHidden: true,
  },
  faq: {
    href: '#',
    label: 'Help and advice',
    isHidden: true,
  },
  profile: {
    href: '/myaccount',
    label: 'My account',
    icon: 'my-account',
    isHidden: true,
  },
  myDetails: {
    routerPath: '#',
    label: 'My details',
    isHidden: true,
  },
  logout: {
    routerPath: '',
    label: 'Logout',
    isHidden: true,
  },
  myAccountHome: {
    routerPath: '#',
    label: 'My plans',
    isHidden: true,
  },
  poweredBy: {
    href: 'https://www.domesticandgeneral.com',
    label: '',
    isHidden: false,
  },
};

const privacyLink: NavigationLink = {
  label: 'Privacy and Cookies policy',
  href: 'https://www.domesticandgeneral.com/privacy-cookies-policy',
  newWindow: true,
};
const footerLinks: NavigationLink[] = [
  {
    section: 'useful',
    label: 'Regulatory information',
    href: 'https://domesticandgeneral.com/shop/en/dg/company-information-legal',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Charity Partnerships',
    href: 'https://www.domesticandgeneral.com/content/contact-domestic-general/corporate-information/media-centre',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Modern Slavery Statement',
    href: 'https://domesticandgeneral.com/shop/en/dg/modern-slavery',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Part VII Transfer',
    href: 'https://domesticandgeneral.com/PartVIITransfer',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Rogue Trader Guidance',
    href: 'https://domesticandgeneral.com/content/help-advice-section/faq/received-a-strange-call-',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Customer Conduct',
    href: 'https://domesticandgeneral.com/customer-conduct',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Coronavirus update',
    href: 'https://domesticandgeneral.com/content/help-advice-section/faq/coronavirus-covid19-update',
    newWindow: true,
  },

  {
    section: 'legal',
    label: 'Website Terms and Conditions',
    href: 'https://www.domesticandgeneral.com/web-terms-conditions',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Privacy and Cookies policy',
    href: 'https://www.domesticandgeneral.com/privacy-cookies-policy',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Accessibility policy',
    href: 'https://www.domesticandgeneral.com/accessibility',
    newWindow: true,
  },
];
export const DEFAULT_ENVIRONMENT = {
  header: headerLinks,
  footer: {
    copyrightOwner: 'Domestic & General Group Limited',
    links: footerLinks,
    socialLinks: [
      {
        href: 'https://www.facebook.com/DandGUK',
        icon: 'facebook',
        label: 'Facebook',
      },
      {
        href: 'https://www.twitter.com/DandG_UK',
        icon: 'twitter',
        label: 'Twitter',
      },
      {
        href: 'https://www.instagram.com/domesticandgeneral',
        icon: 'instagram',
        label: 'Instagram',
      },
      {
        href: 'https://www.linkedin.com/company/domestic-&-general/mycompany',
        icon: 'linkedin',
        label: 'LinkedIn',
      },
    ],
  },
  links: {
    privacy: privacyLink,
  },
  dd_guarantee: 'https://www.domesticandgeneral.com/direct-debit-guarantee',
  wlClient: 'DandGUK',
  catalogueStore: 'EDFWGUK',
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  action: 'QandB',
  quotePage: '/quote',
  checkoutLandingPage: '/checkout',
  checkoutOrderConfirmationPage: '/checkout/order-confirmation',
  applianceDetailsPage: '/products',
  checkoutPersonalDetailsPage: '/checkout/personal-details',
  checkoutDirectDebitPage: '/checkout/direct-debit-payment',
  checkoutCardPaymentPage: '/checkout/card-payment',
  checkoutLoggedInUserPage: '/checkout/logged-in',
  selectPaymentPage: '/checkout/direct-debit-payment',
  forgotPasswordPage: '/forgot-password',
  cms_config: {
    pagesRoot: '/dgx-sales',
    insideCmsEditor: false,
    logCmsRequests: false,
    useCachedCmsOutput: false,
    useFallbackCmsContent: false,
    cachedRouteBase: 'assets/cached-cms/pages',
    cachedContentAppBase: 'assets/cached-cms/content-apps/',
  },
  cardPayment: {
    description: 'Sales',
    transactionSource: 'W',
    transactionType: 'W',
  },
  retryBackOffConfig: {
    maxRetries: 1,
    initialInterval: 3000,
    maxInterval: 3000,
  },
  postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
  postcodeServiceFind:
    'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
  postcodeServiceRetrieve:
    'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  worldpay: {
    customisation: {
      fonts: {
        family: ['Museo Sans', 'Source Sans Pro', 'Arial'],
      },
      color: '#414141',
      buttons: {
        backgroundColor: '#038ad3',
        color: '#fff',
        border: {
          style: 'solid',
          color: '#038ad3',
          width: '5px',
          radius: '2px',
        },
        hover: {
          backgroundColor: '#0071bb',
          border: {
            color: '#0071bb',
          },
        },
      },
      inputs: {
        backgroundColor: '#ffffff',
        border: {
          style: 'solid',
          width: '1px',
          radius: '2px',
          color: '#757575',
        },
        placeholder: {
          color: '#001',
        },
        validation: {
          ok: {
            color: '#228415',
          },
          error: {
            color: '#af2c2b',
          },
        },
      },
      page: {
        backgroundColor: '#fff',
        color: '#333',
        border: {
          style: 'solid',
          color: '#ddd',
          width: '1px',
          radius: '2px',
        },
      },
    },
  },
  backToHomeButton: {
    text: 'Back to EDF Website',
    href: 'https://www.edfenergy.com',
  },
  appRoot: '/',
  sessionInactivityDurationInMinutes: 20,
};
