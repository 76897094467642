import { KeysOfUnion, Normalize } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { normalize } from '../../utils/normalize/normalize.utils';

export function normalized<T>(
  identifier: KeysOfUnion<T>
): OperatorFunction<T[], Normalize<T>> {
  return pipe(
    filterNullUndefined(),
    map((items: T[]) => normalize(items, identifier))
  );
}
