import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  GlowShadowContainerModule,
  PictureComponentsModule,
} from '@domgen/dgx-fe-components-core';
import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import { FeatureHeroComponent } from './feature-hero/feature-hero.component';

@NgModule({
  imports: [
    CommonModule,
    DgxComponentsIconModule,
    GlowShadowContainerModule,
    PictureComponentsModule,
  ],
  declarations: [FeatureHeroComponent],
  exports: [FeatureHeroComponent],
})
export class FeatureHeroComponentModule {}
