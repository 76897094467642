export interface BrandResponse {
  brandName: string;
  brandCode: string;
  warranty: number;
}

export interface Brand {
  description: string;
  code: string;
  warranty?: number;
}
