import { Observable, OperatorFunction, pipe } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

/**
 * @deprecated Use `import { mapToLatestFrom } from '@domgen/dgx-fe-common';` instead.
 */
export function mapToLatestFrom<T>(
  source$: Observable<T>
): OperatorFunction<unknown, T> {
  return pipe(
    withLatestFrom(source$),
    map((values: [unknown, T]) => values.pop() as T)
  );
}
