import { Dictionary } from './ga-tagging.types';

export enum GaDimensions {
  brandName = 'brandName',
  paymentType = 'paymentType',
  companyCode = 'companyCode',
  applianceName = 'applianceName',
  applianceDetailsValues = 'applianceDetailsValues',
  reference = 'reference',
  coverType = 'coverType',
  excess = 'excess',
  fee = 'fee',
  schemeCode = 'schemeCode',
  paymentNumber = 'paymentNumber',
  purchaseMonth = 'purchaseMonth',
  purchaseYear = 'purchaseYear',
  purchasePrice = 'purchasePrice',
  lt = 'lt',
  ltPrice = 'ltPrice',
}
export enum GaCheckoutPages {
  personalDetails = 'personalDetails',
  checkoutLanding = 'checkoutLanding',
  loggedIn = 'loggedIn',
  quote = 'quote',
  selectPayment = 'selectPayment',
  paymentDetails = 'paymentDetails',
  paymentDetailsSummary = 'paymentDetailsSummary',
}

export type GaDimensionsConfig = {
  [key in GaDimensions]: string | null;
};
export type GaDimensionsValues = Partial<{
  [key in GaDimensions]: string | number | null;
}>;

export type GaCheckoutSteps = {
  [key in GaCheckoutPages]: number | null;
};

export interface GoogleTagConfig {
  dimensions: GaDimensionsConfig;
  checkoutSteps: GaCheckoutSteps;
  addAnotherAppliance?: Dictionary<string>;
  addItemToBasket?: Dictionary<string>;
  applianceDetailsLandingTags: Dictionary<string>;
  applianceFormData?: Dictionary<string>;
  backToWebSiteEvent?: Dictionary<string>;
  boilerMultiItemReplace?: Dictionary<string>;
  brandFormData?: Dictionary<string>;
  cancelItemDeletionFromBasket?: Dictionary<string>;
  checkoutCardCommonData?: Dictionary<string>;
  checkoutDirectDebitCommonData?: Dictionary<string>;
  checkoutLandingPage?: Dictionary<string>;
  checkoutLoggedInUser?: Dictionary<string>;
  checkoutPersonalDetails?: Dictionary<string>;
  checkoutSelectPaymentCommonData?: Dictionary<string>;
  config: {
    showLegacyTags: boolean;
  };
  confirmItemDeletionFromBasket?: Dictionary<string>;
  deleteItemFromBasket?: Dictionary<string>;
  excessSelection?: Dictionary<string>;
  guaranteeDurationFormData?: Dictionary<string>;
  inclusionExclusionsData: Dictionary<string>;
  invalidApplianceFormSubmission?: Dictionary<string>;
  marketingPreferencesData?: Dictionary<string>;
  onApplianceFormSubmission?: Dictionary<string>;
  onLoadCheckoutSelectPaymentData?: Dictionary<string>;
  onLoadErrorPageData?: Dictionary<string>;
  onOrderConfirmationPageLoad?: Dictionary<string>;
  orderConfirmationTaggingData?: Dictionary<string>;
  paymentDetailsAccountHolder?: Dictionary<string>;
  paymentDetailsCommonData?: Dictionary<string>;
  paymentDetailsFormBuyNowData?: Dictionary<string>;
  paymentDetailsFormFieldCompletion?: Dictionary<string>;
  paymentDetailsSummaryCommonData?: Dictionary<string>;
  paymentDetailsSummaryComplete?: Dictionary<string>;
  paymentDetailsSummaryBackToPaymentDetails?: Dictionary<string>;
  paymentSelectionEvent?: Dictionary<string>;
  personalDetailsCityCompletionData?: Dictionary<string>;
  personalDetailsFieldCompletionData?: Dictionary<string>;
  personalDetailsFormData?: Dictionary<string>;
  planDocumentPreferencesData?: Dictionary<string>;
  planSelection?: Dictionary<string>;
  proceedToCheckoutMultiBasket?: Dictionary<string>;
  purchaseDateFormData?: Dictionary<string>;
  purchasePriceFormData?: Dictionary<string>;
  quoteLandingTags: Dictionary<string>;
  quotePriceData?: Dictionary<string>;
  quoteSummaryPage?: {
    landing: Dictionary<string>;
    primaryCTA: Dictionary<string>;
    secondaryCTA: Dictionary<string>;
  };
  termsAndConditionsClickEvent?: Dictionary<string>;
  underGuaranteeFormData?: Dictionary<string>;
  workingOrderFormData?: Dictionary<string>;
  autoPlanRenewalData?: Dictionary<string>;
}
