import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { TrustBoxType } from '@common/util-models';

@Component({
  selector: 'sales-trust-pilot',
  templateUrl: './trust-pilot.component.html',
  styleUrls: ['./trust-pilot.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustPilotComponent {
  TrustBox = TrustBoxType;
  @Input() tagsFilter? = '';
}
