import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QueryParamsState, QUERY_PARAMS_KEY } from './query-params.reducer';

export const getQueryParamsState =
  createFeatureSelector<QueryParamsState>(QUERY_PARAMS_KEY);

export const getAWC = createSelector(
  getQueryParamsState,
  (queryParamsState: QueryParamsState) => queryParamsState?.awc
);

export const getStore = createSelector(
  getQueryParamsState,
  (queryParamsState: QueryParamsState) => queryParamsState?.store
);
