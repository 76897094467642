export interface CheckoutCmsData {
  guestCheckout: {
    bannerTitle: string;
    bannerText: string;
    title: string;
    subtitle: string;
    description: string;
    hint: string;
    buttonText: string;
  };
  registeredCheckout: {
    bannerTitle: string;
    bannerText: string;
    title: string;
    subtitle: string;
    description: string;
  };
}

export interface CheckoutCmsResult {
  '@id': string;
  '@name': string;
  '@nodeType': string;
  '@nodes': [];
  '@path': string;
  jcrName: string;
  bannerTitle: string;
  bannerText: string;
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  hint: string;
}

export interface CheckoutPageCmsData {
  results: CheckoutCmsResult[];
}
