import * as deepEqual from 'fast-deep-equal';
import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * @deprecated Use `import { distinctUntilChangedDeep } from '@domgen/dgx-fe-common';` instead.
 */
export function distinctUntilChangedDeep<T>(): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged((previous: T, current: T) => {
    return deepEqual(previous, current);
  });
}
