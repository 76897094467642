export const DEFAULT_ERROR_MESSAGES = {
  header: `Sorry, something's not right.`,
  support: `It looks like there's been a technical issue but we're working to fix it.`,
  customerServiceTitle: 'Please try again later or call our friendly team on:',
  helpNumber: '0800 561 4457',
  availabilityText: `Our lines are open:`,
  availabilityHours: [
    '8am - 8pm, Monday - Friday',
    '10am - 4pm, Saturday - Sunday',
  ],
};
