import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  GraphicComponentModule,
  LinkComponentsModule,
} from '@domgen/dgx-fe-components-core';

import {
  HeaderComponent,
  HeaderNavLinkComponent,
  HeaderPoweredByComponent,
} from './';

const components = [
  HeaderComponent,
  HeaderNavLinkComponent,
  HeaderPoweredByComponent,
];

@NgModule({
  imports: [
    CommonModule,
    LinkComponentsModule,
    GraphicComponentModule,
    RouterModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class HeaderModule {}
