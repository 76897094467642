import { GaTags } from '@common/util-models';
import { filterNullUndefined } from '@domgen/dgx-fe-common';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GaTaggingService } from '../../services/ga-tagging/ga-tagging.service';

export function pushToGa(
  gaTaggingService: GaTaggingService
): MonoTypeOperatorFunction<GaTags | GaTags[]> {
  return pipe(
    filterNullUndefined(),
    tap((gaTags: GaTags | GaTags[]) =>
      Array.isArray(gaTags)
        ? gaTaggingService.tag(...gaTags)
        : gaTaggingService.tag(gaTags)
    )
  );
}
