import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CmsData, CmsPage } from '@common/util-models';
import { Observable } from 'rxjs';
import { CmsDataService, cmsDataUrl } from '@common/data-access-shared';
import { map, switchMap, take } from 'rxjs/operators';
import { QuotesFacade } from '@common/data-access-quotes';

@Injectable({
  providedIn: 'root',
})
export class QuoteCmsPageResolver implements Resolve<Observable<CmsData>> {
  constructor(
    private cmsDataService: CmsDataService,
    private quotesFacade: QuotesFacade
  ) {}

  resolve(): Observable<CmsData> {
    const baseUrl = cmsDataUrl[CmsPage.Quote]?.url;
    return this.quotesFacade.isHeating$.pipe(
      map((isHeating) => {
        return `${baseUrl}/${
          isHeating ? 'heating-policy-features' : 'policy-features-1'
        }`;
      }),
      take(1),
      switchMap((quoteCmsPageUrl) =>
        this.cmsDataService.getCmsData({
          id: CmsPage.Quote,
          url: quoteCmsPageUrl,
        })
      )
    );
  }
}
